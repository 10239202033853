import http from "./httpService";
import { apiURL } from "../config.json";

const apiEndpoint = apiURL + "/users";

// register
// Register a new user.
//
// Input:
//  user object with folloing fields:
//      firstName - String - First name of the user
//      lastName - String - Last name of the user
//      phoneNumber - Number - Mobile phone number of the user
//      countryCode - Number - Country code of the user
//      email - String - Email address of the user
//
//      countryISOCode - String - The country ISO code of the user (for example, U.S.A. has country ISO code of "US")
//      languageLocaleCode - String - Language local of the user ("eng")
export function register(user) {
  const data = {
    firstName: user.firstName,
    lastName: user.lastName,
    mobilePhone: user.mobilePhone,
    mobilePhoneCountryCode: user.mobilePhoneCountryCode,
    countryISOCode: user.countryISOCode,
    languageLocaleCode: user.languageLocaleCode,
    email: user.email,
    password: user.password,
  };

  const url = apiEndpoint + "/addUser";
  // The following HTTP request returns a promise
  return http.post(url, data);
}

// getUser
// Get user's information
//
// Input:
//  token (String) - The JWT token of the user received upon log-in.
//
export function getUser(/* token */) {
  //const headers = { headers: { "x-auth-token": token } };

  // The following HTTP request returns a promise
  //return http.get(apiEndpoint, headers);
  return http.get(apiEndpoint);
}

// getUserFromLocalStorage
// If the user is logged in, then get the current user information from the local storage.
//
// Returns:
//    user JSON
//
export function getUserFromLocalStorage() {
  return JSON.parse(localStorage.getItem("CurrentUser"));
}
