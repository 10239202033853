import Joi from "joi";

import Form from "./common/form";
import * as authService from "../services/authService";
import { ToastContainer } from "react-toastify";
import { showToast } from "../utils/toast";

import "../css/registerVerifyCode.css";

import { assetsURL, logoImageName } from "../config.json";

import UserContext from "../context/userContext";

class RegisterVerifyCode extends Form {
  // Context used
  static contextType = UserContext;

  // Component properties
  isMobile = window.innerWidth < 480;

  state = {
    data: {
      code: "",
    },
    errors: {},
    loading: false,
  };

  schema = Joi.object({
    code: Joi.string()
      .trim()
      .min(6)
      .max(6)
      .required()
      .label("Confirmation code"),
  });

  handleResendCode = async () => {
    this.setState({ loading: true }); // show spinner
    let response;
    try {
      const phoneObject = {
        mobilePhoneCountryCode: this.context.currentUser.mobilePhoneCountryCode,
        mobilePhone: this.context.currentUser.mobilePhone,
      };
      response = await authService.getTempSecret(phoneObject);
    } catch (e) {
    } finally {
      this.setState({ loading: false }); // remove spinner
    }
    // Check if the response is success
    if (response && response.status === 200) {
      showToast("Text sent!", "success");
    }
  };

  doSubmit = async () => {
    const { code } = this.state.data;
    const authObject = {
      mobilePhoneCountryCode: this.context.currentUser.mobilePhoneCountryCode,
      mobilePhone: this.context.currentUser.mobilePhone,
      tempSecret: code,
    };

    let response;
    try {
      this.setState({ loading: true }); // show spinner
      // Send the temp code to the server
      response = await authService.phoneAuth(authObject);
    } catch (e) {
    } finally {
      this.setState({ loading: false }); // remove spinner
    }
    // Check if the response is success
    if (response && response.status === 200) {
      const { token } = response.data;

      // Save information received
      localStorage.setItem("token", token); // Saved in browser's local storage DB

      // Go to Email verification page
      this.props.history.push("/register-verify-email");
    }
  };

  render() {
    let containerClass,
      headingClassContainer,
      titleClass,
      headingClass,
      logoImage;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";
      headingClassContainer = "verify-code-heading-container-mobile";
      titleClass = "verify-code-title-container-mobile";
      headingClass = "ovroom-form-page-heading-mobile";
      logoImage = "logo-image-mobile";
    } else {
      containerClass = "container ovroom-form-container";
      headingClassContainer = "verify-code-heading-container";
      titleClass = "verify-code-title-container";
      headingClass = "ovroom-form-page-heading";
      logoImage = "logo-image";
    }

    return (
      <UserContext.Consumer>
        {() => (
          <div className={containerClass}>
            <div className={headingClassContainer}>
              <div className={titleClass}>
                <img
                  className={logoImage}
                  src={assetsURL + logoImageName}
                  alt="Ovroom logo"
                />
                <p className={headingClass}>Confirm your number</p>
              </div>

              <p className="verify-code-instructions">
                Enter the 6-digit code Ovroom just sent to{" "}
                <b>{this.context.mobilePhone}</b>:
              </p>
            </div>

            <form className="verify-code-form">
              {this.renderInput("code", "Confirmation code", true)}
              {this.renderSubmitButton("Submit")}
            </form>
            <div className="resendContainer">
              <label className="resend-text">Didn't get a text </label>
              <label
                className="resend-text resend-text-link clickable"
                onClick={() => this.handleResendCode()}
              >
                Send again{" "}
              </label>
            </div>
            <ToastContainer />
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}
export default RegisterVerifyCode;
