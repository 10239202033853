import http from "./httpService";
import { apiURL } from "../config.json";

const apiEndpoint = apiURL + "/accommodations";

// getAccommodations
// Get list of accommodations
//
// Input:
//  done automatically - token (String) - The JWT token of the user received upon log-in.
//
export function getAccommodations() {
  // The following HTTP request returns a promise
  return http.get(apiEndpoint);
}
