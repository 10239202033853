import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Home from "./components/home";

// Internal - Ovroom only
import Organizations from "./components/internal/organizations";

// Operator only
import BecomeTourOperator from "./components/becomeTourOperator";
import RegisterTourOperator from "./components/operator/registerOperator";
import OperatorTermsAndConditions from "./components/operator/operatorTermsAndConditions";
import OperatorPrivacy from "./components/operator/operatorPrivacy";
import RegisterTourOperatorVerifyEmail from "./components/operator/registerOperatorVerifyEmail";
import RegisterOperatorSuccessful from "./components/operator/registerOperatorSuccessful";
import Operator from "./components/operator/operator";
import OperatorReservations from "./components/operator/operatorReservations";

// Operator Tour Create
import CreateTourStart from "./components/operator/tour/createTour/createTourStart";
import CreateTourTransportation from "./components/operator/tour/createTour/createTourTransportation";
import CreateTourAccommodation from "./components/operator/tour/createTour/createTourAccommodation";
import CreateTourDuration from "./components/operator/tour/createTour/createTourDuration";
import CreateTourCapacity from "./components/operator/tour/createTour/createTourCapacity";
import CreateTourCancellationPolicy from "./components/operator/tour/createTour/createTourCancellationPolicy";
import CreateTourPrice from "./components/operator/tour/createTour/createTourPrice";
import CreateTourSchedule from "./components/operator/tour/createTour/createTourSchedule";
import CreateTourUnavailableDates from "./components/operator/tour/createTour/createTourUnavailableDates";
import CreateTourLastStartDate from "./components/operator/tour/createTour/createTourLastStartDate";
import CreateTourDescription from "./components/operator/tour/createTour/createTourDescription";
import CreateTourDetails from "./components/operator/tour/createTour/createTourDetails";
import CreateTourHighlights from "./components/operator/tour/createTour/createTourHighlights";
import CreateTourSubmit from "./components/operator/tour/createTour/createTourSubmit";

// Operator Tour Edit
import OperatorTour from "./components/operator/tour/tour";
import TourBasicInfo from "./components/operator/tour/editTour/tourBasicInfo";
import TourUnavailableDates from "./components/operator/tour/editTour/tourUnavailableDates";
import TourPrice from "./components/operator/tour/editTour/tourPrice";
import TourDescription from "./components/operator/tour/editTour/tourDescription";
import TourDetails from "./components/operator/tour/editTour/tourDetails";
import TourHighlights from "./components/operator/tour/editTour/tourHighlights";
import TourDuration from "./components/operator/tour/editTour/tourDuration";
import TourLastStartDate from "./components/operator/tour/editTour/tourLastStartDate";
import TourCapacity from "./components/operator/tour/editTour/tourCapacity";
import TourTransportation from "./components/operator/tour/editTour/tourTransportation";
import TourAccommodation from "./components/operator/tour/editTour/tourAccommodation";

// All
import RegisterUser from "./components/registerUser";
import RegisterVerifyCode from "./components/registerVerifyCode";
import RegisterVerifyEmail from "./components/registerVerifyEmail";
import RegistrationSuccessful from "./components/registrationSuccessful";
import PasswordChangeSuccess from "./components/passwordChangeSuccess";
import VerifyCode from "./components/verifyCode";
import LoginForm from "./components/loginForm";
import ForgotPasswordIntent from "./components/forgotPasswordIntent";
import ForgotPasswordIntentSuccess from "./components/forgotPasswordIntentSuccess";
import ForgotPassword from "./components/forgotPassword";
import Logout from "./components/logout";
import Privacy from "./components/privacy";
import TermsAndConditions from "./components/termsAndCondistions";

import Checkout from "./components/checkout";

import NotFound from "./components/common/notFound";

// CSS
import "./css/normalize.css";
import "./App.css";

// Services

// Contexts for the application
import UserContext from "./context/userContext";
import OperatorContext from "./context/operatorContext";

class App extends Component {
  // Context used
  static contextType = UserContext;

  state = {
    currentUser: {
      firstName: "",
      lastName: "",
      email: "",
      mobilePhone: "",
      mobilePhoneCountryCode: "",
      userId: "",
      unreadMessageCount: 0,
      isOwner: false,
      countryISOCode: "",
      currentOrganization: "",
      languageLocaleCode: "",
      emailVerified: false,
      mobilePhoneVerified: false,

      orgName: "",
      orgFriendlyName: "",
      orgId: "",
      orgFinanceEmail: "",
      orgSupportEmail: "",
    },

    // State to store global value of the Operator | Manage screen. This may be one of
    // Reservations ("reservations"), Tours ("tours") or Personnel ("personnel").
    operatorManage: {
      selection: "reservations",
    },

    // State to store the sub-level tours selections under "Operator | Manage | Tours" menu.
    // The menu items are View Tour "viewtour".
    operationsManageTours: {
      tourSelectlionState: "alltours", // show all tours by default
      id: null, // ID of a tour being viewed
    },

    // State to store the sub-level reservation selections under "Operator | Manage | Reservations" menu.
    // The menu items are "Upcoming" ("upcoming_reservations"), "Past" ("past_reservations") and "Canceled" ("canceled_reservations")
    operatorManageReservations: {
      reservationSubSelection: "upcoming_reservations", // default
      reservationSubSelectionState: "allreservations", // default is "allreservations". The other option is "viewreservation".

      id: null, // ID of a reservation, if the selection is for view or edit any of those items
    },
  };

  // Set the new selection value for the Operator | Manage screen.
  // This is one of Reservations ("reservations"), Tours ("tours") or Personnel ("personnel").
  handleOperatorManageSelectionChanged = (selection) => {
    let operatorManage = { selection };
    this.setState({ operatorManage });
  };

  // Set the new tours sub selection value for the Operator | Manage | Tours screen.
  handleOperatorManageToursSubSelectionChanged = (tourSelectlionState, id) => {
    let operationsManageTours = { tourSelectlionState, id };
    this.setState({ operationsManageTours });
  };

  // Set the new reservation sub selection value for the Operator | Manage screen.
  // This is one of Upcoming ("upcoming_reservations"), Past ("past_reservations") or Canceled ("canceled_reservations").
  handleOperatorManageReservationSubSelectionChanged = (
    reservationSubSelection,
    reservationSubSelectionState,
    id
  ) => {
    let operatorManageReservations = {
      reservationSubSelection,
      reservationSubSelectionState,
      id,
    };
    this.setState({ operatorManageReservations });
  };

  handleUserRegisered = (
    newFirstName,
    newLastName,
    newEmail,
    newMobilePhone,
    newMobilePhoneCountryCode,
    newuserId
  ) => {
    let currentUser = { ...this.state.currentUser };
    currentUser["firstName"] = newFirstName;
    currentUser["lastName"] = newLastName;
    currentUser["email"] = newEmail;
    currentUser["mobilePhone"] = newMobilePhone;
    currentUser["mobilePhoneCountryCode"] = newMobilePhoneCountryCode;
    currentUser["userId"] = newuserId;
    this.setState({ currentUser });
  };

  handleOrgRegistered = (
    newOrgId,
    newOrgName,
    newOrgFriendlyName,
    newFinanceEmail,
    newSupportEmail
  ) => {
    let currentUser = { ...this.state.currentUser };
    currentUser["orgName"] = newOrgName;
    currentUser["orgFriendlyName"] = newOrgFriendlyName;
    currentUser["orgId"] = newOrgId;
    currentUser["orgFinanceEmail"] = newFinanceEmail;
    currentUser["orgSupportEmail"] = newSupportEmail;
    this.setState({ currentUser });

    localStorage.setItem("CurrentUser", JSON.stringify(currentUser));
  };

  handleLoginWithEmail = (newEmail) => {
    let currentUser = { ...this.state.currentUser };
    currentUser["email"] = newEmail;
    this.setState({ currentUser });

    currentUser = JSON.parse(localStorage.getItem("CurrentUser"));
    currentUser["email"] = newEmail;
    localStorage.setItem("CurrentUser", JSON.stringify(currentUser));
  };

  handlePhoneLoginIntent = (newMobilePhone, newMobilePhoneCountryCode) => {
    let currentUser = { ...this.state.currentUser };
    currentUser["mobilePhone"] = newMobilePhone;
    currentUser["mobilePhoneCountryCode"] = newMobilePhoneCountryCode;
    this.setState({ currentUser });

    currentUser = JSON.parse(localStorage.getItem("CurrentUser"))
      ? JSON.parse(localStorage.getItem("CurrentUser"))
      : [];
    currentUser["mobilePhone"] = newMobilePhone;
    currentUser["mobilePhoneCountryCode"] = newMobilePhoneCountryCode;
    localStorage.setItem("CurrentUser", JSON.stringify(currentUser));
  };

  handleUserLoggedIn = (
    newuserId,
    newFirstName,
    newLastName,
    newUnreadMessageCount,
    newIsOwner,
    newCountryISOCode,
    newCurrentOrganization,
    newLanguageLocaleCode,
    newEmailVerified,
    newMobilePhoneVerified
  ) => {
    let currentUser = { ...this.state.currentUser };
    currentUser.firstName = newFirstName;
    currentUser.lastName = newLastName;
    currentUser.userId = newuserId;
    currentUser.unreadMessageCount = newUnreadMessageCount;
    currentUser.isOwner = newIsOwner;
    currentUser.countryISOCode = newCountryISOCode;
    currentUser.currentOrganization = newCurrentOrganization;
    currentUser.languageLocaleCode = newLanguageLocaleCode;
    currentUser.emailVerified = newEmailVerified;
    currentUser.mobilePhoneVerified = newMobilePhoneVerified;
    this.setState({ currentUser });

    localStorage.removeItem("CurrentUser");
    localStorage.setItem("CurrentUser", JSON.stringify(currentUser));
  };

  render() {
    return (
      <UserContext.Provider
        value={{
          currentUser: this.state.currentUser,
          onLogin: this.handleUserLoggedIn,
          onEmailLogin: this.handleLoginWithEmail,
          onPhoneLoginIntent: this.handlePhoneLoginIntent,
          onUserRegistered: this.handleUserRegisered,
          onOrgRegistered: this.handleOrgRegistered,
        }}
      >
        <OperatorContext.Provider
          value={{
            operatorManage: {
              selection: this.state.operatorManage.selection,
              selectionState: this.state.operatorManage.selectionState,
              id: this.state.operatorManage.id,
            },
            operationsManageTours: {
              tourSelectlionState:
                this.state.operationsManageTours.tourSelectlionState,
              id: this.state.operationsManageTours.id,
            },
            operatorManageReservations: {
              reservationSubSelection:
                this.state.operatorManageReservations.reservationSubSelection,
              reservationSubSelectionState:
                this.state.operatorManageReservations
                  .reservationSubSelectionState,
              id: this.state.operatorManageReservations.id,
            },
            onOperatorManageSelectionChanged:
              this.handleOperatorManageSelectionChanged,
            onOperatorManageToursSubSelectionChanged:
              this.handleOperatorManageToursSubSelectionChanged,
            onOperatorManageReservationsSubSelectionChanged:
              this.handleOperatorManageReservationSubSelectionChanged,
          }}
        >
          <React.Fragment>
            <main className="container-fluid app-container-fullwidth-fullheight">
              <Switch>
                <Route path="/checkout" component={Checkout}></Route>

                <Route path="/privacy" component={Privacy}></Route>
                <Route
                  path="/termsandconditions"
                  component={TermsAndConditions}
                ></Route>

                <Route
                  path="/operator-privacy"
                  component={OperatorPrivacy}
                ></Route>
                <Route
                  path="/operator-termsandconditions"
                  component={OperatorTermsAndConditions}
                ></Route>

                <Route path="/register" component={RegisterUser}></Route>

                <Route
                  path="/register-verify-code"
                  component={RegisterVerifyCode}
                ></Route>
                <Route
                  path="/register-verify-email"
                  component={RegisterVerifyEmail}
                ></Route>

                <Route
                  path="/registration-successful"
                  component={RegistrationSuccessful}
                ></Route>

                <Route path="/login" component={LoginForm}></Route>
                <Route path="/verify-code" component={VerifyCode}></Route>
                <Route path="/logout" component={Logout}></Route>
                <Route
                  path="/forgotPasswordIntent"
                  component={ForgotPasswordIntent}
                ></Route>
                <Route
                  path="/forgotPasswordIntentSuccess"
                  component={ForgotPasswordIntentSuccess}
                ></Route>
                <Route
                  path="/forgotPassword/:token"
                  component={ForgotPassword}
                />
                <Route
                  path="/passwordChangeSuccess"
                  component={PasswordChangeSuccess}
                ></Route>

                {/* Internal - Ovroom only */}
                <Route path="/organizations" component={Organizations}></Route>

                {/* Operator only */}
                <Route
                  path="/becomeTourOperator"
                  component={BecomeTourOperator}
                ></Route>
                <Route
                  path="/operator/register"
                  component={RegisterTourOperator}
                ></Route>
                <Route
                  path="/operator/register-verify-email"
                  component={RegisterTourOperatorVerifyEmail}
                ></Route>
                <Route
                  path="/operator/register-success"
                  component={RegisterOperatorSuccessful}
                ></Route>

                <Route
                  path="/operator/create-tour-start"
                  component={CreateTourStart}
                ></Route>

                <Route
                  path="/operator/create-tour-transportation"
                  component={CreateTourTransportation}
                ></Route>

                <Route
                  path="/operator/create-tour-accommodation"
                  component={CreateTourAccommodation}
                ></Route>

                <Route
                  path="/operator/create-tour-duration"
                  component={CreateTourDuration}
                ></Route>

                <Route
                  path="/operator/create-tour-capacity"
                  component={CreateTourCapacity}
                ></Route>

                <Route
                  path="/operator/create-tour-cancel-policy"
                  component={CreateTourCancellationPolicy}
                ></Route>

                <Route
                  path="/operator/create-tour-price"
                  component={CreateTourPrice}
                ></Route>
                <Route
                  path="/operator/create-tour-schedule"
                  component={CreateTourSchedule}
                ></Route>
                <Route
                  path="/operator/create-tour-unavailabledates"
                  component={CreateTourUnavailableDates}
                ></Route>
                <Route
                  path="/operator/create-tour-laststartdate"
                  component={CreateTourLastStartDate}
                ></Route>

                <Route
                  path="/operator/create-tour-description"
                  component={CreateTourDescription}
                ></Route>

                <Route
                  path="/operator/create-tour-details"
                  component={CreateTourDetails}
                ></Route>

                <Route
                  path="/operator/create-tour-highlights"
                  component={CreateTourHighlights}
                ></Route>

                <Route
                  path="/operator/create-tour-submit"
                  component={CreateTourSubmit}
                ></Route>

                <Route
                  path="/operator/tours/tour"
                  component={OperatorTour}
                ></Route>
                <Route
                  path="/operator/tours/tour-basic-info"
                  component={TourBasicInfo}
                ></Route>

                <Route
                  path="/operator/tours/tour-description"
                  component={TourDescription}
                ></Route>

                <Route
                  path="/operator/tours/tour-details"
                  component={TourDetails}
                ></Route>

                <Route
                  path="/operator/tours/tour-highlights"
                  component={TourHighlights}
                ></Route>

                <Route
                  path="/operator/tours/tour-duration"
                  component={TourDuration}
                ></Route>

                <Route
                  path="/operator/tours/tour-unavailable-dates"
                  component={TourUnavailableDates}
                ></Route>

                <Route
                  path="/operator/tours/tour-price"
                  component={TourPrice}
                ></Route>

                <Route
                  path="/operator/tours/tour-last-start-date"
                  component={TourLastStartDate}
                ></Route>
                <Route
                  path="/operator/tours/tour-capacity"
                  component={TourCapacity}
                ></Route>
                <Route
                  path="/operator/tours/tour-transportation"
                  component={TourTransportation}
                ></Route>

                <Route
                  path="/operator/tours/tour-accommodation"
                  component={TourAccommodation}
                ></Route>

                {/* Operator manage tours, reservations and personnel */}
                <Route
                  path="/operator/manage"
                  exact
                  component={Operator}
                ></Route>

                {/* Operator Edit and Create Trous */}
                {/* <Route path="/operator/tours" exact component={Operator}></Route> */}

                <Route
                  path="/operator/reservations"
                  component={OperatorReservations}
                ></Route>
                {/* <Route path="/operator/personnel" component={Operator}></Route> */}

                {/* Mosh stuff  */}
                {/* <Route path="/movies/:id" component={MovieForms}></Route>
          <Route path="/customers" component={Customers}></Route>
          <Route path="/rentals" component={Rentals}></Route> */}

                {/* <Redirect from="/" exact to="/customers" /> */}
                {/* <Route path="/products/:id" component={ProductDetails} />
    <Route
      path="/products"
      render={(props) => <Products sortBy={"newest"} {...props} />}
    />
    <Route path="/posts/:year?/:month?" component={Posts} />
    <Route path="/admin" component={Dashboard} />
    <Redirect from="/messages" to="/posts" />
    
     */}

                <Route path="/not-found" component={NotFound} />

                <Route path="/" exact component={Home} />
                {/* <Route path="/" exact component={CreateTourSubmit} /> */}

                <Redirect to="/not-found" />
              </Switch>
            </main>
          </React.Fragment>
        </OperatorContext.Provider>
      </UserContext.Provider>
    );
  }
}

export default App;
