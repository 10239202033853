import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import OperatorViewResBasicInfo from "./viewReservation/viewResBasicInfo";
import OperatorViewResFinancialInfo from "./viewReservation/viewResFinancialInfo";
import OperatorViewResStatus from "./viewReservation/viewResStatusInfo";

import * as authService from "../../../services/authService";
import * as userService from "../../../services/userService";
import * as reservationService from "../../../services/operatorReservationService";

class OperatorViewUpcomingReservation extends React.Component {
  isMobile = window.innerWidth < 480;

  state = { reservation: undefined, loading: true };

  async componentDidMount() {
    // Get the tour ID from the props
    let reservationId = this.props.reservationId;

    if (reservationId) {
      let response;
      try {
        response = await reservationService.getReservation(reservationId);
      } catch (e) {
      } finally {
        this.setState({ loading: false });
      }
      if (response && response.status === 200) {
        this.setState({ reservation: response.data });
      }
    }
  }

  render() {
    const { reservation } = this.state;

    return (
      <div>
        {this.state.loading && (
          <div className="view-spinner-container">
            <span
              className="spinner-border spinner-border-sm m-1"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
        )}

        {reservation && (
          <div>
            <div>
              <button
                onClick={this.props.onShowAllReservations}
                className="btn btn-outline-primary submit-button"
              >
                <i className="bi bi-arrow-return-left" /> Show all reservations
              </button>
              <div className="container">
                <div className="view-title-container">
                  <div>
                    <label className="view-item-name-text">
                      Confirmation number
                    </label>
                    <p className="view-item-content-text">
                      <b>{reservation.confirmation}</b>
                    </p>
                  </div>
                  <div className="edit-button-with-warning-container">
                    <Link
                      className="btn btn-primary view-title-button-text"
                      to={{
                        pathname: "/operator/tours/tour-basic-info",
                        state: { reservation },
                      }}
                    >
                      <i className="bi bi-trash"></i> Cancel reservation
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="horizontal-line-container">
              <hr className="horizontal-line-primary-color" />
            </div>

            <OperatorViewResBasicInfo reservation={reservation} />
            <OperatorViewResFinancialInfo reservation={reservation} />
            <OperatorViewResStatus reservation={reservation} />
          </div>
        )}
      </div>
    );
  }
}

OperatorViewUpcomingReservation.propTypes = {
  reservationId: PropTypes.string.isRequired,
  onShowAllReservations: PropTypes.func.isRequired,
};

export default OperatorViewUpcomingReservation;
