import React from "react";
import PropTypes from "prop-types";

import TableHeader from "./tableHeader";
import TableBody from "./tableBody";

// Table component
// Renders a table.
//
// Input:
//      The props of this component should have:
//      data (Array of objects, required) - The object of array, whose contents are being shown in table.
//      columns (Array of objects, required) - This is the array of column title objects which looks as follows:
//              {
//                  label: <String for the column header title>
//                  path: <String that represents the variable-name of the path of the column in the calling component>
//                  key: <Optional - String - A unique string that identifies the column uniquely. If key is provide, it is used to
//                      display the column header component 'th', otherwise the 'path' is used as the key.
//                  content: <Optional - function, but must have 'key' if present - JSX component (in other words a React Component, which is a JavaScript object). This is used
//                      to render column contents which are JSX components like:
//                          (org) => (<button onClick={() => this.props.onDelete(org)} className="btn btn-danger btn-sm m-2"> Deactivate </button>)
//              }
//      sortColumn (Object, required) - The column description of the sort column. This object looks as follows:
//              {
//                  path: <String that identifies the path to the column in the calling component>
//                  order: <String with values "asc" (for ascending order) or "desc" (for descending order)
//              }
//      onSort (function, required) - The method to call in the calling-component when sorting of a column event occurs
//      rowKey (String, required) - Variable name in the data objects that identifies the unique key for each row.
//
const Table = ({ data, columns, sortColumn, onSort, rowKey }) => {
  return (
    <table className="table">
      <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
      <TableBody data={data} columns={columns} rowKey={rowKey} />
    </table>
  );
};

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  sortColumn: PropTypes.object.isRequired,
  rowKey: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default Table;
