import React from "react";
import Joi from "joi";
import _ from "lodash";

import * as authService from "../../../../services/authService";
import Form from "../../../common/form";
import "../../../../css/ovroomFormPage.css";

class CreateTourPriceSinglePriceForm extends Form {
  isMobile = window.innerWidth < 480;

  state = {
    data: {
      currency: "",
      amountAdult: "",
      amountChild: "",
      salesTaxPercentage: "",
      salesTaxDescription: "",
      otherTaxPercentage: "",
      otherTaxDescription: "",
    },
    errors: {},
    loading: false,
  };

  schema = Joi.object({
    currency: Joi.string().required().label("Currency"),
    amountAdult: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per adult"),
    amountChild: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per child"),
    salesTaxPercentage: Joi.number()
      .min(0)
      .required()
      .label("Sales tax percentage"),
    salesTaxDescription: Joi.string()
      .empty("")
      .max(3000)
      .label("Sales tax description")
      .optional(),
    otherTaxPercentage: Joi.number()
      .empty("")
      .min(0)
      .label("Other tax percentage")
      .optional(),
    otherTaxDescription: Joi.string()
      .empty("")
      .max(3000)
      .label("Other tax description")
      .optional(),
  }).with("otherTaxPercentage", "otherTaxDescription"); // make sure if startDate is present, then endDate must be present, too

  doSubmit = async () => {
    let prices = [];
    // Get the actual currency notation
    const currency = this.props.currencies.find((c) =>
      _.isEqual(c._id, this.state.data.currency)
    ).currency;

    const currencyCountryISOCode = this.props.currencies.find((c) =>
      _.isEqual(c._id, this.state.data.currency)
    ).isoCode;

    let priceObject = {
      currency: currency,
      currencyCountryISOCode: currencyCountryISOCode,
      amountAdult: this.state.data.amountAdult,
      amountChild: this.state.data.amountChild,
      salesTaxPercentage: this.state.data.salesTaxPercentage,
      salesTaxDescription: this.state.data.salesTaxDescription,
      // otherTaxPercentage: this.state.data.otherTaxPercentage
      //   ? this.state.data.otherTaxPercentage
      //   : 0,
      // otherTaxDescription: this.state.data.otherTaxDescription,
    };

    if (this.state.data.otherTaxPercentage) {
      priceObject["otherTaxPercentage"] = this.state.data.otherTaxPercentage;
      priceObject["otherTaxDescription"] = this.state.data.otherTaxDescription;
    }

    prices.push(priceObject);

    this.props.onSubmitForm(prices); // Call the parent component for further actions
  };

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      //localStorage.setItem("UrlBeforeLogin", this.props.location.pathname);
      this.props.history.replace("/login");
      return null;
    }

    let sectionHeadingTextStyle;
    if (this.isMobile) {
      sectionHeadingTextStyle = "ovroom-form-section-heading-mobile";
    } else {
      // desktop and tablet
      sectionHeadingTextStyle = "ovroom-form-section-heading";
    }

    return (
      <React.Fragment>
        <form>
          <label className={sectionHeadingTextStyle}>
            Tax we need to collect
          </label>

          {this.renderInput("salesTaxPercentage", "Sales tax percentage", true)}
          {this.renderInput(
            "salesTaxDescription",
            "Sales tax description",
            false
          )}

          {this.renderInput(
            "otherTaxPercentage",
            "Other tax percentage (optional)",
            false
          )}
          {this.renderInput(
            "otherTaxDescription",
            "Other tax description",
            false
          )}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>

          <label className={sectionHeadingTextStyle}>Tour prices</label>

          {this.renderSelect(
            "currency",
            "Currency",
            this.props.currencies,
            false
          )}

          {this.renderInput("amountAdult", "Price per adult", false)}
          {this.renderInput("amountChild", "Price per child", false)}

          {this.renderSubmitButton("Save and next")}
        </form>
      </React.Fragment>
    );
  }
}

export default CreateTourPriceSinglePriceForm;
