import React from "react";
import Joi from "joi";
import _ from "lodash";
import { ToastContainer } from "react-toastify";

import * as tourServices from "../../../../services/operatorTourService";
import * as authService from "../../../../services/authService";
import * as countryService from "../../../../services/countryService";
import Form from "../../../common/form";
import "../../../../css/ovroomFormPage.css";
import "../../../../css/checkbox.css";

import { assetsURL, logoImageName } from "../../../../config.json";

class TourDuration extends Form {
  isMobile = window.innerWidth < 480;

  state = {
    data: {
      durationDays: "",
      durationNights: "",
      durationLength: "",
      durationLengthUnit: "",
      durationStartTimeHour: "",
      durationStartTimeMinute: "",
      durationStartTimezone: "",
      durationEndTimeHour: "",
      durationEndTimeMinute: "",
      durationEndTimezone: "",
    },
    errors: {},
    startTimeZones: [],
    endTimeZones: [],
    hours: [],
    minutes: [],
    durationUnits: [],
    loading: true,
    changed: false,
  };

  schema = Joi.object({
    durationDays: Joi.number()
      .min(0)
      .max(28)
      .required()
      .label("Tour number of days"),
    durationNights: Joi.number()
      .min(0)
      .max(28)
      .required()
      .label("Tour number of nights"),
    durationLength: Joi.number().min(0).max(28).required().label("Tour length"),
    durationLengthUnit: Joi.string().required().label("Tour length unit"),
    durationStartTimeHour: Joi.number()
      .min(0)
      .max(23)
      .required()
      .label("Tour starttime hour"),
    durationStartTimeMinute: Joi.number()
      .min(0)
      .max(59)
      .required()
      .label("Tour starttime minutes"),
    durationStartTimezone: Joi.string()
      .min(1)
      .max(255)
      .required()
      .label("Timezone"),

    durationEndTimeHour: Joi.number()
      .min(0)
      .max(23)
      .required()
      .label("Tour endtime hour"),

    durationEndTimeMinute: Joi.number()
      .min(0)
      .max(59)
      .required()
      .label("Tour starttime minutes"),

    durationEndTimezone: Joi.string()
      .min(1)
      .max(255)
      .required()
      .label("Timezone"),
  });

  doCancel = () => {
    this.props.history.goBack();
  };

  doSubmit = async () => {
    const { data } = this.state;

    const startHours = this.state.hours.find((h) =>
      _.isEqual(h._id.toString(), data.durationStartTimeHour)
    ).name;

    const startMinutes = this.state.minutes.find((h) =>
      _.isEqual(h._id.toString(), data.durationStartTimeMinute)
    ).name;

    const endHours = this.state.hours.find((h) =>
      _.isEqual(h._id.toString(), data.durationEndTimeHour)
    ).name;

    const endMinutes = this.state.minutes.find((h) =>
      _.isEqual(h._id.toString(), data.durationEndTimeMinute)
    ).name;

    const startTime = startHours + ":" + startMinutes;
    const endTime = endHours + ":" + endMinutes;

    const duration = {
      days: data.durationDays,
      nights: data.durationNights,
      length: data.durationLength,
      lengthUnit: this.state.durationUnits[data.durationLengthUnit].name,
      startTime: startTime,
      startTimezone: this.state.startTimeZones[data.durationStartTimezone].name,
      endTime: endTime,
      endTimezone: this.state.endTimeZones[data.durationEndTimezone].name,
    };

    const { tour } = this.props.location.state;

    // Update the server
    let response;
    try {
      this.setState({ loading: true });
      response = await tourServices.updateDuration(tour._id, duration);
    } catch (e) {
    } finally {
      this.setState({ loading: false });
      if (response && response.status === 200) this.props.history.goBack();
    }
  };

  async componentDidMount() {
    const { tour } = this.props.location.state;
    const { duration } = tour;

    let response;
    let startCountryISOCode = tour.startAddress.countryISOCode;
    let endCountryISOCode = tour.endAddress.countryISOCode;
    try {
      if (startCountryISOCode && startCountryISOCode !== "")
        response = await countryService.getCountryTimezones(
          startCountryISOCode
        );
      else response = await countryService.getAllTimezones();
    } catch (e) {}

    let startTimezones, endTimezones;

    if (response && response.status === 200) {
      let id = 0;
      startTimezones = response.data.map((tz) => {
        const rval = { _id: id, name: tz };
        id = id + 1;
        return rval;
      });
      //this.setState({ startTimeZones: startTimezones });
      if (_.isEqual(startCountryISOCode, endCountryISOCode)) {
        endTimezones = startTimezones;
        //this.setState({ endTimeZones: startTimezones });
        //this.setState({ loading: false });
      } else {
        try {
          if (endCountryISOCode && endCountryISOCode !== "")
            response = await countryService.getCountryTimezones(
              endCountryISOCode
            );
          else response = await countryService.getAllTimezones();
        } catch (e) {
        } finally {
          //this.setState({ loading: false });
        }
        if (response && response.status === 200) {
          let id = 0;
          endTimezones = response.data.map((tz) => {
            const rval = { _id: id, name: tz };
            id = id + 1;
            return rval;
          });
          //this.setState({ endTimeZones: endTimezones });
        }
      }
    }

    // Create Hour and Minute select input array
    let hours = [];
    let minutes = [];
    for (let i = 0; i < 24; i++)
      hours.push({
        _id: i,
        name: i.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }),
      });
    for (let i = 0; i < 46; i = i + 15)
      minutes.push({
        _id: i,
        name: i.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }),
      });

    const durationUnits = [
      { _id: 0, name: "Days" },
      { _id: 1, name: "Nights" },
    ];

    // Fill out the information from the tour in the form
    const startTimeArray = duration.startTime.split(":");
    const startHourId = hours.find((h) => h.name === startTimeArray[0])._id;
    const startMinutesId = minutes.find(
      (m) => m.name === startTimeArray[1]
    )._id;
    const startTimezoneId = startTimezones.find(
      (tz) => tz.name === duration.startTimezone
    )._id;

    const endTimeArray = duration.endTime.split(":");
    const endHourId = hours.find((h) => h.name === endTimeArray[0])._id;
    const endMinutesId = minutes.find((m) => m.name === endTimeArray[1])._id;
    const endTimezoneId = endTimezones.find(
      (tz) => tz.name === duration.endTimezone
    )._id;

    const durationLengthUnitId = durationUnits.find(
      (du) => du.name === duration.lengthUnit
    )._id;

    this.setState({
      loading: false,
      hours: hours,
      minutes: minutes,
      durationUnits: durationUnits,
      startTimeZones: startTimezones,
      endTimeZones: endTimezones,
      data: {
        durationDays: duration.days.toString(),
        durationNights: duration.nights.toString(),
        durationLength: duration.length.toString(),
        durationLengthUnit: durationLengthUnitId.toString(),
        durationStartTimeHour: startHourId.toString(),
        durationStartTimeMinute: startMinutesId.toString(),
        durationStartTimezone: startTimezoneId.toString(),
        durationEndTimeHour: endHourId.toString(),
        durationEndTimeMinute: endMinutesId.toString(),
        durationEndTimezone: endTimezoneId.toString(),
      },
    });
  }

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      //localStorage.setItem("UrlBeforeLogin", this.props.location.pathname);
      this.props.history.replace("/login");
      return null;
    }

    let containerClass,
      headerContainer,
      formContainer,
      logoImage,
      pageHeadingTextStyle,
      sectionHeadingTextStyle;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";
      headerContainer = "ovroom-form-header-container-mobile";
      formContainer = "ovroom-form-elements-container-option2";
      logoImage = "logo-image-mobile";
      pageHeadingTextStyle = "ovroom-form-page-heading-mobile";
      sectionHeadingTextStyle = "ovroom-form-section-heading-mobile";
    } else {
      // desktop and tablet
      containerClass = "container ovroom-form-container";
      headerContainer = "ovroom-form-header-container";
      formContainer = "ovroom-form-elements-container-option2";
      logoImage = "logo-image";
      pageHeadingTextStyle = "ovroom-form-page-heading";
      sectionHeadingTextStyle = "ovroom-form-section-heading";
    }

    return (
      <div className={containerClass}>
        <div className={headerContainer}>
          <img
            className={logoImage}
            src={assetsURL + logoImageName}
            alt="Ovroom logo"
          />
          <p className={pageHeadingTextStyle}>Tour Length and Times</p>
        </div>
        <div className={formContainer}>
          {this.state.loading && (
            <div className="view-spinner-container">
              <span
                className="spinner-border spinner-border-sm m-1"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          )}

          <form>
            <label className={sectionHeadingTextStyle}>Tour length</label>
            {this.renderInput("durationDays", "Number of days", false)}
            {this.renderInput("durationNights", "Number of nights", false)}
            {this.renderInput("durationLength", "Tour duration", false)}
            {this.renderSelect(
              "durationLengthUnit",
              "Unit of tour duration",
              this.state.durationUnits,
              false
            )}

            <div className="horizontal-line-container">
              <hr className="horizontal-line-primary-color" />
            </div>

            <label className={sectionHeadingTextStyle}>
              Tour start time on first day
            </label>

            {this.renderSelect(
              "durationStartTimeHour",
              "Tour start time HOURS",
              this.state.hours,
              false
            )}

            {this.renderSelect(
              "durationStartTimeMinute",
              "Tour start time MINUTES",
              this.state.minutes,
              false
            )}

            {this.renderSelect(
              "durationStartTimezone",
              "Timezone of tour start time",
              this.state.startTimeZones,
              false
            )}

            <div className="horizontal-line-container">
              <hr className="horizontal-line-primary-color" />
            </div>

            <label className={sectionHeadingTextStyle}>
              Tour end time on last day
            </label>

            {this.renderSelect(
              "durationEndTimeHour",
              "Tour end time HOURS",
              this.state.hours,
              false
            )}

            {this.renderSelect(
              "durationEndTimeMinute",
              "Tour end time MINUTES",
              this.state.minutes,
              false
            )}

            {this.renderSelect(
              "durationEndTimezone",
              "Timezone of tour end time",
              this.state.endTimeZones,
              false
            )}
            {this.renderEditFormSubmitButton("Save and close")}
          </form>
          <div className="vertical-filler-container" />
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default TourDuration;
