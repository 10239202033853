import React from "react";
import Joi from "joi";
import _ from "lodash";
import * as authService from "../services/authService";
import * as countryService from "../services/countryService";
import Form from "./common/form";

import UserContext from "../context/userContext";

class PhoneForm extends Form {
  // Context used
  static contextType = UserContext;

  state = {
    data: {
      country: "", // Country selection
      phoneNumber: "",
    },
    errors: {},
    countries: [],
    loading: true,
  };

  // The following 'doSubmit' must be spelled exactly like the way it is to ensure that
  // when our 'Form' component performs the submit, it is calling the correct method
  // implemented here.
  doSubmit = async () => {
    const { country, phoneNumber } = this.state.data;

    // Find the country from the country ID
    let userCountry = this.state.countries.find((c) =>
      _.isEqual(country, c._id)
    );

    const phoneObject = {
      mobilePhoneCountryCode: userCountry.countryCode,
      mobilePhone: phoneNumber,
    };

    let response;
    try {
      this.setState({ loading: true }); // start spinner
      response = await authService.getTempSecret(phoneObject);
    } catch (e) {
    } finally {
      this.setState({ loading: false }); // remove spinner
    }
    if (response && response.status === 200) {
      // Update the UserContext with mobile phone number and country code so that the
      // next screen (Verify Secret Code screen) gets that information from User Context
      this.context.onPhoneLoginIntent(phoneNumber, userCountry.countryCode);

      // Return to the parent component to perform further tasks (e.g. go to VerifyCode page)
      this.props.onSubmitForm();
    }
  };

  schema = Joi.object({
    country: Joi.string().required().label("Country (Country code)"),
    phoneNumber: Joi.number().required().label("Phone number"),
    loading: true,
  });

  async componentDidMount() {
    let response;
    try {
      response = await countryService.getTouristCountries();
    } catch (e) {
    } finally {
      this.setState({ loading: false }); // remove spinner
    }
    if (response && response.status === 200) {
      this.setState({ countries: response.data });
    }
  }

  render() {
    let countries = [];
    if (this.state.countries && this.state.countries.length !== 0) {
      let Countries = this.state.countries;
      // Prepare the coutnries array
      countries = Countries.map((c) => {
        return {
          _id: c._id,
          name: `${c.name} (+${c.countryCode})`,
        };
      });
    }

    return (
      <React.Fragment>
        <form>
          {this.renderSelect(
            "country",
            "Country (Country code)",
            countries,
            false
          )}
          {this.renderInput("phoneNumber", "Phone number", false)}
          <p className="login-sub-text">
            We'll text you to confirm your number. Standard message and data
            rates apply.
          </p>
          {this.renderSubmitButton("Continue with phone number")}
        </form>
      </React.Fragment>
    );
  }
}

export default PhoneForm;
