import http from "./httpService";
import { apiURL } from "../config.json";

const apiEndpoint = apiURL + "/tourtypes";

// getTourtypes
// Get list of tour-types
//
// Input:
//  done automatically - token (String) - The JWT token of the user received upon log-in.
//
export function getTourTypes() {
  // The following HTTP request returns a promise
  return http.get(apiEndpoint);
}
