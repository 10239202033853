import React from "react";
import Joi from "joi";
import DatePicker from "react-datepicker";
import { ToastContainer } from "react-toastify";

import * as authService from "../../../../services/authService";
import Form from "../../../common/form";
import "../../../../css/ovroomFormPage.css";
import "../../../../css/createTourLastStartDate.css";
import "react-datepicker/dist/react-datepicker.css";

import { assetsURL, logoImageName } from "../../../../config.json";

import UserContext from "../../../../context/userContext";

class CreateTourLastStartDate extends Form {
  isMobile = window.innerWidth < 480;

  // Context used
  static contextType = UserContext;

  state = {
    data: {
      lastStartDate: null,
    },
    errors: {},
    loading: false,
    lastStartDateForServer: "",
  };

  schema = Joi.object({
    lastStartDate: Joi.object().optional().allow(null),
  });

  onChangeStartDate = (date) => {
    if (!date) return;
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const day = date.getDate().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const stringDate = `${year}-${month}-${day}`;

    this.setState({
      lastStartDateForServer: stringDate,
      data: { lastStartDate: date },
    });
  };

  doSubmit = async () => {
    const { lastStartDateForServer } = this.state;
    localStorage.setItem(
      "TourLastStartDate",
      JSON.stringify(lastStartDateForServer)
    );
    this.props.history.push("/operator/create-tour-description");
  };

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      //localStorage.setItem("UrlBeforeLogin", this.props.location.pathname);
      this.props.history.replace("/login");
      return null;
    }

    let containerClass,
      headerContainer,
      formContainer,
      logoImage,
      pageHeadingTextStyle,
      instructionsContainer;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";
      headerContainer = "ovroom-form-header-container-mobile";
      instructionsContainer = "ovroom-form-instruction-container-option1";
      formContainer = "ovroom-form-elements-container-option1";
      logoImage = "logo-image-mobile";
      pageHeadingTextStyle = "ovroom-form-page-heading-mobile";
    } else {
      // desktop and tablet

      containerClass = "container ovroom-form-container";
      headerContainer = "ovroom-form-header-container";
      instructionsContainer = "ovroom-form-instruction-container-option1";
      formContainer = "ovroom-form-elements-container-option1";
      logoImage = "logo-image";
      pageHeadingTextStyle = "ovroom-form-page-heading";
    }

    const { lastStartDate } = this.state.data;

    const minStartDate = new Date();
    let maxStartDate = new Date();

    // Allow specifying a last start date for 3 years from now
    maxStartDate = new Date(
      maxStartDate.setMonth(maxStartDate.getMonth() + 3 * 12)
    );

    return (
      <UserContext.Consumer>
        {(userContext) => (
          <div className={containerClass}>
            <div className={headerContainer}>
              <img
                className={logoImage}
                src={assetsURL + logoImageName}
                alt="Ovroom logo"
              />
              <p className={pageHeadingTextStyle}>Tour Last Start Date</p>
            </div>
            <div className={instructionsContainer}>
              <b>Optional.</b> If you do not wish to conduct this tour after a
              certain date, please specify it below. If you want to skip, please
              click 'Save and next' without specifying any dates.
            </div>
            <div className={formContainer}>
              <div className="last-start-date-picker-container">
                <DatePicker
                  className="ovroom-date-picker-container-style"
                  selected={lastStartDate}
                  onChange={(date) => this.onChangeStartDate(date)}
                  placeholderText="Last start date"
                  minDate={minStartDate}
                  maxDate={maxStartDate}
                />
              </div>

              {this.renderSubmitButton("Save and next")}

              <div className="vertical-filler-container" />
            </div>
            <ToastContainer />
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default CreateTourLastStartDate;
