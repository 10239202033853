import React, { Component } from "react";

import TourPriceSinglePriceForm from "./tourPriceSinglePriceForm";
import TourPriceMonthlyPriceForm from "./tourPriceMonthlyPriceForm";

import { ToastContainer } from "react-toastify";

import * as authService from "../../../../services/authService";
import * as countryService from "../../../../services/countryService";
import "../../../../css/ovroomFormPage.css";
import { assetsURL, logoImageName } from "../../../../config.json";

class TourPrice extends Component {
  isMobile = window.innerWidth < 480;

  state = { singlePriceSelected: true, currencies: [], loading: true };

  handleRadioButtonClicked = ({ currentTarget: input }) => {
    if (input.id === "singleprice")
      this.setState({ singlePriceSelected: input.checked });
    else this.setState({ singlePriceSelected: !input.checked });
  };

  doSubmit = async () => {
    // Go back to previous screen to view the tour
    this.props.history.goBack();
  };

  doCancel = () => {
    this.props.history.goBack();
  };

  async componentDidMount() {
    const { prices } = this.props.location.state.tour;
    this.setState({
      singlePriceSelected: prices.length > 1 ? false : true,
    });

    let response;
    try {
      //response = await countryService.getTouristCountries();
      response = await countryService.getOperatorCountries();
    } catch (e) {
    } finally {
      this.setState({ loading: false });
    }
    if (response && response.status === 200) {
      let countries = response.data;
      const currencies = countries.map((c) => {
        return {
          _id: c._id,
          name: `${c.name} (${c.currency})`,
          currency: c.currency,
          currencyCountryISOCode: c.countryISOCode,
        };
      });
      this.setState({
        currencies: currencies,
      });
    }
  }

  render() {
    const { tour } = this.props.location.state;

    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      //localStorage.setItem("UrlBeforeLogin", this.props.location.pathname);
      this.props.history.replace("/login");
      return null;
    }

    let containerClass, headerContainer, logoImage, pageHeadingTextStyle;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";
      headerContainer = "ovroom-form-header-container-mobile";
      pageHeadingTextStyle = "ovroom-form-page-heading-mobile";
      logoImage = "logo-image-mobile";
    } else {
      // desktop and tablet
      containerClass = "container ovroom-form-container";
      headerContainer = "ovroom-form-header-container";
      pageHeadingTextStyle = "ovroom-form-page-heading";
      logoImage = "logo-image";
    }
    return (
      <div className={containerClass}>
        <div className={headerContainer}>
          <img
            className={logoImage}
            src={assetsURL + logoImageName}
            alt="Ovroom logo"
          />
          <p className={pageHeadingTextStyle}>Tour Price</p>
        </div>

        <div
          className="btn-group ovroom-form-elements-container-option3"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="singleprice"
            autoComplete="off"
            checked={this.state.singlePriceSelected}
            onChange={this.handleRadioButtonClicked}
          />
          <label className="btn btn-outline-secondary" htmlFor="singleprice">
            Single price
          </label>

          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="monthlyprice"
            autoComplete="off"
            checked={!this.state.singlePriceSelected}
            onChange={this.handleRadioButtonClicked}
          />
          <label className="btn btn-outline-secondary" htmlFor="monthlyprice">
            Monthly price
          </label>
        </div>

        <div className="ovroom-form-instructions-container-option3">
          {this.state.singlePriceSelected && (
            <p>
              To charge the same price throughout the year, use Single Price. To
              charge different prices in different months, use Monthly Price.
            </p>
          )}
          {!this.state.singlePriceSelected && (
            <p>
              To charge different prices in different months, use Monthly Price.
              Please enter price for every month of the year below. Ovroom
              automatically applies this price schedule when a tour is reserved.
            </p>
          )}
        </div>

        <div className="ovroom-form-container-option3">
          {this.state.singlePriceSelected &&
            this.state.currencies.length > 0 && (
              <TourPriceSinglePriceForm
                onSubmitForm={this.doSubmit}
                onCancel={this.doCancel}
                currencies={this.state.currencies}
                tour={tour}
                isChanged={tour.prices.length > 1}
              />
            )}
          {!this.state.singlePriceSelected &&
            this.state.currencies.length > 0 && (
              <TourPriceMonthlyPriceForm
                onSubmitForm={this.doSubmit}
                onCancel={this.doCancel}
                currencies={this.state.currencies}
                tour={tour}
                isChanged={tour.prices.length === 1}
              />
            )}
          {this.state.loading && (
            <div className="view-spinner-container">
              <span
                className="spinner-border spinner-border-sm m-1"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          )}
        </div>
        <div className="vertical-filler-container" />
        <ToastContainer />
      </div>
    );
  }
}

export default TourPrice;
