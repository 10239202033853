import React from "react";

import "../css/section3Card.css";

const Section3Card = ({ url, title }) => {
  return (
    <div className="card section3-card border-0">
      <div>
        <img
          src={url}
          className="card-img-top section3-card-image"
          alt={title}
          style={{ backgroundColor: "#484848" }}
        />
      </div>

      <div className="card-body  section3-card-body p-1 ">
        <p className="section3-card-body-text">{title}</p>
      </div>
    </div>
  );
};

export default Section3Card;
