import React from "react";
import { Link } from "react-router-dom";
import Joi from "joi";
import _ from "lodash";
import { ToastContainer } from "react-toastify";

import * as userService from "../services/userService";
import * as countryService from "../services/countryService";
import Form from "./common/form";
import "../css/registerUser.css";
import "../css/checkbox.css";

import { assetsURL, logoImageName } from "../config.json";

import UserContext from "../context/userContext";

class RegisterUser extends Form {
  isMobile = window.innerWidth < 480;

  // Context used
  static contextType = UserContext;

  state = {
    data: {
      firstName: "",
      lastName: "",
      country: "", // Country selection
      phoneNumber: "",
      email: "",
      password: "",
      password_confirmation: "",
      agreement: "false", // The state of the checkbox indicating if user has agreed to terms
    },
    errors: {},
    countries: [],
    loading: true, // Flag to indicate if an async task is being performed. We set it to true, because we are downloading
    // the list of countries, just as soon as this form is loaded. It will be set to false in the componentDidMount
    // after the list of countries is successfully loaded.
  };

  schema = Joi.object({
    firstName: Joi.string()
      .trim()
      .min(1)
      .max(255)
      .required()
      .label("First name"),
    lastName: Joi.string().trim().min(1).max(255).required().label("Last name"),
    country: Joi.string().required().label("Country (Country code)"),
    phoneNumber: Joi.number().required().label("Mobile phone number"),
    email: Joi.string()
      .trim()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    password: Joi.string()
      .trim()
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&+-_])[A-Za-z\d@$!%*?&+-_]{8,}$/
      )
      .required()
      .label("Password")
      .messages({
        "string.pattern.base":
          'Password must be minimum 8 characters long, and must have one uppercase character, one lowercase character, one numeric character and one of the following special characters "@ $ ! % * ? & + - _"',
      }),

    password_confirmation: Joi.any()
      .equal(Joi.ref("password"))
      .required()
      .label("Confirm password")
      .messages({ "any.only": "Passwords must match" }),

    agreement: Joi.boolean().invalid(false),
  });

  doSubmit = async () => {
    const {
      firstName,
      lastName,
      country,
      phoneNumber,
      email,
      password,
    } = this.state.data;

    let userCountry = this.state.countries.find((c) =>
      _.isEqual(country, c._id)
    );

    // Construct the user to be sent
    const user = {
      firstName,
      lastName,
      mobilePhone: phoneNumber,
      mobilePhoneCountryCode: userCountry.countryCode,
      countryISOCode: userCountry.countryISOCode,
      languageLocaleCode: "en",
      email,
      password,
    };

    let response;
    try {
      this.setState({ loading: true });
      response = await userService.register(user);
    } catch (e) {
    } finally {
      this.setState({ loading: false });
    }
    // Check if the response is success
    if (response && response.status === 200) {
      // Extract User Id and the flag that indicates if we should verify secret code or go to email log in page
      const { userId, verifySecretCode } = response.data;

      // Update the UserContext
      this.context.onUserRegistered(
        firstName,
        lastName,
        email,
        phoneNumber,
        userCountry.countryCode,
        userId
      );

      // If the user's country supports SMS, then send the user to verify the
      // mobile phone number, otherwise send the user to email verification page.
      if (verifySecretCode) this.props.history.push("/register-verify-code");
      else this.props.history.push("/register-verify-email");
    }
  };

  async componentDidMount() {
    let response;
    try {
      response = await countryService.getTouristCountries();
    } catch (e) {
    } finally {
      this.setState({ loading: false });
    }
    if (response && response.status === 200) {
      this.setState({ countries: response.data });
    }
  }

  render() {
    let Countries = this.state.countries;
    // Prepare the coutnries array
    const countries = Countries.map((c) => {
      return {
        _id: c._id,
        name: `${c.name} (+${c.countryCode})`,
      };
    });

    let containerClass,
      headerContainer,
      formContainer,
      logoImage,
      registerLabelText;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";
      headerContainer = "ovroom-form-header-container-mobile";
      formContainer = "ovroom-form-elements-container-option2";
      logoImage = "logo-image-mobile";
      registerLabelText = "ovroom-form-page-heading-mobile";
    } else {
      // desktop and tablet

      containerClass = "container ovroom-form-container";
      headerContainer = "ovroom-form-header-container";
      formContainer = "ovroom-form-elements-container-option2";
      logoImage = "logo-image";
      registerLabelText = "ovroom-form-page-heading";
    }

    return (
      <UserContext.Consumer>
        {(userContext) => (
          <div className={containerClass}>
            <div className={headerContainer}>
              <img
                className={logoImage}
                src={assetsURL + logoImageName}
                alt="Ovroom logo"
              />
              <p className={registerLabelText}>Register</p>
            </div>
            <div className={formContainer}>
              <form>
                {this.renderInput("firstName", "First name", true)}
                {this.renderInput("lastName", "Last name", false)}
                {this.renderSelect(
                  "country",
                  "Country (Country code)",
                  countries,
                  false
                )}
                {this.renderInput("phoneNumber", "Mobile phone number", false)}
                {this.renderInput("email", "Email", false)}
                {this.renderInput("password", "Password", false, "password")}
                {this.renderInput(
                  "password_confirmation",
                  "Confirm password",
                  false,
                  "password"
                )}

                <div className="form-check">
                  <input
                    className="form-check-input checkbox-form"
                    type="checkbox"
                    value={this.state.agreement}
                    id={"agreement"}
                    name={"agreement"}
                    onChange={this.handleChange}
                  />
                  <p className="accept-terms-text">
                    I have read and agree to the{" "}
                    <Link to="/termsandconditions">Terms and Conditions</Link>{" "}
                    and <Link to="/privacy">Privacy Policy</Link>.
                  </p>
                </div>
                {this.renderSubmitButton("Register")}
              </form>
              <div className="bottom-filler-container"></div>
            </div>
            <ToastContainer />
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default RegisterUser;
