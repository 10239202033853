import React from "react";

import deviceConfig from "./common/deviceConfig";
import "../css/section5.css";

const Section5 = () => {
  let containerClass = "section5-container";
  if (deviceConfig.isMobilePhone) {
    containerClass += "-mobile";
  }

  return (
    <div className={containerClass}>
      <div className="section5-menu">
        {/* <p>About | Contact Us | Terms | Privacy</p> */}
        <div className="section5-footer1">
          <a className="section5-menu-text" href="#">
            About
          </a>
        </div>
        <div className="section5-footer1">
          <a className="section5-menu-text" href="#">
            Contact
          </a>
        </div>
        <div className="section5-footer1">
          <a className="section5-menu-text" href="/termsandconditions">
            Terms
          </a>
        </div>
        <div className="section5-footer2">
          <a className="section5-menu-text" href="/privacy">
            Privacy
          </a>
        </div>
      </div>
      <div className="section5-copyright">
        <p className="section5-copyright-text">
          &copy; 2022 Ovroom, Inc. All rights reserved
        </p>
      </div>
    </div>
  );
};

export default Section5;
