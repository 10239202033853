import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../css/toastify.css";

// Show a toast message from top of the screen.
// Input:
//  message (String) - Message to be shown.
//  type (string) - Message type can be one of the followings:
//    success, error, warn, info
export function showToast(message, type = "info") {
  toast[type](message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}
