import React, { Component } from "react";
import PropTypes from "prop-types";

// TableHeader component
// Renders a table-header.
//
// Input:
//      The props of this component should have:
//      columns (Array of objects, required) - This is the array of column title objects which looks as follows:
//              {
//                  label: <String for the column header title>
//                  path: <String that represents the variable-name of the path of the column in the calling component>
//                  key: <Optional - String - A unique string that identifies the column uniquely. If key is provide, it is used to
//                      display the column header component 'th', otherwise the 'path' is used as the key.
//                  content: <Optional, but must have 'key' if present - JSX component (in other words a React Component, which is a JavaScript object). This is used
//                      to render column contents which are JSX components like (<h1> Title </h1>).
//              }
//      sortColumn (Object, required) - The column description of the sort column. This object looks as follows:
//              {
//                  path: <String that identifies the path to the column in the calling component>
//                  order: <String with values "asc" (for ascending order) or "desc" (for descending order)
//              }
//      onSort (function, required) - The method to call in the calling-component when sorting event occurs
//
class TableHeader extends Component {
  _raiseSort = (path) => {
    let sortColumn = { ...this.props.sortColumn };
    if (sortColumn.path === path)
      sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    else {
      sortColumn.path = path;
      sortColumn.order = "asc";
    }
    this.props.onSort({ sortColumn });
  };

  _renderSortIcon = (column) => {
    const { path, order } = this.props.sortColumn;
    if (column.path !== path) return null;
    if (order === "asc") return <i className="fa fa-sort-asc" />;
    else return <i className="fa fa-sort-desc" />;
  };

  render() {
    const { columns } = this.props;
    return (
      <thead>
        <tr>
          {columns.map((c) => (
            <th
              className="clickable"
              key={c.key ? c.key : c.path}
              onClick={() => this._raiseSort(c.path)}
            >
              {c.path ? c.label : null} {this._renderSortIcon(c)}
            </th>
          ))}
        </tr>
      </thead>
    );
  }
}

TableHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  sortColumn: PropTypes.object.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default TableHeader;
