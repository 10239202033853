import React from "react";
import Joi from "joi";
import { ToastContainer } from "react-toastify";

import * as tourService from "../../../../services/operatorTourService";
import * as authService from "../../../../services/authService";
import Form from "../../../common/form";
import "../../../../css/ovroomFormPage.css";

import { assetsURL, logoImageName } from "../../../../config.json";

class TourDescription extends Form {
  isMobile = window.innerWidth < 480;

  state = {
    data: {
      description: "",
    },
    errors: {},
    loading: false,
  };

  schema = Joi.object({
    description: Joi.string()
      .trim()
      .min(2)
      .max(25000)
      .required()
      .label("Tour description"),
  });

  componentDidMount() {
    let { description } = this.props.location.state.tour;
    this.setState({ data: { description } });
  }

  doCancel = () => {
    this.props.history.goBack();
  }

  doSubmit = async () => {
    const { tour } = this.props.location.state;
    const { description } = this.state.data;
    // Update the server
    let response;
    try {
      this.setState({ loading: true });
      response = await tourService.updateDescription(tour._id, description);
    } catch (e) {
    } finally {
      this.setState({ loading: false });
      if (response && response.status === 200) this.props.history.goBack();
    }
  };

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      //localStorage.setItem("UrlBeforeLogin", this.props.location.pathname);
      this.props.history.replace("/login");
      return null;
    }

    let containerClass,
      headerContainer,
      formContainer,
      logoImage,
      pageHeadingTextStyle;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";
      headerContainer = "ovroom-form-header-container-mobile";
      formContainer = "ovroom-form-elements-container-option2";
      logoImage = "logo-image-mobile";
      pageHeadingTextStyle = "ovroom-form-page-heading-mobile";
    } else {
      // desktop and tablet

      containerClass = "container ovroom-form-container";
      headerContainer = "ovroom-form-header-container";
      formContainer = "ovroom-form-elements-container-option2";
      logoImage = "logo-image";
      pageHeadingTextStyle = "ovroom-form-page-heading";
    }

    return (
      <div className={containerClass}>
        <div className={headerContainer}>
          <img
            className={logoImage}
            src={assetsURL + logoImageName}
            alt="Ovroom logo"
          />
          <p className={pageHeadingTextStyle}>Tour Description</p>
        </div>
        <div className={formContainer}>
          <form>
            {this.renderTextArea("description", "Tour description", true, 15)}

            {this.renderEditFormSubmitButton("Save and close")}
          </form>
          <div className="vertical-filler-container" />
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default TourDescription;
