import _ from "lodash";

const orgs = [
  {
    address: {
      street: "128 Ines Avenue, Suite 103",
      city: "Phoenix",
      state: "Arizona",
      postalCode: "85005-1234",
      country: "U.S.A.",
    },
    finance: {
      isEmailVerified: true,
      email: "ines.indrani@ovroom.com",
      phone: "6235551212",
      phoneCountryCode: "1",
    },
    support: {
      isEmailVerified: true,
      email: "ines.indrani@ovroom.com",
      phone: "6235551212",
      phoneCountryCode: "1",
    },
    tours: [{}, {}],
    isFinanceApproved: false,
    isPublicationApproved: false,
    createdAt: "2020-11-14T20:56:41.271Z",
    lastUpdatedAt: "2020-11-14T20:56:41.271Z",
    _id: "5fb045fc94e9c6658f30a695",
    name: "Ines, LLC",
    friendlyName: "INLLC",
    phone: "6235551219",
    phoneCountryCode: "1",
    users: [
      {
        isOwner: true,
        user: {
          _id: "5fb045fb94e9c6658f30a688",
          firstName: "Ines",
          lastName: "Indrani",
        },
      },
    ],
  },

  {
    address: {
      street: "124 Alice Avenue, Suite 101",
      city: "Phoenix",
      state: "Arizona",
      postalCode: "85001-1234",
      country: "U.S.A.",
    },
    finance: {
      isEmailVerified: true,
      email: "bobFinance@ovroom.com",
      phone: "6235551212",
      phoneCountryCode: "1",
    },
    support: {
      isEmailVerified: true,
      email: "bobSupport@ovroom.com",
      phone: "6235551212",
      phoneCountryCode: "1",
    },
    tours: [],
    isFinanceApproved: false,
    isPublicationApproved: true,
    createdAt: "2020-11-14T20:56:41.271Z",
    lastUpdatedAt: "2020-11-14T20:56:41.271Z",
    _id: "5fb045f594e9c6658f30a602",
    name: "Bob, LLC",
    friendlyName: "BOLLC",
    phone: "6235551213",
    phoneCountryCode: "1",
    users: [
      {
        isOwner: true,
        user: {
          _id: "5fb045f494e9c6658f30a5f4",
          firstName: "Bob",
          lastName: "Biden",
        },
      },
      {
        isOwner: true,
        user: {
          _id: "5fb045f794e9c6658f30a63c",
          firstName: "Erica",
          lastName: "Ebis",
        },
      },
    ],
    publicationApprovedBy: {
      _id: "5fb045f094e9c6658f30a5cb",
      firstName: "Chinmay",
      lastName: "Patel",
    },
  },
  {
    address: {
      street: "125 Alice Avenue, Suite 102",
      city: "Phoenix",
      state: "Arizona",
      postalCode: "85002-1234",
      country: "U.S.A.",
    },
    finance: {
      isEmailVerified: true,
      email: "chrisFinance@ovroom.com",
      phone: "6235551212",
      phoneCountryCode: "1",
    },
    support: {
      isEmailVerified: true,
      email: "chrisSupport@ovroom.com",
      phone: "6235551212",
      phoneCountryCode: "1",
    },
    tours: [{}, {}, {}, {}],
    isFinanceApproved: false,
    isPublicationApproved: false,
    createdAt: "2020-11-14T20:56:41.271Z",
    lastUpdatedAt: "2020-11-14T20:56:41.271Z",
    _id: "5fb045f694e9c6658f30a61a",
    name: "Chris, LLC",
    friendlyName: "CHLLC",
    phone: "6235551214",
    phoneCountryCode: "1",
    users: [
      {
        isOwner: true,
        user: {
          _id: "5fb045f594e9c6658f30a60c",
          firstName: "Chris",
          lastName: "Connely",
        },
      },
      {
        isOwner: false,
        user: {
          _id: "5fb045f794e9c6658f30a63c",
          firstName: "Erica",
          lastName: "Ebis",
        },
      },
    ],
  },
  {
    address: {
      street: "126 Alice Avenue, Suite 103",
      city: "Phoenix",
      state: "Arizona",
      postalCode: "85003-1234",
      country: "U.S.A.",
    },
    finance: {
      isEmailVerified: true,
      email: "deniseFinance@ovroom.com",
      phone: "6235551212",
      phoneCountryCode: "1",
    },
    support: {
      isEmailVerified: true,
      email: "deniseSupport@ovroom.com",
      phone: "6235551212",
      phoneCountryCode: "1",
    },
    tours: [],
    isFinanceApproved: false,
    isPublicationApproved: false,
    createdAt: "2020-11-14T20:56:41.271Z",
    lastUpdatedAt: "2020-11-14T20:56:41.271Z",
    _id: "5fb045f794e9c6658f30a632",
    name: "Denise, LLC",
    friendlyName: "DNLLC",
    phone: "6235551214",
    phoneCountryCode: "1",
    users: [
      {
        isOwner: true,
        user: {
          _id: "5fb045f694e9c6658f30a624",
          firstName: "Denise",
          lastName: "Dohortey",
        },
      },
    ],
  },
  {
    address: {
      street: "127 Erica Avenue, Suite 103",
      city: "Phoenix",
      state: "Arizona",
      postalCode: "85004-1234",
      country: "U.S.A.",
    },
    finance: {
      isEmailVerified: true,
      email: "erica.ebis@ovroom.com",
      phone: "6235551212",
      phoneCountryCode: "1",
    },
    support: {
      isEmailVerified: true,
      email: "erica.ebis@ovroom.com",
      phone: "6235551212",
      phoneCountryCode: "1",
    },
    tours: [],
    isFinanceApproved: false,
    isPublicationApproved: false,
    createdAt: "2020-11-14T20:56:41.271Z",
    lastUpdatedAt: "2020-11-14T20:56:41.271Z",
    _id: "5fb045f894e9c6658f30a649",
    name: "Erica, LLC",
    friendlyName: "ERLLC",
    phone: "6235551215",
    phoneCountryCode: "1",
    users: [
      {
        isOwner: true,
        user: {
          _id: "5fb045f794e9c6658f30a63c",
          firstName: "Erica",
          lastName: "Ebis",
        },
      },
    ],
  },
  {
    address: {
      street: "128 Frank Avenue, Suite 103",
      city: "Phoenix",
      state: "Arizona",
      postalCode: "85005-1234",
      country: "U.S.A.",
    },
    finance: {
      isEmailVerified: true,
      email: "frank.fotedar@ovroom.com",
      phone: "6235551212",
      phoneCountryCode: "1",
    },
    support: {
      isEmailVerified: true,
      email: "frank.fotedar@ovroom.com",
      phone: "6235551212",
      phoneCountryCode: "1",
    },
    tours: [],
    isFinanceApproved: false,
    isPublicationApproved: false,
    createdAt: "2020-11-14T20:56:41.271Z",
    lastUpdatedAt: "2020-11-14T20:56:41.271Z",
    _id: "5fb045f994e9c6658f30a65c",
    name: "Frank, LLC",
    friendlyName: "FNLLC",
    phone: "6235551216",
    phoneCountryCode: "1",
    users: [
      {
        isOwner: true,
        user: {
          _id: "5fb045f894e9c6658f30a64f",
          firstName: "Frank",
          lastName: "Fotedar",
        },
      },
    ],
  },
  {
    address: {
      street: "128 Greg Avenue, Suite 103",
      city: "Phoenix",
      state: "Arizona",
      postalCode: "85005-1234",
      country: "U.S.A.",
    },
    finance: {
      isEmailVerified: true,
      email: "greg.gulati@ovroom.com",
      phone: "6235551212",
      phoneCountryCode: "1",
    },
    support: {
      isEmailVerified: true,
      email: "greg.gulati@ovroom.com",
      phone: "6235551212",
      phoneCountryCode: "1",
    },
    tours: [],
    isFinanceApproved: false,
    isPublicationApproved: false,
    createdAt: "2020-11-14T20:56:41.271Z",
    lastUpdatedAt: "2020-11-14T20:56:41.271Z",
    _id: "5fb045fa94e9c6658f30a66f",
    name: "Greg, LLC",
    friendlyName: "GRLLC",
    phone: "6235551217",
    phoneCountryCode: "1",
    users: [
      {
        isOwner: true,
        user: {
          _id: "5fb045f994e9c6658f30a662",
          firstName: "Greg",
          lastName: "Gulati",
        },
      },
    ],
  },
  {
    address: {
      street: "128 Henry Avenue, Suite 103",
      city: "Phoenix",
      state: "Arizona",
      postalCode: "85005-1234",
      country: "U.S.A.",
    },
    finance: {
      isEmailVerified: true,
      email: "henry.hamza@ovroom.com",
      phone: "6235551212",
      phoneCountryCode: "1",
    },
    support: {
      isEmailVerified: true,
      email: "henry.hamza@ovroom.com",
      phone: "6235551212",
      phoneCountryCode: "1",
    },
    tours: [],
    isFinanceApproved: false,
    isPublicationApproved: false,
    createdAt: "2020-11-14T20:56:41.271Z",
    lastUpdatedAt: "2020-11-14T20:56:41.271Z",
    _id: "5fb045fb94e9c6658f30a682",
    name: "Henry, LLC",
    friendlyName: "HNLLC",
    phone: "6235551218",
    phoneCountryCode: "1",
    users: [
      {
        isOwner: true,
        user: {
          _id: "5fb045fa94e9c6658f30a675",
          firstName: "Henry",
          lastName: "Hamza",
        },
      },
    ],
  },

  {
    address: {
      street: "123 Alice Avenue, Suite 100",
      city: "Phoenix",
      state: "Arizona",
      postalCode: "85000-1234",
      country: "U.S.A.",
    },
    finance: {
      isEmailVerified: true,
      email: "aliceFinance@ovroom.com",
      phone: "6235551212",
      phoneCountryCode: "1",
    },
    support: {
      isEmailVerified: true,
      email: "aliceSupport@ovroom.com",
      phone: "6235551212",
      phoneCountryCode: "1",
    },
    tours: [],
    isFinanceApproved: true,
    isPublicationApproved: true,
    createdAt: "2020-11-14T20:56:41.271Z",
    lastUpdatedAt: "2020-11-14T20:56:41.271Z",
    _id: "5fb045f494e9c6658f30a5ea",
    name: "Alice, LLC",
    friendlyName: "ALLC",
    phone: "6235551212",
    phoneCountryCode: "1",
    users: [
      {
        isOwner: true,
        user: {
          _id: "5fb045f294e9c6658f30a5dc",
          firstName: "Alice",
          lastName: "Arnette",
        },
      },
      {
        isOwner: true,
        user: {
          _id: "5fb045f794e9c6658f30a63c",
          firstName: "Erica",
          lastName: "Ebis",
        },
      },
    ],
    publicationApprovedBy: {
      _id: "5fb045f094e9c6658f30a5cb",
      firstName: "Chinmay",
      lastName: "Patel",
    },
  },
  {
    address: {
      street: "128 James Avenue, Suite 103",
      city: "Phoenix",
      state: "Arizona",
      postalCode: "85005-1234",
      country: "U.S.A.",
    },
    finance: {
      isEmailVerified: true,
      email: "james.jugal@ovroom.com",
      phone: "6235551212",
      phoneCountryCode: "1",
    },
    support: {
      isEmailVerified: true,
      email: "james.jugal@ovroom.com",
      phone: "6235551212",
      phoneCountryCode: "1",
    },
    tours: [],
    isFinanceApproved: false,
    isPublicationApproved: false,
    createdAt: "2020-11-14T20:56:41.271Z",
    lastUpdatedAt: "2020-11-14T20:56:41.271Z",
    _id: "5fb045fd94e9c6658f30a6a8",
    name: "James, LLC",
    friendlyName: "JMLLC",
    phone: "6235551220",
    phoneCountryCode: "1",
    users: [
      {
        isOwner: true,
        user: {
          _id: "5fb045fd94e9c6658f30a69b",
          firstName: "James",
          lastName: "Jugal",
        },
      },
    ],
  },
];

export function getOrgs() {
  return orgs;
}

// Return all orgs whose publication approval is pending
export function getPendingPublicationApprovalOrgs() {
  return orgs.filter((o) => !o.isPublicationApproved);
}

// Return all orgs whose finance approval is pending
export function getPendingFinanceApprovalOrgs() {
  return orgs.filter((o) => !o.isFinanceApproved);
}

// Return all orgs owned by me
export function getMyOrgs(userId) {
  return orgs.filter((o) => {
    let rval = false;
    o.users.forEach((user) => {
      if (_.isEqual(user.user._id, userId) && user.isOwner) {
        rval = true;
      }
    });
    return rval;
  });
}
