import React from "react";
import Joi from "joi";
import { ToastContainer } from "react-toastify";

import * as operatorTourService from "../../../../services/operatorTourService";
import * as authService from "../../../../services/authService";
import Form from "../../../common/form";
import "../../../../css/ovroomFormPage.css";
import "../../../../css/createTourDetails.css";

import { assetsURL, logoImageName } from "../../../../config.json";

import UserContext from "../../../../context/userContext";

class TourHighlights extends Form {
  isMobile = window.innerWidth < 480;

  // Context used
  static contextType = UserContext;

  state = {
    data: {
      highlights: [],
    },
    optionalData: {
      description: "",
    },
    editHighlightData: {
      description: "",
    },
    errors: {},
    loading: false,
    changed: false,
    editingHighlight: false,
  };

  schema = Joi.object({
    highlights: Joi.array(),
  });

  edithHighlightDataSchema = Joi.object({
    description: Joi.string()
      .trim()
      .min(2)
      .max(100)
      .required()
      .label("Highlight"),
  });

  optionalDataSchema = Joi.object({
    description: Joi.string()
      .trim()
      .min(2)
      .max(100)
      .required()
      .label("Highlight"),
  });

  validateEditHighlightProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const rule = this.edithHighlightDataSchema.extract(name);
    const schema = Joi.object({ [name]: rule });
    const { error } = schema.validate(obj);
    return error ? error.details[0].message : null;
  };

  validateEditHightlight = () => {
    const { editHighlightData } = this.state;
    const { error } = this.edithHighlightDataSchema.validate(
      editHighlightData,
      {
        abortEarly: false,
      }
    );
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  validateHighlightProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const rule = this.optionalDataSchema.extract(name);
    const schema = Joi.object({ [name]: rule });
    const { error } = schema.validate(obj);
    return error ? error.details[0].message : null;
  };

  validateHighlight = () => {
    const { optionalData } = this.state;
    const { error } = this.optionalDataSchema.validate(optionalData, {
      abortEarly: false,
    });
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  handleChangeEditHighlight = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };

    let errorMessage;

    errorMessage = this.validateEditHighlightProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name]; // If no errors, remove any existing errors for this field, if any.

    let editHighlightData = { ...this.state.editHighlightData };
    editHighlightData[input.name] = input.value;
    this.setState({ editHighlightData });
  };

  handleChangeOfHighlights = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };

    let errorMessage;

    errorMessage = this.validateHighlightProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name]; // If no errors, remove any existing errors for this field, if any.

    let optionalData = { ...this.state.optionalData };
    optionalData[input.name] = input.value;
    this.setState({ optionalData, errors });
  };

  handleAddHighlight = () => {
    let highlights = [...this.state.data.highlights];
    let optionalData = { ...this.state.optionalData };
    let highlight = {
      id: new Date(),
      description: optionalData.description,
    };
    highlights.push(highlight);

    // Reset optional data
    optionalData.description = "";

    this.setState({ data: { highlights }, optionalData, changed: true });
  };

  componentDidMount = () => {
    let { highlights } = this.props.location.state.tour;
    if (!highlights) return;
    let i = 0;
    highlights = highlights.map((d) => {
      return {
        id: i++,
        description: d.description,
        editing: false, // Is the highlight being edited?
      };
    });
    this.setState({ data: { highlights } });
  };

  doCancel = () => {
    this.props.history.goBack();
  }

  doSubmit = async () => {
    const { data } = this.state;
    let highlights = data.highlights.map((u) => {
      return { description: u.description };
    });

    const { tour } = this.props.location.state;

    // Update the server
    let response;
    try {
      this.setState({ loading: true });
      response = await operatorTourService.updateHighlights(
        tour._id,
        highlights
      );
    } catch (e) {
    } finally {
      this.setState({ loading: false });
      if (response && response.status === 200) this.props.history.goBack();
    }
  };

  handleEditHighlight = (highlight) => {
    let index = this.state.data.highlights.findIndex(
      (u) => u.id === highlight.id
    );
    highlight.editing = true;
    let highlights = this.state.data.highlights.filter(
      (u) => u.id !== highlight.id
    );
    highlights.splice(index, 0, highlight);
    let editHighlightData = { ...this.state.editHighlightData };
    editHighlightData.title = highlight.title;
    editHighlightData.description = highlight.description;
    this.setState({
      data: { highlights },
      editHighlightData,
      editingHighlight: true,
    });
  };

  handleCancelEdit = (highlight) => {
    let index = this.state.data.highlights.findIndex(
      (u) => u.id === highlight.id
    );
    highlight.editing = false;
    let highlights = this.state.data.highlights.filter(
      (u) => u.id !== highlight.id
    );
    highlights.splice(index, 0, highlight);
    this.setState({ data: { highlights }, editingHighlight: false });
  };

  handleSaveHighlight = (highlight) => {
    let index = this.state.data.highlights.findIndex(
      (u) => u.id === highlight.id
    );
    let highlights = this.state.data.highlights.filter(
      (u) => u.id !== highlight.id
    );
    let updatedDetail = { ...this.state.editHighlightData };
    updatedDetail["id"] = new Date();
    updatedDetail["editing"] = false;
    highlights.splice(index, 0, updatedDetail);
    this.setState({
      data: { highlights },
      editingHighlight: false,
      changed: true,
    });
  };

  handleDeleteHighlight = (highlight) => {
    let highlights = this.state.data.highlights.filter(
      (u) => u.id !== highlight.id
    );
    this.setState({ data: { highlights }, changed: true });
  };

  renderEachHighlight = (highlight) => {
    const { editHighlightData } = this.state;
    const { errors } = this.state;
    return (
      <div key={highlight.id}>
        {!highlight.editing && (
          <div className="existing-detail-container">
            <div className="existing-detail-removebutton-container">
              <button
                className="btn btn-sm btn-primary"
                onClick={() => this.handleEditHighlight(highlight)}
                disabled={this.state.editingHighlight}
              >
                <i className="bi bi-pencil-square"></i> Edit
              </button>

              <label
                className="btn btn-sm"
                style={{ color: "var(--color-kesudo)" }}
                onClick={() => this.handleDeleteHighlight(highlight)}
              >
                <i className="bi bi-trash"></i>
              </label>
            </div>
            <div>
              <div>
                <p>{highlight.description}</p>
              </div>
            </div>
          </div>
        )}

        {highlight.editing && (
          <div className="existing-detail-container" key={highlight.id}>
            <div className="existing-detail-removebutton-container">
              <button
                className="btn btn-sm btn-primary"
                onClick={() => this.handleSaveHighlight(highlight)}
              >
                <i className="bi bi-save"></i> Save
              </button>

              <button
                className="btn btn-sm"
                onClick={() => this.handleCancelEdit(highlight)}
              >
                Cancel
              </button>
            </div>
            <div>
              <div>
                <textarea
                  id="description"
                  name="description"
                  value={editHighlightData["description"]}
                  error={errors["description"]}
                  type="text"
                  rows={5}
                  onChange={this.handleChangeEditHighlight}
                  className="form-control input-form mt-2"
                />
                {errors["description"] && (
                  <div className="alert alert-danger">
                    {errors["description"]}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  renderHighlights = () => {
    const { highlights } = this.state.data;

    return (
      <React.Fragment>
        {highlights.map((highlight) => this.renderEachHighlight(highlight))}
      </React.Fragment>
    );
  };

  render() {
    const { optionalData } = this.state;
    const { errors } = this.state;

    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      //localStorage.setItem("UrlBeforeLogin", this.props.location.pathname);
      this.props.history.replace("/login");
      return null;
    }

    let containerClass,
      headerContainer,
      formContainer,
      logoImage,
      pageHeadingTextStyle,
      instructionsContainer,
      sectionHeadingTextStyle;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";
      headerContainer = "ovroom-form-header-container-mobile";
      instructionsContainer = "ovroom-form-instruction-container-option1";
      formContainer = "ovroom-form-elements-container-option1";
      sectionHeadingTextStyle = "ovroom-form-section-heading-mobile";
      logoImage = "logo-image-mobile";
      pageHeadingTextStyle = "ovroom-form-page-heading-mobile";
    } else {
      // desktop and tablet

      containerClass = "container ovroom-form-container";
      headerContainer = "ovroom-form-header-container";
      instructionsContainer = "ovroom-form-instruction-container-option1";
      sectionHeadingTextStyle = "ovroom-form-section-heading";
      formContainer = "ovroom-form-elements-container-option1";
      logoImage = "logo-image";
      pageHeadingTextStyle = "ovroom-form-page-heading";
    }

    return (
      <UserContext.Consumer>
        {(userContext) => (
          <div className={containerClass}>
            <div className={headerContainer}>
              <img
                className={logoImage}
                src={assetsURL + logoImageName}
                alt="Ovroom logo"
              />
              <p className={pageHeadingTextStyle}>Tour Highlights</p>
            </div>
            <div className={instructionsContainer}>
              <b>Optional.</b> If you would like to add highlights of the tour,
              please add upto 3 highlights. Describe each highlight in brief
              (upto 100 characters each).
            </div>
            <div className={formContainer}>
              <div className="new-detail-container">
                <div className="form-group input-form-group">
                  <div className="form-group input-form-group">
                    <label className="input-form-label" htmlFor="description">
                      Highlight
                    </label>
                    <textarea
                      id="description"
                      name="description"
                      value={optionalData["description"]}
                      error={errors["description"]}
                      type="text"
                      rows={2}
                      onChange={this.handleChangeOfHighlights}
                      className="form-control input-form"
                      disabled={this.state.data.highlights.length >= 3}
                    />
                    {errors["description"] && (
                      <div className="alert alert-danger">
                        {errors["description"]}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Do not allow more than 30 details */}
              <button
                disabled={
                  this.validateHighlight() ||
                  this.state.data.highlights.length >= 3
                }
                onClick={this.handleAddHighlight}
                className="btn btn-primary submit-button"
              >
                Add highlight
              </button>

              <div className="horizontal-line-container">
                <hr className="horizontal-line-primary-color" />
              </div>
              <label className={sectionHeadingTextStyle}>Tour highlights</label>
              {this.state.data.highlights.length === 0 && <p>No highlights</p>}
              {this.state.data.highlights.length > 0 && this.renderHighlights()}

              <div className="horizontal-line-container">
                <hr className="horizontal-line-primary-color" />
              </div>

              <form>{this.renderEditFormSubmitButton("Save and close")}</form>
              <div className="vertical-filler-container" />
            </div>
            <ToastContainer />
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default TourHighlights;
