import React from "react";
import Joi from "joi";
import DatePicker from "react-datepicker";
import { ToastContainer } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";

import * as tourService from "../../../../services/operatorTourService";
import * as authService from "../../../../services/authService";
import Form from "../../../common/form";
import "../../../../css/ovroomFormPage.css";
import "../../../../css/createTourLastStartDate.css";

import { assetsURL, logoImageName } from "../../../../config.json";

import UserContext from "../../../../context/userContext";

class TourLastStartDate extends Form {
  isMobile = window.innerWidth < 480;

  // Context used
  static contextType = UserContext;

  state = {
    data: {
      lastStartDate: null,
    },
    errors: {},
    loading: false,
    changed: false,
    lastStartDateForServer: "",
  };

  schema = Joi.object({
    lastStartDate: Joi.object().optional().allow(null),
  });

  handleChangeLastStartDate = (date) => {
    if (!date) return;
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const day = date.getDate().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const stringDate = `${year}-${month}-${day}`;

    this.setState({
      lastStartDateForServer: stringDate,
      data: { lastStartDate: date },
      changed: true,
    });
  };

  _getTourLastStartDate() {
    let { lastStartDate } = this.props.location.state.tour;
    const updatedDate = lastStartDate.includes("275760")
      ? ""
      : new Date(lastStartDate);
    return updatedDate;
  }

  componentDidMount() {
    const date = this._getTourLastStartDate();
    this.setState({ data: { lastStartDate: date } });
  }

  doCancel = () => {
    this.props.history.goBack();
  };

  doSubmit = async () => {
    let { tour } = this.props.location.state;
    const { lastStartDateForServer } = this.state;
    // Update the server
    let response;
    try {
      this.setState({ loading: true });
      response = await tourService.updateLastStartDate(
        tour._id,
        lastStartDateForServer
      );
    } catch (e) {
    } finally {
      this.setState({ loading: false });

      if (response && response.status === 200) this.props.history.goBack();
      else {
        const date = this._getTourLastStartDate();
        this.setState({ data: { lastStartDate: date }, changed: false });
      }
    }
  };

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      //localStorage.setItem("UrlBeforeLogin", this.props.location.pathname);
      this.props.history.replace("/login");
      return null;
    }

    let containerClass,
      headerContainer,
      formContainer,
      logoImage,
      pageHeadingTextStyle,
      instructionsContainer;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";
      headerContainer = "ovroom-form-header-container-mobile";
      instructionsContainer = "ovroom-form-instruction-container-option1";
      formContainer = "ovroom-form-elements-container-option1";
      logoImage = "logo-image-mobile";
      pageHeadingTextStyle = "ovroom-form-page-heading-mobile";
    } else {
      // desktop and tablet

      containerClass = "container ovroom-form-container";
      headerContainer = "ovroom-form-header-container";
      instructionsContainer = "ovroom-form-instruction-container-option1";
      formContainer = "ovroom-form-elements-container-option1";
      logoImage = "logo-image";
      pageHeadingTextStyle = "ovroom-form-page-heading";
    }

    const { lastStartDate } = this.state.data;

    const minStartDate = new Date();
    let maxStartDate = new Date();

    // Allow specifying a last start date for 3 years from now
    maxStartDate = new Date(
      maxStartDate.setMonth(maxStartDate.getMonth() + 3 * 12)
    );

    return (
      <UserContext.Consumer>
        {(userContext) => (
          <div className={containerClass}>
            <div className={headerContainer}>
              <img
                className={logoImage}
                src={assetsURL + logoImageName}
                alt="Ovroom logo"
              />
              <p className={pageHeadingTextStyle}>Tour Last Start Date</p>
            </div>
            <div className={instructionsContainer}>
              <b>Optional.</b> If you do not wish to conduct this tour after a
              certain date, please specify it below.
            </div>
            <div className={formContainer}>
              <div className="last-start-date-picker-container">
                <DatePicker
                  className="ovroom-date-picker-container-style"
                  selected={lastStartDate}
                  onChange={(date) => this.handleChangeLastStartDate(date)}
                  placeholderText="Last start date"
                  minDate={minStartDate}
                  maxDate={maxStartDate}
                />
              </div>

              {this.renderEditFormSubmitButton("Save and close")}

              <div className="vertical-filler-container" />
            </div>
            <ToastContainer />
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default TourLastStartDate;
