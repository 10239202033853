import React from "react";
import Joi from "joi";

import * as authService from "../../../../services/authService";
import Form from "../../../common/form";
import "../../../../css/ovroomFormPage.css";

class CreateTourTourScheduleForm extends Form {
  isMobile = window.innerWidth < 480;

  state = {
    data: {
      slots1: "", // January
      slots2: "", // February
      slots3: "", // March
      slots4: "", // April
      slots5: "", // May
      slots6: "", // June
      slots7: "", // July
      slots8: "", // August
      slots9: "", // September
      slots10: "", // October
      slots11: "", // November
      slots12: "", // December
    },
    errors: {},
    loading: false,
  };

  schema = Joi.object({
    slots1: Joi.number()
      .positive()
      .integer()
      .min(1)
      .required()
      .label("Number of simultaneous tours"),
    slots2: Joi.number()
      .positive()
      .integer()
      .min(1)
      .required()
      .label("Number of simultaneous tours"),
    slots3: Joi.number()
      .positive()
      .integer()
      .min(1)
      .required()
      .label("Number of simultaneous tours"),
    slots4: Joi.number()
      .positive()
      .integer()
      .min(1)
      .required()
      .label("Number of simultaneous tours"),
    slots5: Joi.number()
      .positive()
      .integer()
      .min(1)
      .required()
      .label("Number of simultaneous tours"),
    slots6: Joi.number()
      .positive()
      .integer()
      .min(1)
      .required()
      .label("Number of simultaneous tours"),
    slots7: Joi.number()
      .positive()
      .integer()
      .min(1)
      .required()
      .label("Number of simultaneous tours"),
    slots8: Joi.number()
      .positive()
      .integer()
      .min(1)
      .required()
      .label("Number of simultaneous tours"),
    slots9: Joi.number()
      .positive()
      .integer()
      .min(1)
      .required()
      .label("Number of simultaneous tours"),
    slots10: Joi.number()
      .positive()
      .integer()
      .min(1)
      .required()
      .label("Number of simultaneous tours"),
    slots11: Joi.number()
      .positive()
      .integer()
      .min(1)
      .required()
      .label("Number of simultaneous tours"),
    slots12: Joi.number()
      .positive()
      .integer()
      .min(1)
      .required()
      .label("Number of simultaneous tours"),
  });

  doSubmit = async () => {
    const { data } = this.state;
    const schedule = {
      scheduleType: "Tour",
      slots: [
        data.slots1,
        data.slots2,
        data.slots3,
        data.slots4,
        data.slots5,
        data.slots6,
        data.slots7,
        data.slots8,
        data.slots9,
        data.slots10,
        data.slots11,
        data.slots12,
      ],
    };
    this.props.onSubmitForm(schedule); // Call the parent component for further actions
  };

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      //localStorage.setItem("UrlBeforeLogin", this.props.location.pathname);
      this.props.history.replace("/login");
      return null;
    }

    let sectionHeadingTextStyle;
    if (this.isMobile) {
      sectionHeadingTextStyle = "ovroom-form-section-heading-mobile";
    } else {
      // desktop and tablet

      sectionHeadingTextStyle = "ovroom-form-section-heading";
    }

    return (
      <React.Fragment>
        <form>
          <label className={sectionHeadingTextStyle}>January</label>
          {this.renderInput("slots1", "Number of simultaneous tours", true)}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>
          <label className={sectionHeadingTextStyle}>February</label>
          {this.renderInput("slots2", "Number of simultaneous tours", false)}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>
          <label className={sectionHeadingTextStyle}>March</label>
          {this.renderInput("slots3", "Number of simultaneous tours", false)}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>
          <label className={sectionHeadingTextStyle}>April</label>
          {this.renderInput("slots4", "Number of simultaneous tours", false)}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>
          <label className={sectionHeadingTextStyle}>May</label>
          {this.renderInput("slots5", "Number of simultaneous tours", false)}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>
          <label className={sectionHeadingTextStyle}>June</label>
          {this.renderInput("slots6", "Number of simultaneous tours", false)}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>
          <label className={sectionHeadingTextStyle}>July</label>
          {this.renderInput("slots7", "Number of simultaneous tours", false)}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>
          <label className={sectionHeadingTextStyle}>August</label>
          {this.renderInput("slots8", "Number of simultaneous tours", false)}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>
          <label className={sectionHeadingTextStyle}>September</label>
          {this.renderInput("slots9", "Number of simultaneous tours", false)}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>
          <label className={sectionHeadingTextStyle}>October</label>
          {this.renderInput("slots10", "Number of simultaneous tours", false)}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>
          <label className={sectionHeadingTextStyle}>November</label>
          {this.renderInput("slots11", "Number of simultaneous tours", false)}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>
          <label className={sectionHeadingTextStyle}>December</label>
          {this.renderInput("slots12", "Number of simultaneous tours", false)}

          {this.renderSubmitButton("Save and next")}
        </form>
      </React.Fragment>
    );
  }
}

export default CreateTourTourScheduleForm;
