import React, { Component } from "react";
import { Link } from "react-router-dom";
import EmailForm from "./emailForm";
import PhoneForm from "./phoneForm";

import "../css/loginForm.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import { assetsURL, logoImageName } from "../config.json";

class LoginForm extends Component {
  isMobile = window.innerWidth < 480;

  doSubmitEmail = () => {
    let urlBeforeLogin = localStorage.getItem("UrlBeforeLogin");
    if (urlBeforeLogin) {
      localStorage.removeItem("UrlBeforeLogin");
      this.props.history.replace(urlBeforeLogin);
      //window.location =urlBeforeLogin; // Go to homepage, and also reload the application from server
    } else {
      // Send user to Home page
      this.props.history.replace("/");
      //window.location = "/"; // Go to homepage, and also reload the application from server
    }
  };

  doSubmitPhone = () => {
    // Send user to the appropriate page
    this.props.history.push("/verify-code");
  };

  render() {
    let containerClass,
      headerContainer,
      emailFormContainerClass,
      phoneFormContainerClass,
      logoImage,
      loginLabelText,
      loginRegisterContainerClass,
      sectionHeadingTextClass;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";
      headerContainer = "ovroom-form-header-container-mobile";
      emailFormContainerClass = "emailForm-container-mobile";
      phoneFormContainerClass = "phoneForm-container";
      logoImage = "logo-image-mobile";
      loginLabelText = "login-label-text-mobile";
      loginRegisterContainerClass = "login-regisger-container-mobile";
      //sectionHeadingTextClass = "login-section-heading-text-mobile";
      sectionHeadingTextClass = "ovroom-form-section-heading-mobile";
    } else {
      // desktop and tablet
      containerClass = "container ovroom-form-container";
      headerContainer = "ovroom-form-header-container";
      emailFormContainerClass = "emailForm-container";
      phoneFormContainerClass = "phoneForm-container-mobile";
      logoImage = "logo-image";
      loginLabelText = "login-label-text";
      loginRegisterContainerClass = "login-regisger-container";
      //sectionHeadingTextClass = "login-section-heading-text";
      sectionHeadingTextClass = "ovroom-form-section-heading";
    }
    return (
      <div className={containerClass}>
        <div className={headerContainer}>
          <img
            className={logoImage}
            src={assetsURL + logoImageName}
            alt="Ovroom logo"
          />
          <p className={loginLabelText}>Login</p>
        </div>
        <div className={emailFormContainerClass}>
          <div className="login-section-heading-container">
            <label className={sectionHeadingTextClass}>Login with email</label>
          </div>
          <EmailForm
            onSubmitForm={this.doSubmitEmail}
            onNotification={this.handleNotifiction}
          />
        </div>
        <div className="horizontal-line-container-1 horizontal-line-container">
          <hr className="horizontal-line-primary-color" />
        </div>
        <div className={phoneFormContainerClass}>
          <div className="login-section-heading-container">
            <label className={sectionHeadingTextClass}>
              Login with mobile phone number
            </label>
          </div>
          <PhoneForm onSubmitForm={this.doSubmitPhone} />
        </div>
        <div className="horizontal-line-container-2 horizontal-line-container">
          <hr className="horizontal-line-primary-color" />
        </div>
        <div className={loginRegisterContainerClass}>
          Don't have an account? <Link to="/register">Register here.</Link>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default LoginForm;
