import React from "react";
import PropTypes from "prop-types";

import "../../css/checkbox.css";

// Form's Checkbox component
// Renders a label with text field and error messages for a Form.
//
// Input:
//      The props of this component should have:
//        name (String, required) - Name of the specific input field in the form. This should be unique in a given form.
//        text (String, required) - Label to be displayed ABOVE the text-field.
//        value (String, required) - Value of the checkbox
//        onChange (function, required) - The method to call in the calling-component when the value of the input field changes (user types).
//        autoFocus (Boolean, optional) - If set, the field is rendered with FOCUS.
//        error (String, optional) - If present, it is shown BELOW the input field as error.
//

const Checkbox = ({ name, text, value, onChange, error, ...rest }) => {
  return (
    <div className="form-group input-form-group">
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value={value}
          id={name}
          name={name}
          onChange={onChange}
        />
        <p for={name}>{text}</p>
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default Checkbox;
