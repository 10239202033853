import http from "./httpService";
import { apiURL } from "../config.json";

const apiEndpoint = apiURL + "/operator/";

// registerOperator
// Register a new tour operator.
//
// Input:
//  organization object with folloing fields:
//      name - String - Name of the organization
//      friendlyName - String - Friendly name of the organization
//      phone - Number - Phone number of the organization
//      phoneCountryCode - Number - Country code of the organization
//
//      aboutInfo - String - Descrption paragraph of the organization
//
//      finance - Object with following fields
//      {
//          email - String - Finance email of the oranization
//          phone - Number - Finance phone number of the organization
//          phoneCountryCode - Number - Finance country code of the organization
//      }
//
//      support - Object with following fields
//      {
//          email - String - Support email of the oranization
//          phone - Number - Support phone number of the organization
//          phoneCountryCode - Number - Support country code of the organization
//      }
//
export function registerOperator(organization) {
  const url = apiEndpoint + "organizations";

  // The following HTTP request returns a promise
  return http.post(url, organization);
}

// getOrganization
// Get details of the operator organization
//
// Input: JWT in the header. The requestor must be Owner of the organization.
//
// Returns:
//  Status 200 if OK, and the payload should be Organization structure.
//  Error code if encountered error.
//
export function getOrganization() {
  const url = apiEndpoint + "organizations";

  // The following HTTP request returns a promise
  return http.get(url);
}

// getOrganizationUsers
// Get list of users at an organization
//
// Input: JWT in the header. The requestor must be Owner of the organization.
//
// Returns:
//  Status 200 if OK, and the payload should be array of organization users.
//  Error code if encountered error.
//
export function getOrganizationUsers() {
  const url = apiEndpoint + "organizations/users";

  // The following HTTP request returns a promise
  return http.get(url);
}
