import React, { Component } from "react";
import _ from "lodash";

import OperatorViewUpcomingReservation from "./viewUpcoming";
import Pagination from "../../../components/common/pagination";
import Table from "../../common/table";
import { paginate } from "../../../utils/paginate";

import * as authService from "../../../services/authService";
import * as userService from "../../../services/userService";
import * as reservationService from "../../../services/operatorReservationService";

import "../../../css/app.css";
import "../../../css/search.css";

import OperatorContext from "../../../context/operatorContext";

class OperatorCanceledReservations extends Component {
  isMobile = window.innerWidth < 480;

  // Context used
  static contextType = OperatorContext;

  // Tour selection state
  ALLRESERVATIONS = "allreservations"; // Show listing of all reservations
  VIEWRESERVATION = "viewreservation"; // View details of a reservation

  totalReservations = 0;
  returnedReservations = 0;
  limit = 0;
  skip = 0;

  search = "";

  state = {
    reservations: [],
    sortColumn: { path: "startDate", order: "asc" },
    loading: true,
    currentPage: 1,
    pageSize: 5,
    total: 0,
    search: "",
  };

  //********************************************
  // Private methods
  //********************************************

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      reservations: allReservations,
      sortColumn,
      search,
    } = this.state;

    let filteredReservations;

    // Filter by search query
    if (search) {
      // Find all reservations by confirmation number
      const filteredReservationsByConfirmation = allReservations.filter((r) =>
        r.confirmation.toLowerCase().startsWith(search.toLowerCase())
      );

      // Find all reservations by tourist names
      const filteredReservationsByTouristName = allReservations.filter((r) =>
        r.touristName.toLowerCase().includes(search.toLowerCase())
      );

      filteredReservations = [...filteredReservationsByConfirmation];

      // If a reservation does not exist in the filteredReservations, then add it from the
      // array of filteredReservationsByTouristName.
      filteredReservationsByTouristName.forEach((res) => {
        let i = _.findIndex(filteredReservations, { _id: res._id });
        if (i <= 0) {
          filteredReservations.push(res);
        }
      });
    }
    // Return all reservations
    else filteredReservations = [...allReservations];

    const sortedReservations = _.orderBy(
      filteredReservations,
      [sortColumn.path],
      [sortColumn.order]
    );

    const reservations = paginate(sortedReservations, currentPage, pageSize);

    return { totalCount: filteredReservations.length, data: reservations };
  };

  // Get all upcoming reservations from the server
  async getUpcomingReservations() {
    let response;
    try {
      const search = this.state.search === "" ? null : this.state.search;
      response = await reservationService.getCanceledReservations(search);
    } catch (e) {
    } finally {
      this.setState({ loading: false });
    }
    if (response && response.status === 200) {
      this.setState({
        reservations: this.mapReservationsViewModel(response.data.reservations), // map data received to the client's view model
        total: response.data.total,
      });
    }
  }

  // Map the data recevied from the server to the client's view model
  mapReservationsViewModel(inReservations) {
    let reservations = inReservations.map((r) => {
      return {
        _id: r._id,
        confirmation: r.confirmation,
        startDate: r.duration.startDate,
        touristName: r.tourist.firstName + " " + r.tourist.lastName,
        tourTitle: r.tour.tourTitle,
        status: r.fulfillmentStatus,
      };
    });
    return reservations;
  }

  handlePageChange = async (page) => {
    if (page !== this.state.currentPage) this.setState({ currentPage: page });
  };

  async componentDidMount() {
    await this.getUpcomingReservations();
  }

  handleSearchButtonPressed = async () => {
    // await this.setState({ searchResultsDisplayed: true });
    // await this.getPagedDataAndSetState(1);
  };

  handleClearSearch = async () => {
    // IMPORTANT ::
    // The "setState" is asynch function, and unless we await it, it does not guarantee
    // that it will finish executing before calling the next function. So both asynch
    // functions below are awaited.
    await this.setState({ searchResultsDisplayed: false, search: "" });
    // await this.getPagedDataAndSetState(1);
  };

  // handleChange - Everytime something is typed in the search input, store it in the state.
  handleChange = ({ currentTarget: input }) => {
    let search = input.value;
    this.setState({ search });
  };

  handleSort = ({ sortColumn }) => {
    this.setState({ sortColumn });
  };

  handleViewReservation = (reservation) => {
    this.context.onOperatorManageReservationsSubSelectionChanged(
      this.context.operatorManageReservations.reservationSubSelection,
      this.VIEWRESERVATION,
      reservation._id
    );
  };

  handleShowAllReservations = () => {
    this.context.onOperatorManageReservationsSubSelectionChanged(
      this.context.operatorManageReservations.reservationSubSelection,
      this.ALLRESERVATIONS,
      null
    );
  };

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      this.props.history.replace("/login");
      return null;
    }

    // Get user info from the local storage. If it is not found, send the user to the log-in screen.
    const userInfo = userService.getUserFromLocalStorage();
    if (!userInfo) {
      this.props.history.replace("/login");
      return null;
    }

    const selectionState =
      this.context.operatorManageReservations.reservationSubSelectionState;

    const { length: count } = this.state.reservations;
    const { currentPage, pageSize, searchResultsDisplayed } = this.state;
    const { totalCount, data } = this.getPagedData();

    // Set up 'columns' property for 'Table' component.
    let columns = [
      {
        label: "Start Date",
        path: "startDate",
        key: "Start Date",
      },
      {
        label: "Confirmation",
        path: "confirmation",
        key: "Confirmation",
        content: (reservation) => (
          <button
            onClick={() => this.handleViewReservation(reservation)}
            className="btn btn-secondary-noborder"
          >
            {reservation.confirmation}
          </button>
        ),
      },
      {
        label: "Tourist",
        path: "touristName",
        key: "Tourist",
      },
    ];

    if (!this.isMobile) {
      columns = [
        ...columns,
        {
          label: "Tour",
          path: "tourTitle",
          key: "Tour",
        },
        {
          label: "Status",
          path: "status",
          key: "Status",
        },
      ];
    }

    return (
      <OperatorContext.Consumer>
        {(operatorContext) => (
          <React.Fragment>
            {selectionState === this.ALLRESERVATIONS && (
              <div>
                <div className="search-box-2">
                  <input
                    className="form-control mx-2 w-75"
                    type="search"
                    placeholder="Search..."
                    aria-label="Search"
                    onChange={this.handleChange}
                  />
                </div>

                {this.state.loading && (
                  <div className="view-spinner-container">
                    <span
                      className="spinner-border spinner-border-sm m-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </div>
                )}

                {!this.state.loading && count === 0 && (
                  <p>There are no reservations.</p>
                )}

                {!this.state.loading && count > 0 && (
                  <div>
                    <Table
                      data={data}
                      columns={columns}
                      sortColumn={this.state.sortColumn}
                      onSort={this.handleSort}
                      rowKey="_id"
                    />

                    <Pagination
                      itemsCount={totalCount}
                      pageSize={pageSize}
                      currentPage={currentPage}
                      onPageChange={this.handlePageChange}
                    />
                  </div>
                )}
              </div>
            )}

            {selectionState === this.VIEWRESERVATION && (
              <div>
                <OperatorViewUpcomingReservation
                  reservationId={this.context.operatorManageReservations.id}
                  onShowAllReservations={this.handleShowAllReservations}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </OperatorContext.Consumer>
    );
  }
}

export default OperatorCanceledReservations;
