import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import PropTypes from "prop-types";

import Table from "../common/table";

// OrgTable component
// Renders a organization table for Ovroom internal users.
//
// Input:
//      The props of this component should have:
//      organizations (Array of organizations, required) - Array of organizations that the OrgTable displays.
//      sortColumn (Object, required) - The column description of the sort column. This object looks as follows:
//              {
//                  path: <String that identifies the path to the column in the calling component>
//                  order: <String with values "asc" (for ascending order) or "desc" (for descending order)
//              }
//      onSort (function, required) - The method to call in the calling-component when sorting of a column event occurs
//      onDelete (function, required) - The method to call in the calling-compoinent when an organization is deleted (deactivated).
//
class OrgTable extends Component {
  columns = [
    // { label: "Name", path: "name" },

    {
      label: "Name",
      path: "name",
      key: "name_column_key", // Key to identify this column uniquely
      content: (org) => <Link to={`/movies/${org._id}`}>{org.name}</Link>,
    },

    { label: "Is Publication Approved", path: "isPublicationApproved" },
    { label: "Is Finance Approved", path: "isFinanceApproved" },
    { label: "Tours", path: "tours" },
    {
      label: "Deactivate",
      key: "deactivate_column_key", // Key to identify this column uniquely
      content: (org) => (
        <button
          onClick={() => this.props.onDelete(org)}
          className="btn btn-danger btn-sm m-2"
        >
          Deactivate
        </button>
      ),
    },
  ];

  _mapData = () => {
    const { organizations } = this.props;
    return organizations.map((o) => {
      let rOrg = _.omit(o, [
        "tours",
        "isPublicationApproved",
        "isFinanceApproved",
      ]);
      rOrg["tours"] = o.tours.length;
      rOrg["isPublicationApproved"] = o.isPublicationApproved ? "Yes" : "No";
      rOrg["isFinanceApproved"] = o.isFinanceApproved ? "Yes" : "No";
      return rOrg;
    });
  };

  render() {
    const { sortColumn, onSort } = this.props;

    let data = this._mapData();
    return (
      <Table
        columns={this.columns}
        sortColumn={sortColumn}
        onSort={onSort}
        rowKey="_id"
        data={data}
      />
    );
  }
}

OrgTable.propTypes = {
  organizations: PropTypes.array.isRequired,
  sortColumn: PropTypes.object.isRequired,
  onSort: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default OrgTable;
