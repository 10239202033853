import _ from "lodash";

// paginate
// This function takes an array (items), and returns only a portion of the array that represents
// the number of elements (equal to pageSize) on a given page (pageNumber). This function is used
// on client-side pagination of long lists. Given a particular pageNumber and pageSize, it returns
// the elements of the list that should be shown on that particular page.
//
// Input:
//  items (array, required) - Array of items that represent the whole list
//  pageNumber (Number, required) - The page number that needs to be displayed
//  pageSize (Number, required) - The size of each page
//
// Returns:
//  An array containing only the elements of 'items' which should appear on the given pageNumber
//  and quantity (pageSize).
//
export function paginate(items, pageNumber, pageSize) {
  const startIndex = (pageNumber - 1) * pageSize;
  return _(items).slice(startIndex).take(pageSize).value();
}
