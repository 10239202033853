import React, { Component } from "react";
import PropTypes from "prop-types";

import UploadTourImages from "./uploadTourImages";
import * as imageUrlUtils from "../../../utils/imageUrl";
import * as operatorTourService from "../../../services/operatorTourService";

import "../../../css/operator.css";
import "../../../css/ovroomFormPage.css";
import "../../../css/edit.css";

class OperatorEditTourImage extends Component {
  isMobile = window.innerWidth < 480;

  state = {
    images: undefined,
    fileUploadSuccess: false,
    uploadedImage: undefined,
    uploadedImageClearState: false,
  };

  handleImageUploadSuccess = (image) => {
    this.setState({ fileUploadSuccess: true, uploadedImage: image });
    this.props.onTourProfileImageAdded(this.props.tour);
  };

  handleFileUploadClose = () => {
    let images = [...this.state.images];
    images.push(this.state.uploadedImage);
    this.setState({
      fileUploadSuccess: false,
      uploadedImage: undefined,
      images,
      // Reset the flag so the UploadTourImages component resets its state
      uploadedImageClearState: true,
    });
  };

  handleUploadImageClicked = () => {
    // Reset the flag so the UploadTourImages component does not reset its state
    this.setState({ uploadedImageClearState: false });
  };

  handleDeleteImageIntention = ({ target }) => {
    let { images } = this.state;
    images.forEach((i) => {
      if (i.storedFileName === target.id) i.deleteIntention = true;
    });
    this.setState({ images });
  };

  handleDeleteImage = async ({ target }) => {
    let { images } = this.state;
    const filename = target.id;
    images = images.filter((i) => {
      if (!(i.storedFileName === target.id && i.deleteIntention === true))
        return i;
    });
    this.setState({ images });

    // Delete image on the server
    await operatorTourService.deleteImage(this.props.tour._id, filename, true);

    this.props.onTourProfileImageDeleted(this.props.tour);
  };

  handleDeleteImageCancel = ({ target }) => {
    let { images } = this.state;
    images.forEach((i) => {
      if (i.storedFileName === target.id) i.deleteIntention = false;
    });
    this.setState({ images });
  };

  handleImageView = ({ target }) => {
    //const image = this.state.images.find((i) => i.storedFileName === target.id);
    const imageUrl = imageUrlUtils.getImageUrl(target.id, "Operator");
    window.open(imageUrl);
  };

  handleProfileImageSelected = async (image, tour) => {
    let { images } = this.state;
    images.forEach((i) => {
      if (i.storedFileName === image.storedFileName) i.profilePhoto = true;
      else i.profilePhoto = false;
    });
    this.setState({ images });

    // Update the tour on the server

    try {
      await operatorTourService.setProfilePhoto(tour._id, image.storedFileName);
    } catch (e) {
      // Probably no need to revert back, since the next time we update from the server, correct data will be retrieved
    } finally {
      this.props.onTourProfileImageChanged(tour, image);
    }
  };

  componentDidMount() {
    let { images } = this.props.tour;
    images = images.map((i) => {
      return { ...i, deleteIntention: false };
    });
    this.setState({ images });
  }

  renderOneImageCard = (image) => {
    const filename = image.storedFileName;
    const imageURL = imageUrlUtils.getResizedImageUrl(
      filename,
      "Operator",
      200,
      200
    );
    let imageStyle;
    if (this.isMobile) {
      imageStyle = "operator-edit-tour-card-image-style";
    } else {
      imageStyle = "operator-edit-tour-card-image-style-mobile";
    }

    return (
      <div
        className="card app-card-style shadow p-3 mb-5 bg-white rounded"
        key={filename}
      >
        <img
          className="card-img-top imageStyle clickable"
          src={imageURL}
          alt="Tour"
          id={image.storedFileName}
          onClick={this.handleImageView}
        />
        <div className="card-body">
          <p>{image.imageFileName}</p>
          <div className="operator-edit-tour-image-buttons-container">
            {!image.deleteIntention && (
              <label
                className="clickable"
                style={{ color: "var(--color-kesudo)" }}
                onClick={this.handleDeleteImageIntention}
                id={image.storedFileName}
              >
                <i className="bi bi-trash" id={image.storedFileName}></i>
              </label>
            )}

            {!image.deleteIntention && (
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id={image.storedFileName}
                  checked={image.profilePhoto}
                  onChange={() =>
                    this.handleProfileImageSelected(image, this.props.tour)
                  }
                />
                <label
                  className="form-check-label"
                  htmlFor={image.storedFileName}
                >
                  Cover
                </label>
              </div>
            )}

            {image.deleteIntention && (
              <label
                className="clickable"
                style={{ color: "var(--color-greyoda)" }}
                onClick={this.handleDeleteImageCancel}
                id={image.storedFileName}
              >
                Cancel
              </label>
            )}
            {image.deleteIntention && (
              <label
                className="clickable"
                style={{ color: "var(--color-kesudo)" }}
                onClick={this.handleDeleteImage}
                id={image.storedFileName}
              >
                Confirm{" "}
                <i className="bi bi-trash" id={image.storedFileName}></i>
              </label>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderAllImageCards = () => {
    const { images } = this.state;
    if (images.length === 0)
      return (
        <label>
          Please add tour photos <i className="bi bi-card-image"></i>
        </label>
      );
    return images.map((i) => this.renderOneImageCard(i));
  };

  render() {
    const { images } = this.state;
    let cardContainerStyle;
    if (this.isMobile) {
      cardContainerStyle = "app-card-group-style2-mobile";
    } else {
      cardContainerStyle = "app-card-group-style2";
    }

    let containerClass;
    if (this.isMobile) {
      // containerClass = "container ovroom-form-container-mobile";
      containerClass = "container card-body ";
    } else {
      // containerClass = "container ovroom-form-container";
      containerClass = "container card-body ";
    }

    return (
      <React.Fragment>
        {images && (
          <div className="card shadow p-3 mb-5 bg-white rounded">
            <div className={containerClass}>
              <div className="view-title-container">
                <label className="view-title-text">Photos</label>
                <div className="edit-button-with-warning-container">
                  {/* <div className="view-title-button"> */}
                  <button
                    className="btn btn-primary"
                    disabled={images.length === 10}
                    data-bs-toggle="modal"
                    data-bs-target="#uploadOperatorImage"
                    onClick={this.handleUploadImageClicked}
                  >
                    <i className="bi bi-plus-circle" /> Photo
                  </button>
                  {/* </div> */}
                  <label className="view-item-name-text">
                    (Requires review)
                  </label>
                </div>
              </div>
              <div className={cardContainerStyle}>
                {this.renderAllImageCards()}
              </div>
            </div>
          </div>
        )}

        {/* Modal - Upload operator tour image */}
        <div
          className="modal fade"
          id="uploadOperatorImage"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add photo to the tour
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <UploadTourImages
                  tour={this.props.tour}
                  onSuccessfulUpload={this.handleImageUploadSuccess}
                  clearState={this.state.uploadedImageClearState}
                />
              </div>

              {this.state.fileUploadSuccess && (
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={this.handleFileUploadClose}
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

OperatorEditTourImage.propTypes = {
  tour: PropTypes.object.isRequired,
  onTourProfileImageChanged: PropTypes.func.isRequired,
  onTourProfileImageDeleted: PropTypes.func.isRequired,
};

export default OperatorEditTourImage;
