import React, { Component } from "react";

import OperatorReservations from "./operatorReservations";
import OperatorTours from "./operatorTours";
import TopbarShadeLine from "../common/topbarShadeLine";

import * as authService from "../../services/authService";
import * as userService from "../../services/userService";
import NavBar from "../navbar";

import "../../css/app.css";

import OperatorContext from "../../context/operatorContext";

class Operator extends Component {
  // Context used
  static contextType = OperatorContext;

  isMobile = window.innerWidth < 480;

  // Active component designators
  RESERVATIONS = "reservations";
  TOURS = "tours";
  PERSONNEL = "personnel";

  handleAddTour = () => {
    this.props.history.push("/operator/create-tour-start");
  };

  handleOperatorNavigationLinkSelected = ({ currentTarget: input }) => {
    // Remember the new 'selection' of the Operator | Manage screen.
    // This can be one of "reservations", "tours" or "personnel".
    this.context.onOperatorManageSelectionChanged(input.id);
  };

  componentDidMount() {
    // Get current user from localStorage
    //const currentUser = JSON.parse(localStorage.getItem("CurrentUser"));
  }

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      this.props.history.replace("/login");
      return null;
    }

    // Get user info from the local storage. If it is not found, send the user to the log-in screen.
    const userInfo = userService.getUserFromLocalStorage();
    if (!userInfo) {
      this.props.history.replace("/login");
      return null;
    }

    const activeComponent = this.context.operatorManage.selection;

    const tourBreadCrumbStyle =
      activeComponent === this.TOURS
        ? "breadcrumb-item   active"
        : "breadcrumb-item";
    const tourLinkStyle =
      activeComponent === this.TOURS
        ? "operator-navigation-active-link-style clickable"
        : "operator-navigation-inactive-link-style clickable";

    const reservationsBreadCrumbStyle =
      activeComponent === this.RESERVATIONS
        ? "breadcrumb-item active"
        : "breadcrumb-item";
    const reservationsLinkStyle =
      activeComponent === this.RESERVATIONS
        ? "operator-navigation-active-link-style clickable"
        : "operator-navigation-inactive-link-style clickable";

    const manageBreadCrumbStyle =
      activeComponent === this.PERSONNEL
        ? "breadcrumb-item   active"
        : "breadcrumb-item";
    const personnelLinkStyle =
      activeComponent === this.PERSONNEL
        ? "operator-navigation-active-link-style clickable"
        : "operator-navigation-inactive-link-style clickable";

    return (
      <OperatorContext.Consumer>
        {(operatorContext) => (
          <React.Fragment>
            <div className="container-fluid">
              <div className="container">
                <NavBar loggedIn={true} userInfo={userInfo} />
              </div>
            </div>

            <TopbarShadeLine />

            <div className="container app-style1-container">
              <nav
                className="operator-navigation-styles"
                aria-label="breadcrumb"
              >
                <ol className="breadcrumb">
                  <li className={reservationsBreadCrumbStyle}>
                    <label
                      id={this.RESERVATIONS}
                      className={reservationsLinkStyle}
                      onClick={this.handleOperatorNavigationLinkSelected}
                    >
                      Reservations
                    </label>
                  </li>
                  <li className={tourBreadCrumbStyle} aria-current="page">
                    <label
                      id={this.TOURS}
                      className={tourLinkStyle}
                      onClick={this.handleOperatorNavigationLinkSelected}
                    >
                      Tours
                    </label>
                  </li>

                  <li className={manageBreadCrumbStyle}>
                    <label
                      id={this.PERSONNEL}
                      className={personnelLinkStyle}
                      onClick={this.handleOperatorNavigationLinkSelected}
                    >
                      Personnel
                    </label>
                  </li>
                </ol>
                <div className="operator-navigation-horizontal-line-container">
                  <hr className="horizontal-line-primary-color" />
                </div>
              </nav>

              <div className="app-style1-main-container-placement">
                {activeComponent === this.RESERVATIONS && (
                  <OperatorReservations />
                )}
                {activeComponent === this.TOURS && (
                  <OperatorTours onAddTour={this.handleAddTour} />
                )}
                {/* {activeComponent === this.PERSONNEL && <OperatorManage />} */}
                {activeComponent === this.PERSONNEL && <OperatorReservations />}
              </div>

              {/* <div className="app-style1-footer-container-placement">
            Footer goes here...
          </div> */}
            </div>
          </React.Fragment>
        )}
      </OperatorContext.Consumer>
    );
  }
}

export default Operator;
