import React from "react";
import Joi from "joi";
import _ from "lodash";
import { ToastContainer } from "react-toastify";

import * as transportationService from "../../../../services/transportationService";
import * as authService from "../../../../services/authService";
import Form from "../../../common/form";
import "../../../../css/ovroomFormPage.css";
import "../../../../css/checkbox.css";

import { assetsURL, logoImageName } from "../../../../config.json";

import UserContext from "../../../../context/userContext";

class CreateTourTransportation extends Form {
  isMobile = window.innerWidth < 480;

  dangerLabel = "None"; // Text of the selection that selects no item (and if others are selected, removes them from selection)

  // Context used
  static contextType = UserContext;

  state = {
    data: { transportation: [] },
    errors: {},
    transportations: [],
    loading: true,
  };

  schema = Joi.object({ transportation: Joi.array().label("Transportation") });

  handleButtonGroupItemClicked = ({ currentTarget: input }) => {
    let selections = [...this.state.data.transportation];
    if (input.checked) {
      // Item is checked (selected)
      if (!selections.find((s) => _.isEqual(s, input.name)))
        selections.push(input.name);
    } else {
      // Item is unchecked (unselected)
      selections = selections.filter((s) => !_.isEqual(s, input.name));
    }
    this.setState({ data: { transportation: selections } });
  };

  doSubmit = async () => {
    localStorage.setItem(
      "TourTransportation",
      JSON.stringify(this.state.data.transportation)
    );
    this.props.history.push("/operator/create-tour-accommodation");
  };

  async componentDidMount() {
    let response;
    try {
      response = await transportationService.getTransportations();
    } catch (e) {
    } finally {
      this.setState({ loading: false });
    }
    if (response && response.status === 200) {
      this.setState({ transportations: response.data });
    }
  }

  // Render a button group.
  // Input
  //    array - This is an array of objects with following structure of each element:
  //        {
  //            name (string) - Name of the button to be shown in the button group
  //            _id (string) - Unique ID of the element
  //        }
  //    noSelectionLabel - (string) - If a particular label needs to be shown with danger color
  renderButtonGroup(inputArray, noSelectionLabel) {
    const buttonArray = inputArray.map((i) => {
      return (
        <div key={i._id}>
          <input
            name={i.name}
            type="checkbox"
            className="btn-check ovroom-buttongroup-button"
            id={i._id}
            autoComplete="off"
            onClick={this.handleButtonGroupItemClicked}
          />
          {!_.isEqual(i.name, noSelectionLabel) && (
            <label
              className="btn btn-outline-secondary ovroom-buttongroup-button"
              htmlFor={i._id}
            >
              {i.name}
            </label>
          )}

          {/* {_.isEqual(i.name, noSelectionLabel) && (
            <label className="btn btn-outline-danger" htmlFor={i._id}>
              {i.name}
            </label>
          )} */}
        </div>
      );
    });
    return (
      <div
        className="btn-group ovroom-buttongroup-container"
        role="group"
        aria-label="Basic checkbox toggle button group"
      >
        {buttonArray}
      </div>
    );
  }

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      this.props.history.replace("/login");
      return null;
    }

    let containerClass,
      headerContainer,
      instructionContainer,
      formContainer,
      logoImage,
      pageHeadingTextStyle;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";
      headerContainer = "ovroom-form-header-container-mobile";
      instructionContainer = "ovroom-form-instruction-container-option1";
      formContainer = "ovroom-form-elements-container-option1";
      logoImage = "logo-image-mobile";
      pageHeadingTextStyle = "ovroom-form-page-heading-mobile";
    } else {
      // desktop and tablet

      containerClass = "container ovroom-form-container";
      headerContainer = "ovroom-form-header-container";
      instructionContainer = "ovroom-form-instruction-container-option1";
      formContainer = "ovroom-form-elements-container-option1";
      logoImage = "logo-image";
      pageHeadingTextStyle = "ovroom-form-page-heading";
    }

    return (
      <UserContext.Consumer>
        {(userContext) => (
          <div className={containerClass}>
            <div className={headerContainer}>
              <img
                className={logoImage}
                src={assetsURL + logoImageName}
                alt="Ovroom logo"
              />
              <p className={pageHeadingTextStyle}>Tour Transportations</p>
            </div>
            <div className={instructionContainer}>
              Select all forms of transportations used for this tour.
            </div>
            <div className={formContainer}>
              <form>
                {this.renderButtonGroup(
                  this.state.transportations,
                  this.dangerLabel
                )}
                {this.renderSubmitButton("Save and next")}
              </form>
              <div className="vertical-filler-container" />
            </div>
            <ToastContainer />
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default CreateTourTransportation;
