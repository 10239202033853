import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import "../../../../css/edit.css";
import "../../../../css/operator.css";
import "../../../../css/ovroomFormPage.css";

class OperatorViewResBasicInfo extends Component {
  render() {
    const { reservation } = this.props;

    // Options that determine how dates are shown in UI
    const dateOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZoneName: "short",
    };

    const startDateObject = new Date(reservation.duration.startTimestamp);
    const startDateString = startDateObject.toLocaleDateString(
      "en-US",
      dateOptions
    );

    const endDateObject = new Date(reservation.duration.endTimestamp);
    const endDateString = endDateObject.toLocaleDateString(
      "en-US",
      dateOptions
    );

    const createdAtDateObject = new Date(reservation.createdAt);
    const createdAtDateString = createdAtDateObject.toLocaleDateString(
      "en-US",
      dateOptions
    );

    const lastUpdatedDateObject = new Date(reservation.lastUpdatedAt);
    const lastUpdatedDateString = lastUpdatedDateObject.toLocaleDateString(
      "en-US",
      dateOptions
    );

    if (reservation)
      return (
        <React.Fragment>
          {reservation && (
            <div className="card shadow p-3 mb-5 bg-white rounded">
              <div className="container card-body">
                <div className="view-title-container">
                  <label className="card-title view-title-text">
                    Reservation info
                  </label>
                  {/* <div className="edit-button-with-warning-container">
                    <Link
                      className="btn btn-primary view-title-button-text"
                      to={{
                        pathname: "/operator/tours/tour-basic-info",
                        state: { reservation },
                      }}
                    >
                      <i className="bi bi-pencil-square"></i> Update
                    </Link>
                  </div> */}
                </div>

                {/* "_id price calculatedAmounts duration cancellationPolicy paymentStatus refundStatus refundReason fulfillmentStatus debitStatus disputeStatus checkoutStarted createdAt lastUpdatedAt tour tourist confirmation numberOfAdults numberOfChildren createdBy" */}

                <div className="view-content-container">
                  <label className="view-item-name-text">Tour title</label>
                  <p className="view-item-content-text">
                    {reservation.tour.tourTitle}
                  </p>

                  <label className="view-item-name-text">Tourist name</label>
                  <p className="view-item-content-text">
                    {reservation.tourist.firstName}{" "}
                    {reservation.tourist.lastName}
                  </p>

                  <label className="view-item-name-text">
                    Tourist mobile phone
                  </label>
                  <p className="view-item-content-text">
                    {"+"}
                    {reservation.tourist.mobilePhoneCountryCode}{" "}
                    {reservation.tourist.mobilePhone}
                  </p>

                  <label className="view-item-name-text">Tourist email</label>
                  <p className="view-item-content-text">
                    {reservation.tourist.email}
                  </p>

                  <label className="view-item-name-text">
                    Number of adults
                  </label>
                  <p className="view-item-content-text">
                    {reservation.numberOfAdults}
                  </p>

                  <label className="view-item-name-text">
                    Number of children
                  </label>
                  <p className="view-item-content-text">
                    {reservation.numberOfChildren}
                  </p>

                  <label className="view-item-name-text">Starts</label>
                  <p className="view-item-content-text">{startDateString}</p>

                  <label className="view-item-name-text">Ends</label>
                  <p className="view-item-content-text">{endDateString}</p>

                  <label className="view-item-name-text">Reserved on</label>
                  <p className="view-item-content-text">
                    {createdAtDateString}
                  </p>

                  <label className="view-item-name-text">Last updated</label>
                  <p className="view-item-content-text">
                    {lastUpdatedDateString}
                  </p>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      );
  }
}

OperatorViewResBasicInfo.propTypes = {
  reservation: PropTypes.object.isRequired,
};

export default OperatorViewResBasicInfo;
