import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import "../../../../css/edit.css";
import "../../../../css/operator.css";
import "../../../../css/ovroomFormPage.css";

class OperatorViewTourCapacity extends Component {
  render() {
    const { tour } = this.props;
    const { capacity } = tour;

    return (
      <React.Fragment>
        {tour && (
          <div className="card shadow p-3 mb-5 bg-white rounded">
            <div className="container card-body">
              <div className="view-title-container">
                <label className="card-title view-title-text">
                  Tour capacity
                </label>
                <div className="edit-button-with-warning-container">
                  <Link
                    className="btn btn-primary view-title-button-text"
                    to={{
                      pathname: "/operator/tours/tour-capacity",
                      state: { tour },
                    }}
                  >
                    <i className="bi bi-pencil-square"></i> Edit
                  </Link>
                </div>
              </div>

              <div className="view-content-container">
                <label className="view-item-name-text">
                  Maximum number of adults per tour
                </label>
                <p className="view-item-content-text">
                  {capacity.maxAdultsPerTour}
                </p>
                <label className="view-item-name-text">
                  Maximum number of children per tour
                </label>
                <p className="view-item-content-text">
                  {capacity.maxChildrenPerTour}
                </p>
                <label className="view-item-name-text">
                  Maximum total per tour (adults + children)
                </label>
                <p className="view-item-content-text">
                  {capacity.maxPersonsPerTour}
                </p>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

OperatorViewTourCapacity.propTypes = {
  tour: PropTypes.object.isRequired,
};

export default OperatorViewTourCapacity;
