import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import OperatorTour from "./tour/tour";

import * as authService from "../../services/authService";
import * as userService from "../../services/userService";
import * as tourServices from "../../services/operatorTourService";
import * as imageUrlUtils from "../../utils/imageUrl";

import "../../css/operator.css";
import { placeHolderImageName } from "../../config.json";

import OperatorContext from "../../context/operatorContext";

class OperatorTours extends Component {
  // Context used
  static contextType = OperatorContext;

  // Tour selection state
  ALLTOURS = "alltours"; // Show listing of all tours
  VIEWTOUR = "viewtour"; // View details of a tour

  isMobile = window.innerWidth < 480;
  totalTours = 0;
  returnedTours = 0;
  limit = 0;
  skip = 0;

  state = { tours: [], selectedTour: {}, loading: true, incompleteTour: false };

  // Handle changing of the profile image for a tour
  handleTourProfileImageChanged = (tour, image) => {
    // Find the tour
    const tourIndex = _.findIndex(this.state.tours, { _id: tour._id });
    if (tourIndex < 0) return;

    // Update tour's images to mark the correct image as profile image
    let { images } = this.state.tours[tourIndex];
    images.forEach((i) => {
      if (i.storedFileName === image.storedFileName) i.profilePhoto = true;
      else i.profilePhoto = false;
    });
  };

  // Handle deletion of the tour profile image
  handleTourProfileImageDeletedOrAdded = async (tour) => {
    // Find the tour index
    const tourIndex = _.findIndex(this.state.tours, { _id: tour._id });
    if (tourIndex < 0) return;

    let tempTours = [...this.state.tours];

    // Now download the tour whose photo is deleted from the server
    let response;
    try {
      response = await tourServices.getTour(tour._id);
    } catch (e) {
    } finally {
      this.setState({ loading: false });
    }
    if (response && response.status === 200) {
      tempTours.splice(tourIndex, 1, response.data);
      this.setState({ tours: tempTours });
    }
  };

  handleShowAllTours = () => {
    this.context.onOperatorManageToursSubSelectionChanged(this.ALLTOURS, null);
  };

  handleTourCardClick = (tour) => {
    this.context.onOperatorManageToursSubSelectionChanged(
      this.VIEWTOUR,
      tour._id
    );
  };

  handleTourEdit = () => {
    // Possibly download the edited tour, and update the 'tours' array in this.state with the newly edited tour
  };

  async componentDidMount() {
    let response;
    try {
      response = await tourServices.getTours();
    } catch (e) {
    } finally {
      this.setState({ loading: false });
    }
    if (response && response.status === 200) {
      this.setState({ tours: response.data.tours });
      this.totalTours = response.data.total;
      this.returnedTours = response.data.returned;
      this.limit = response.data.limit;
      this.skip = response.data.skip;
    }
  }

  cardForTour = (tour) => {
    const imageURL = this.getTourProfileImageUrl(tour, 300, 300);
    const title = tour.tourTitle;
    // const description = tour.description.slice(0, 120) + "...";
    const lastUpdatedDate = new Date(tour.lastUpdatedAt);
    const tourYear = lastUpdatedDate.getFullYear();
    const tourMonth = (lastUpdatedDate.getMonth() + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }); // Months are zero based
    const tourDate = lastUpdatedDate.getDate().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const tourHours = lastUpdatedDate.getHours().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const tourMinutes = lastUpdatedDate.getMinutes().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });

    return (
      <div
        className="card app-card-style shadow p-3 mb-5 bg-white rounded clickable"
        key={tour._id}
        onClick={() => this.handleTourCardClick(tour)}
      >
        <img
          className="card-img-top operatorTour-tour-card-image-style"
          src={imageURL}
          alt="Card cap"
        />
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item" id="status">
            <label>Status: {this.renderTourStatus(tour)}</label>
          </li>
        </ul>
        <div className="card-footer">
          <small className="text-muted">
            Updated at {tourHours}:{tourMinutes} on {tourMonth}-{tourDate}-
            {tourYear}
          </small>
        </div>
      </div>
    );
  };

  renderTours = () => {
    const { tours } = { ...this.state };
    let containerStyle = this.isMobile
      ? "app-card-group-mobile"
      : "app-card-group";
    return (
      <div className={containerStyle}>
        {tours.map((t) => this.cardForTour(t))}
      </div>
    );
  };

  getTourProfileImageUrl = (tour, width, height) => {
    let image;
    if (tour.images.length > 0) {
      image = tour.images.find((i) => i.profilePhoto);
      if (!image) image = tour.images[0];
    }
    return image
      ? imageUrlUtils.getResizedImageUrl(
          image.storedFileName,
          "Operator",
          width,
          height
        )
      : imageUrlUtils.getResizedImageUrl(
          placeHolderImageName,
          "Client",
          width,
          height
        );
  };

  renderTourStatus = (tour) => {
    let text, color;
    if (tour.incomplete) {
      text = "Incomplete";
      color = "var(--color-kesudo)";
    } else if (!tour.approvedForPublication) {
      // Pending
      text = "Pending";
      color = "var(--color-kesudo)";
    } else if (!tour.active) {
      text = "Paused";
      color = "var(--color-kesudo)";
    } else if (tour.active) {
      text = "Selling";
      color = "var(--color-neelo)";
    }
    return <label style={{ color }}>{text}</label>;
  };

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      this.props.history.replace("/login");
      return null;
    }

    // Get user info from the local storage. If it is not found, send the user to the log-in screen.
    const userInfo = userService.getUserFromLocalStorage();
    if (!userInfo) {
      this.props.history.replace("/login");
      return null;
    }

    const selectionState =
      this.context.operationsManageTours.tourSelectlionState;

    const addButtonTextStyle = "btn btn-primary operatorTour-add-button my-2";

    return (
      <OperatorContext.Consumer>
        {(operatorContext) => (
          <React.Fragment>
            {selectionState === this.ALLTOURS && (
              <div className="app-main-area-flex-layout">
                <div className="app-main-area-top-bar1">
                  <button
                    className={addButtonTextStyle}
                    onClick={this.props.onAddTour}
                  >
                    <i className="bi bi-plus-circle "></i> Tour
                  </button>
                </div>
                {this.renderTours()}
                {this.state.loading && (
                  <div className="view-spinner-container">
                    <span
                      className="spinner-border spinner-border-sm m-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </div>
                )}
              </div>
            )}

            {selectionState === this.VIEWTOUR &&
              this.context.operationsManageTours.id && (
                <OperatorTour
                  tourId={this.context.operationsManageTours.id}
                  incompleteTour={this.state.incompleteTour}
                  onShowAllTours={this.handleShowAllTours}
                  onTourProfileImageChanged={this.handleTourProfileImageChanged}
                  onTourProfileImageDeleted={
                    this.handleTourProfileImageDeletedOrAdded
                  }
                  onTourProfileImageAdded={
                    this.handleTourProfileImageDeletedOrAdded
                  }
                />
              )}
          </React.Fragment>
        )}
      </OperatorContext.Consumer>
    );
  }
}

OperatorTours.propTypes = {
  onAddTour: PropTypes.func.isRequired,
};

export default OperatorTours;
