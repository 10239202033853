import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import "../../../../css/edit.css";
import "../../../../css/operator.css";
import "../../../../css/ovroomFormPage.css";
import "../../../../css/createTourDetails.css";

class OperatorViewTourHighlights extends Component {
  render() {
    const { tour } = this.props;
    const { highlights } = this.props.tour;

    let i = 0; // Key for each description

    return (
      <React.Fragment>
        {tour && (
          <div className="card shadow p-3 mb-5 bg-white rounded">
            <div className="container card-body">
              <div className="view-title-container">
                <label className="card-title view-title-text">
                  Tour highlights
                </label>
                <div className="edit-button-with-warning-container">
                  <Link
                    className="btn btn-primary view-title-button-text"
                    to={{
                      pathname: "/operator/tours/tour-highlights",
                      state: { tour },
                    }}
                  >
                    <i className="bi bi-pencil-square"></i> Edit
                  </Link>
                  <label className="view-item-name-text">
                    (Requires review)
                  </label>
                </div>
              </div>

              <div className="view-content-container">
                {highlights &&
                  highlights.map((h) => {
                    return (
                      <div key={i++}>
                        <div>
                          <p>
                            <i className="bi bi-check2-circle view-highlights-checkmark-text" />{" "}
                            {h.description}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

OperatorViewTourHighlights.propTypes = {
  tour: PropTypes.object.isRequired,
};

export default OperatorViewTourHighlights;
