import http from "./httpService";
import { apiURL } from "../config.json";

const apiEndpoint = apiURL + "/transportations";

// getTransportations
// Get list of transportations
//
// Input:
//  done automatically - token (String) - The JWT token of the user received upon log-in.
//
export function getTransportations() {
  // The following HTTP request returns a promise
  return http.get(apiEndpoint);
}
