import React from "react";

import { assetsURL, logoImageName } from "../config.json";

import "../css/ovroomFormPage.css";

const BecomeTourOperator = ({ history }) => {
  const isMobile = window.innerWidth < 480;

  const doSubmit = () => {
    history.push("/operator/register");
  };

  let containerClass, pageHeaderContainerClass, headingTextClass, logoImage;
  if (isMobile) {
    containerClass = "container ovroom-form-container-mobile";

    pageHeaderContainerClass = "ovroom-form-header-container-mobile";
    headingTextClass = "ovroom-form-page-heading-mobile";
    logoImage = "logo-image-mobile";
  } else {
    containerClass = "container ovroom-form-container";

    pageHeaderContainerClass = "ovroom-form-header-container";
    headingTextClass = "ovroom-form-page-heading";
    logoImage = "logo-image";
  }

  return (
    <div className={containerClass}>
      <div className={pageHeaderContainerClass}>
        <img
          className={logoImage}
          src={assetsURL + logoImageName}
          alt="Ovroom logo"
        />
        <p className={headingTextClass}>Become Tour Operator</p>
      </div>

      <div className="ovroom-form-instruction-container-option1">
        <p>
          Welcome to a world of possibilities! Ovroom makes an equal playing
          field for all tour operators to compete equally based on their passion
          and service to the tours they conduct. Follw few simple setps to
          establish your business and tours with Ovroom:
        </p>

        <ul>
          <li>Register your business (company or personal) with Ovroom.</li>
          <li>
            If your business is in one of the supported countries (we will
            automatically determine after the previous step), register it with
            our payment processing company.
          </li>
          <li>
            Ovroom will verify your company information and notify you if we
            need any further information.
          </li>
          <li>Upload details of your tours.</li>
          <li>Ovroom will verify your tours.</li>
          <li>You're in business!</li>
        </ul>

        <button
          className="btn btn-primary ovroom-form-submit-button"
          onClick={doSubmit}
        >
          Great! Let's start!
        </button>
      </div>
    </div>
  );
};

export default BecomeTourOperator;
