import React, { Component } from "react";

class TopbarShadeLine extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <hr className="horizontal-line-greyoli-color" />
        <hr className="horizontal-line-greyoli-color" />
        <hr className="horizontal-line-greyolili-color" />
        <hr className="horizontal-line-greyolili-color" />
        <hr className="horizontal-line-greyolili-color" />
        <hr className="horizontal-line-greyolili-color" />
        <hr className="horizontal-line-greyolili-color" />
        <hr className="horizontal-line-greyolili-color" />
      </React.Fragment>
    );
  }
}

export default TopbarShadeLine;
