import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import * as dateUtils from "../../../../utils/date";

import "../../../../css/edit.css";
import "../../../../css/operator.css";
import "../../../../css/ovroomFormPage.css";

class OperatorViewTourLastStartDate extends Component {
  render() {
    const { tour } = this.props;
    const lastStartDate = dateUtils.getStringFromDate(tour.lastStartDate);
    const displayLastStartDate = lastStartDate.includes("275760")
      ? `You have not set the last start date for the tour. This is perfectly fine! Most of the tours don't need to set the last start date, unless you are planning to retire a tour after a specific start date.`
      : lastStartDate;

    return (
      <React.Fragment>
        {tour && (
          <div className="card shadow p-3 mb-5 bg-white rounded">
            <div className="container card-body">
              <div className="view-title-container">
                <label className="card-title view-title-text">
                  Tour last start date
                </label>
                <div className="edit-button-with-warning-container">
                  <Link
                    className="btn btn-primary view-title-button-text"
                    to={{
                      pathname: "/operator/tours/tour-last-start-date",
                      state: { tour },
                    }}
                  >
                    <i className="bi bi-pencil-square"></i> Edit
                  </Link>
                </div>
              </div>

              <div className="view-content-container">
                <p className="view-item-content-text">{displayLastStartDate}</p>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

OperatorViewTourLastStartDate.propTypes = {
  tour: PropTypes.object.isRequired,
};

export default OperatorViewTourLastStartDate;
