import http from "./httpService";
import { apiURL } from "../config.json";

const apiEndpoint = apiURL + "/countries";

// async function
// getTouristCountries
// Get list of coutnries available to the tourists.
//
// Input: none
//
// Returns: A promise that needs to be awaited.
//  On Success:
//      JSON with array of coutnry objects:
//
export function getTouristCountries() {
  // The following HTTP request returns a promise
  const url = apiEndpoint + "/forTourists";
  return http.get(url);
}

// async function
// getOperatorCountries
// Get list of coutnries available to the tour operators.
//
// Input: none
//
// Returns: A promise that needs to be awaited.
//  On Success:
//      JSON with array of coutnry objects:
//
export function getOperatorCountries() {
  // The following HTTP request returns a promise
  const url = apiEndpoint + "/forOperator";
  return http.get(url);
}

// async function
// getCountryTimezones
// Get list of timezones for a country.
//
// Input: country ISO code
//
// Returns: A promise that needs to be awaited.
//  On Success:
//      JSON with array of timezones for a country.
//
export function getCountryTimezones(countryISOCode) {
  // The following HTTP request returns a promise
  const url = apiEndpoint + `/timezones/${countryISOCode}`;
  return http.get(url);
}

// async function
// getAllTimezones
// Get list of all timezones in the world.
//
// Returns: A promise that needs to be awaited.
//  On Success:
//      JSON with array of timezones for the whole world.
//
export function getAllTimezones() {
  // The following HTTP request returns a promise
  const url = apiEndpoint + `/timezones`;
  return http.get(url);
}
