import React from "react";
import Joi from "joi";
import { ToastContainer } from "react-toastify";

import * as authService from "../../../../services/authService";
import Form from "../../../common/form";
import "../../../../css/ovroomFormPage.css";
import "../../../../css/checkbox.css";

import { assetsURL, logoImageName } from "../../../../config.json";

import UserContext from "../../../../context/userContext";

class CreateTourCancellationPolicy extends Form {
  isMobile = window.innerWidth < 480;

  // Context used
  static contextType = UserContext;

  state = {
    data: {
      cancellationPolicyFullRefundDays: "",
      cancellationPolicyPartialRefundDays: "",
      cancellationPolicyPartialRefundPercentage: "",
      cancellationPolicyNoRefundDays: "",
    },
    errors: {},
    loading: false,
  };

  schema = Joi.object({
    cancellationPolicyFullRefundDays: Joi.number()
      .min(0)
      .required()
      .label("Full refund days"),
    cancellationPolicyPartialRefundDays: Joi.number()
      .min(0)
      .required()
      .label("Partial refund days"),
    cancellationPolicyPartialRefundPercentage: Joi.number()
      .min(0)
      .required()
      .label("Partial refund percentage"),
    cancellationPolicyNoRefundDays: Joi.number()
      .min(0)
      .required()
      .label("No refund days"),
  });

  doSubmit = async () => {
    const { data } = this.state;

    const cancellationPolicy = {
      fullRefundDays: data.cancellationPolicyFullRefundDays,
      partialRefundDays: data.cancellationPolicyPartialRefundDays,
      partialRefundPercentage: data.cancellationPolicyPartialRefundPercentage,
      noRefundDays: data.cancellationPolicyNoRefundDays,
    };

    localStorage.setItem(
      "TourCancelPolicy",
      JSON.stringify(cancellationPolicy)
    );
    this.props.history.push("/operator/create-tour-price");
  };

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      //localStorage.setItem("UrlBeforeLogin", this.props.location.pathname);
      this.props.history.replace("/login");
      return null;
    }

    let containerClass,
      headerContainer,
      formContainer,
      logoImage,
      pageHeadingTextStyle;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";
      headerContainer = "ovroom-form-header-container-mobile";
      formContainer = "ovroom-form-elements-container-option2";
      logoImage = "logo-image-mobile";
      pageHeadingTextStyle = "ovroom-form-page-heading-mobile";
    } else {
      // desktop and tablet

      containerClass = "container ovroom-form-container";
      headerContainer = "ovroom-form-header-container";
      formContainer = "ovroom-form-elements-container-option2";
      logoImage = "logo-image";
      pageHeadingTextStyle = "ovroom-form-page-heading";
    }

    return (
      <UserContext.Consumer>
        {(userContext) => (
          <div className={containerClass}>
            <div className={headerContainer}>
              <img
                className={logoImage}
                src={assetsURL + logoImageName}
                alt="Ovroom logo"
              />
              <p className={pageHeadingTextStyle}>Tour Cancellation Policy</p>
            </div>
            <div className={formContainer}>
              <form>
                {this.renderInput(
                  "cancellationPolicyFullRefundDays",
                  "Full refund days",
                  true
                )}
                {this.renderInput(
                  "cancellationPolicyPartialRefundDays",
                  "Partial refund days",
                  false
                )}
                {this.renderInput(
                  "cancellationPolicyPartialRefundPercentage",
                  "Partial refund percentage",
                  false
                )}
                {this.renderInput(
                  "cancellationPolicyNoRefundDays",
                  "No refund days",
                  false
                )}

                {this.renderSubmitButton("Save and next")}
              </form>
              <div className="vertical-filler-container" />
            </div>
            <ToastContainer />
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default CreateTourCancellationPolicy;
