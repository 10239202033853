// Tour operator reservation service

import http from "./httpService";
import { apiURL } from "../config.json";

const apiEndpoint = apiURL + "/operator/reservations";

// getUpcomingReservations
// Get upcoming reservations for the tour operator.
// Input:
//  search (string) - is the search term to search for (confirmation number of tourist name)
//
export function getUpcomingReservations(search) {
  const searchStr = search ? "?search=" + search : "";
  const url = apiEndpoint + "/upcoming" + searchStr;
  return http.get(url);
}

// getPastReservations
// Get past reservations for the tour operator.
//
export function getPastReservations() {
  const url = apiEndpoint + "/past";
  return http.get(url);
}

// getCanceledReservations
// Get past reservations for the tour operator.
//
export function getCanceledReservations() {
  const url = apiEndpoint + "/canceled";
  return http.get(url);
}

// getReservation
// Get a reservations for the tour operator.
// Input:
//  reservationId (string) - is the object ID of the reservation to be retrieved
//
export function getReservation(reservationId) {
  return http.get(apiEndpoint + "/" + reservationId);
}
