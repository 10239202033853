import React from "react";

import "../../css/termsAndConditions.css";

const OperatorTermsAndConditions = () => {
  const isMobile = window.innerWidth < 480;

  let containerClass;
  // let  headerClass, contentClass;
  if (isMobile) {
    containerClass = "container tac-container-mobile";
    // headerClass = "header-container-mobile";
    // contentClass = "privacy-content-mobile";
  } else {
    containerClass = "container tac-container";
    // headerClass = "header-container";
    // contentClass = "privacy-content";
  }

  return (
    <div className={containerClass}>
      <h1>Tour Operator Terms And Conditions</h1>

      <p> Last updated: April 9, 2021</p>

      <p>
        Please read these Terms of Use (“Terms”, “Terms of Use”) carefully
        before using the https://www.ovroom.com website (the “Service”) operated
        by MightyMob, LLC (“us”, “we”, or “our”). Your access to and use of the
        Service is conditioned on your acceptance of and compliance with these
        Terms. These Terms apply to all visitors, users and others who access or
        use the Service. By accessing or using the Service you agree to be bound
        by these Terms. If you disagree with any part of the terms then you may
        not access the Service.
      </p>

      <h2>Intellectual Property</h2>

      <p>
        The Service and its original content, features and functionality are and
        will remain the exclusive property of MightyMob, LLC and its licensors.
      </p>

      <h2>Links To Other Web Sites</h2>

      <p>
        Our Service may contain links to third-party web sites or services that
        are not owned or controlled by MightyMob, LLC. MightyMob, LLC has no
        control over, and assumes no responsibility for, the content, privacy
        policies, or practices of any third party web sites or services. You
        further acknowledge and agree that MightyMob, LLC shall not be
        responsible or liable, directly or indirectly, for any damage or loss
        caused or alleged to be caused by or in connection with use of or
        reliance on any such content, goods or services available on or through
        any such web sites or services. We strongly advise you to read the terms
        and conditions and privacy policies of any third-party web sites or
        services that you visit.
      </p>

      <h2>Termination </h2>

      <p>
        We may terminate or suspend access to our Service immediately, without
        prior notice or liability, for any reason whatsoever, including without
        limitation if you breach the Terms. All provisions of the Terms which by
        their nature should survive termination shall survive termination,
        including, without limitation, ownership provisions, warranty
        disclaimers, indemnity and limitations of liability.
      </p>

      <h2> Disclaimer </h2>

      <p>
        Your use of the Service is at your sole risk. The Service is provided on
        an “AS IS” and “AS AVAILABLE” basis. The Service is provided without
        warranties of any kind, whether express or implied, including, but not
        limited to, implied warranties of merchantability, fitness for a
        particular purpose, non-infringement or course of performance.
      </p>

      <h2>Age Resitrictions</h2>

      <p>
        You must be at least 18 years of age or older to use the service. You
        must not register or interact with the service if you are younger.
      </p>

      <h2>Governing Law</h2>

      <p>
        These Terms shall be governed and construed in accordance with the laws
        of United States without regard to its conflict of law provisions. Our
        failure to enforce any right or provision of these Terms will not be
        considered a waiver of those rights. If any provision of these Terms is
        held to be invalid or unenforceable by a court, the remaining provisions
        of these Terms will remain in effect. These Terms constitute the entire
        agreement between us regarding our Service, and supersede and replace
        any prior agreements we might have between us regarding the Service.
      </p>

      <h2>Changes</h2>

      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material we will try to provide at
        least 30 days notice prior to any new terms taking effect. What
        constitutes a material change will be determined at our sole discretion.
        By continuing to access or use our Service after those revisions become
        effective, you agree to be bound by the revised terms. If you do not
        agree to the new terms, please stop using the Service.
      </p>

      <h2>Contact Us</h2>

      <p>
        If you have any questions about these Terms, please contact us at
        support@ovroom.com.
      </p>
    </div>
  );
};

export default OperatorTermsAndConditions;
