import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import "../../../../css/edit.css";
import "../../../../css/operator.css";
import "../../../../css/ovroomFormPage.css";

class OperatorViewTourDuration extends Component {
  render() {
    const { tour } = this.props;
    const { duration } = tour;

    return (
      <React.Fragment>
        {tour && (
          <div className="card shadow p-3 mb-5 bg-white rounded">
            <div className="container card-body">
              <div className="view-title-container">
                <label className="card-title view-title-text">
                  Tour length and times
                </label>
                <Link
                  className="btn btn-primary view-title-button-text"
                  to={{
                    pathname: "/operator/tours/tour-duration",
                    state: { tour },
                  }}
                >
                  <i className="bi bi-pencil-square"></i> Edit
                </Link>
              </div>

              {duration && (
                <div className="view-content-container">
                  <label className="view-item-name-text">Number of days</label>
                  <p className="view-item-content-text">{duration.days}</p>

                  <label className="view-item-name-text">
                    Number of nights
                  </label>
                  <p className="view-item-content-text">{duration.nights}</p>

                  <label className="view-item-name-text">Tour duration</label>
                  <p className="view-item-content-text">
                    {duration.length} {duration.lengthUnit}
                  </p>

                  {/* <label className="view-item-name-text">
                    Unit of tour duration
                  </label>
                  <p className="view-item-content-text">
                    {duration.lengthUnit}
                  </p> */}

                  <div className="horizontal-line-container">
                    <hr className="horizontal-line-primary-color" />
                  </div>

                  <label className="view-item-name-text">
                    Tour start time on first day (24-hour format)
                  </label>
                  <p className="view-item-content-text">
                    {duration.startTime.toLocaleString("en-US", {
                      hour: "numeric",
                      hour12: false,
                    })}
                  </p>

                  <label className="view-item-name-text">
                    Timezone of tour start time
                  </label>
                  <p className="view-item-content-text">
                    {duration.startTimezone}
                  </p>

                  <div className="horizontal-line-container">
                    <hr className="horizontal-line-primary-color" />
                  </div>

                  <label className="view-item-name-text">
                    Tour end time on last day (24-hour format)
                  </label>
                  <p className="view-item-content-text">
                    {duration.endTime.toLocaleString("en-US", {
                      hour: "numeric",
                      hour12: false,
                    })}
                  </p>

                  <label className="view-item-name-text">
                    Timezone of tour end time
                  </label>
                  <p className="view-item-content-text">
                    {duration.endTimezone}
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

OperatorViewTourDuration.propTypes = {
  tour: PropTypes.object.isRequired,
};

export default OperatorViewTourDuration;
