import {
  manipulatedAssetsUrl,
  operatorBucketName,
  clientBucketName,
} from "../config.json";

// getResizedImageUrl
// Returns an image URL of the Amazon CloudFront that retrives and image with given 'operation' performed on the
// image. In this case, the operation performed is to resize the image with given width and height.
//
// Input:
//  filename (String) - Name of the file that needs to be retrieved
//  bucket (String) - Which Ovroom bucket on Amazon S3 does the file need to retrieved from. Acceptable values are:
//                      "Operator" - Specifies Tour Operator Assets
//                      "Client" - Specifies the Ovroom Client Images (Current folder)
//
//  width (Number) - Width in pixels
//  height (Number) - Height in pixels
//
// Returns:
//  imageUrl (String) - The correctly encoded URL that can be used to download the image
//
export function getResizedImageUrl(filename, bucket, width, height) {
  const url = manipulatedAssetsUrl;
  let bucketName;
  if (bucket === "Operator") {
    bucketName = operatorBucketName;
  } else if (bucket === "Client") {
    bucketName = clientBucketName;
  }
  const request = {
    bucket: bucketName, // Name of bucket
    key: filename, // Name of the file
    edits: {
      resize: {
        width: width,
        height: height,
        fit: "cover",
      },
    },
  };
  const strRequest = JSON.stringify(request);
  const encRequest = btoa(strRequest);
  const imageUrl = `${url}${encRequest}`; // the image url
  return imageUrl;
}

// getImageUrl
// Returns an image URL of the Amazon CloudFront that retrives and image from the specified bucket.
// It does not modify the image.
//
// Input:
//  filename (String) - Name of the file that needs to be retrieved
//  bucket (String) - Which Ovroom bucket on Amazon S3 does the file need to retrieved from. Acceptable values are:
//                      "Operator" - Specifies Tour Operator Assets
//                      "Client" - Specifies the Ovroom Client Images (Current folder)
//
// Returns:
//  imageUrl (String) - The correctly encoded URL that can be used to download the image
//
export function getImageUrl(filename, bucket) {
  const url = manipulatedAssetsUrl;
  let bucketName;
  if (bucket === "Operator") {
    bucketName = operatorBucketName;
  } else if (bucket === "Client") {
    bucketName = clientBucketName;
  }
  const request = {
    bucket: bucketName, // Name of bucket
    key: filename, // Name of the file
  };
  const strRequest = JSON.stringify(request);
  const encRequest = btoa(strRequest);
  const imageUrl = `${url}${encRequest}`; // the image url
  return imageUrl;
}
