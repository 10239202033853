import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import * as authService from "../services/authService";
import NavBar from "./navbar";
import Section2 from "./section2";
import Section3 from "./section3";
import Section4 from "./section4";
import Section5 from "./section5";
import SearchTour from "./common/searchTour";
import deviceConfig from "./common/deviceConfig";

import "../css/home.css";

export let gIsMobilePHone;

const Home = (props) => {
  // Set global device configuration that can be used later
  deviceConfig.isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  deviceConfig.isBigScreen = useMediaQuery({
    query: "(min-device-width: 1824px)",
  });
  deviceConfig.isTabletOrMobile = useMediaQuery({
    query: "(max-width: 1224px)",
  });
  window.gIsMobilePHone = deviceConfig.isMobilePhone = useMediaQuery({
    query: "(max-device-width: 464px)",
  });
  deviceConfig.isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
  deviceConfig.isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  deviceConfig.isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  let sectionClassName = "hero-image";
  if (deviceConfig.isDesktopOrLaptop) {
    sectionClassName += " hero-image-desktop";
    if (deviceConfig.isBigScreen) sectionClassName += "-bigscreen";
  }
  const [userInfoFromJWT, setUserInfoFromJWT] = useState(null);
  const [userInfoFromServer, setUserInfoFromServer] = useState(null);

  // Call useEffect when the component mounts. Don't call it everytime it updates.
  useEffect(() => {
    // Need an async function to fetch User from server
    // async function fetchUserInfo(token) {
    //   try {
    //     const response = await userService.getUser(token);

    //     if (response.status && response.status === 200) {
    //       const {
    //         firstName,
    //         lastName,
    //         userId,
    //         unreadMessages,
    //         isOwner,
    //         countryISOCode,
    //         currentOrganization,
    //         emailVerified,
    //         languageLocaleCode,
    //         mobilePhoneVerified,
    //       } = response.data;

    //       setUserInfoFromServer({
    //         firstName,
    //         lastName,
    //         userId,
    //         unreadMessages,
    //         isOwner,
    //       });

    //       // Update the  App level UserContext with the information
    //       userContext.handleUserLoggedIn(
    //         userId,
    //         firstName,
    //         lastName,
    //         unreadMessages,
    //         isOwner,
    //         countryISOCode,
    //         currentOrganization,
    //         languageLocaleCode,
    //         emailVerified,
    //         mobilePhoneVerified
    //       );
    //     }
    //   } catch (e) {
    //     const { response } = e;
    //     // If invalid token, log out the user and send the user to the home page
    //     if (
    //       response &&
    //       response.status &&
    //       response.status === 400 &&
    //       response.data.includes("Invalid token")
    //     ) {
    //       authService.logout();
    //       window.location = "/"; // Reload the application
    //     }
    //   }
    // }

    // Check if a user is logged in. If so, then set state with that user info object.
    const userInfo1 = authService.getCurrentUserInfoFromJWT();
    if (userInfo1) setUserInfoFromJWT(userInfo1);

    // //const token = authService.getJwt();
    // const token = localStorage.getItem("token");

    // if (token) {
    //   fetchUserInfo(token);
    // }

    const userInfo2 = JSON.parse(localStorage.getItem("CurrentUser"));
    setUserInfoFromServer(userInfo2);
  }, []);

  const handleSignUpTourOperator = (event) => {
    props.history.push("/operator/register");
    return;
  };

  return (
    <React.Fragment>
      <section className={sectionClassName}>
        <div className="container-fluid">
          <div className="container">
            <NavBar
              loggedIn={userInfoFromJWT}
              userInfo={userInfoFromServer}
              themeColor="safed"
            />
          </div>
        </div>
        <div className="container search-container">
          <SearchTour />
        </div>
      </section>
      <Section2 />
      <Section3 />
      <Section4 onHandleSignUpTourOperator={handleSignUpTourOperator} />
      <Section5 />
    </React.Fragment>
  );
};

export default Home;
