import React, { Component } from "react";
import Joi from "joi";

import Input from "./input";
import TextArea from "./textArea";
import Select from "./select";
import Checkbox from "./checkbox";
import "../../css/form.css";

// Form component
// Renders a form.
//
// Input:
//      The props of this component should have:
//        data (Array of objects, required) - The array of objects, whose contents should be as follows:
//        {
//        name (String, required) - Name of the specific input field in the form. This should be unique in a given form.
//        label (String, required) - Label to be displayed ABOVE the text-field.
//        value (String, required) - Value of the text-field.
//        onChange (function, required) - The method to call in the calling-component when the value of the input field changes (user types).
//        autoFocus (Boolean, optional) - If set, the field is rendered with FOCUS.
//        error (String, optional) - If present, it is shown BELOW the input field as error.
//        }
//      loading -
//      The state of the child component should implement 'loading' property in the 'state'. If 'loading' is set to true while
//      doing asynchronous activities, such as submitting a form to server over the network, the form's Submit button will show
//      a spinner to indicate the network activity. It will aso disable the submit button to ensure the form does not get submitted
//      twice by two back-to-back quick clicks.
//
class Form extends Component {
  state = {
    data: {},
    errors: {},
    loading: false,
  };

  // validateProperty - Validate individual form items as they are being changed. It is called during handling changes
  // to the specific field.
  validateProperty = ({ name, value }) => {
    if (name !== "password_confirmation") {
      const obj = { [name]: value };
      const rule = this.schema.extract(name);
      const schema = Joi.object({ [name]: rule });
      const { error } = schema.validate(obj);
      return error ? error.details[0].message : null;
    } else {
      // Special case for "password_confirmation"
      const obj = { [name]: value, password: this.state.data.password };
      const rule1 = this.schema.extract(name);
      const rule2 = this.schema.extract("password");
      const schema = Joi.object({ [name]: rule1, password: rule2 });
      const { error } = schema.validate(obj);
      return error ? error.details[0].message : null;
    }
  };

  // validate - Validate the entire form's contents. It should be called when user submits the form.
  //
  //  Returns -
  //    When no errors are ound, it returns null.
  //    When errors are found, it returns errors <object data="//" type="" className=""></object>
  validate = () => {
    const { data } = this.state;
    const { error } = this.schema.validate(data, { abortEarly: false });
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  // handleSubmit - Submit the form. All the fields of the form are validated, and if all is well
  // the form is submitted to the server.
  handleSubmit = (e) => {
    e.preventDefault(); // Stop the form from being downloaded afresh everytime the form is submitted

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return; // Don't make a network call to the server

    this.doSubmit();
  };

  // handleCancel - Cancel the form content
  handleCancel = (e) => {
    e.preventDefault(); // Stop the form from being downloaded afresh everytime the form is submitted
    this.doCancel();
  };

  // handleChange - Everytime an input field of the form changes, call this method to do the following two things:
  // 1. Validate the current new value of the field, and display validation error, if any.
  // 2. Display the current new value of the field on the screen by setting the state.
  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };

    let errorMessage;

    //if (input.name !== "password_confirmation") {
    if (input.type === "checkbox")
      errorMessage = this.validateProperty({
        name: input.name,
        value: input.checked,
      });
    else errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name]; // If no errors, remove any existing errors for this field, if any.
    //}

    let data = { ...this.state.data };
    data[input.name] = input.type === "checkbox" ? input.checked : input.value;
    this.setState({ data, errors, changed: true });
  };

  // Render a check box in the menu
  // Input:
  //    name (String, required) -  The name to be associated with this field in the menu. Must be unique for the form.
  //    text (String, required) - Lable to be shown above the drop-down menu.
  //    autoFocus (Boolean, optional) - If this field should get the autofocus or not while rendering
  //    type (String, optional) - Type of the input field, e.g. "text" for plain text, or "password" to hide typed text etc.
  //        Default value of it is set to "text". See bootstrap document for details of 'input' class.
  //
  renderCheckbox(name, text, autoFocus = false) {
    const { data, errors } = this.state;
    return (
      <Checkbox
        name={name}
        text={text}
        value={data[name]}
        error={errors[name]}
        onChange={this.handleChange}
        autoFocus={autoFocus}
      />
    );
  }

  // Render a text field in the menu
  // Input:
  //    name (String, required) -  The name to be associated with this field in the menu. Must be unique for the form.
  //    label (String, required) - Lable to be shown above the input field.
  //    autoFocus (Boolean, optional) - If this field should get the autofocus or not while rendering
  //    type (String, optional) - Type of the input field, e.g. "text" for plain text, or "password" to hide typed text etc.
  //        Default value of it is set to "text". See bootstrap document for details of 'input' class.
  //
  renderInput(
    name,
    label,
    autoFocus = false,
    type = "text",
    shouldHandleChange = true
  ) {
    const { data, errors } = this.state;
    return (
      <Input
        name={name}
        label={label}
        value={data[name]}
        error={errors[name]}
        onChange={this.handleChange}
        autoFocus={autoFocus}
        type={type}
      />
    );
  }

  // Render a text area in the menu
  // Input:
  //    name (String, required) -  The name to be associated with this field in the menu. Must be unique for the form.
  //    label (String, required) - Lable to be shown abovethis field.
  //    autoFocus (Boolean, optional) - If this field should get the autofocus or not while rendering
  //    rows (Number, optional) - Number of rows to be shown in the text area. Default is 3.
  //
  renderTextArea(
    name,
    label,
    autoFocus = false,
    rows = 3,
    shouldHandleChange = true
  ) {
    const { data, errors } = this.state;
    return (
      <TextArea
        name={name}
        label={label}
        value={data[name]}
        error={errors[name]}
        onChange={this.handleChange}
        autoFocus={autoFocus}
        rows={rows}
      />
    );
  }

  // Render a drop-down menu (selection menu)
  // Input:
  //    name (String, required) -  The name to be associated with this field in the menu. Must be unique for the form.
  //    label (String, required) - Lable to be shown above the drop-down menu.
  //    options (Array of objects, required) - Array of items to be displayed in drop-down menu. Each object in this
  //        array looks as under:
  //              {
  //                  _id: <(String, required) A unique string that identifies the option>
  //                  name: <(String, required) The value of the option to be shown in the drop-down menu>
  //              }
  //
  renderSelect(name, label, options) {
    const { data, errors } = this.state;
    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  // renderSelectMultiple
  // Render a drop-down menu (selection menu) that allows multiple selections
  // Input:
  //    name (String, required) -  The name to be associated with this field in the menu. Must be unique for the form.
  //    label (String, required) - Lable to be shown above the drop-down menu.
  //    options (Array of objects, required) - Array of items to be displayed in drop-down menu. Each object in this
  //        array looks as under:
  //              {
  //                  _id: <(String, required) A unique string that identifies the option>
  //                  name: <(String, required) The value of the option to be shown in the drop-down menu>
  //              }
  //
  renderSelectMultiple(name, label, options) {
    const { data, errors } = this.state;
    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
        multiple
        data-style="btn-default"
      />
    );
  }

  // renderSubmitButton
  // Render the submit button of the form.
  // Input:
  //    label (String, required) - Lable to be shown in the submit button.
  //
  renderSubmitButton(label) {
    return (
      <button
        disabled={this.validate() || this.state.loading}
        onClick={this.handleSubmit}
        className="btn btn-primary submit-button"
      >
        {this.state.loading && (
          <span
            className="spinner-border spinner-border-sm m-1"
            role="status"
            aria-hidden="true"
          ></span>
        )}
        {label}
      </button>
    );
  }

  // renderEditFormSubmitButton
  // Render the submit button for the Edit form.
  // Input:
  //    label (String, required) - Lable to be shown in the submit button.
  //
  renderEditFormSubmitButton(label) {
    return (
      <React.Fragment>
      <button
        disabled={this.validate() || this.state.loading || !this.state.changed}
        onClick={this.handleSubmit}
        className="btn btn-primary submit-button"
      >
        {this.state.loading && (
          <span
            className="spinner-border spinner-border-sm m-1"
            role="status"
            aria-hidden="true"
          ></span>
        )}
        <i className="bi bi-save"></i> {label}
      </button>

      <button
        onClick={this.handleCancel}
        className="btn btn-outline-primary submit-button"
      >
        Cancel
        
      </button>


      </React.Fragment>
    );
  }

  // renderCloseButton
  // Render the close button for the Edit form. This button does nothing but
  // simply closes the form.
  // Input:
  //    label (String, required) - Lable to be shown in the close button.
  //
  renderCloseButton(label) {
    return (
      <button
        onClick={this.handleCancel}
        className="btn btn-primary submit-button"
      >
        {label}
      </button>
    );
  }
}

export default Form;
