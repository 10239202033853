import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";

import "../../../../css/edit.css";
import "../../../../css/operator.css";
import "../../../../css/ovroomFormPage.css";

class OperatorViewResFinancialInfo extends Component {
  render() {
    const { reservation } = this.props;

    if (reservation)
      return (
        <React.Fragment>
          {reservation && (
            <div className="card shadow p-3 mb-5 bg-white rounded">
              <div className="container card-body">
                <div className="view-title-container">
                  <label className="card-title view-title-text">
                    Financial info
                  </label>
                </div>

                {/* "_id price calculatedAmounts duration cancellationPolicy paymentStatus refundStatus refundReason fulfillmentStatus debitStatus disputeStatus checkoutStarted createdAt lastUpdatedAt tour tourist confirmation numberOfAdults numberOfChildren createdBy" */}
                <div className="view-content-container">
                  <label className="view-item-name-text">
                    Total before tax
                  </label>
                  <p className="view-item-content-text">
                    {reservation.calculatedAmounts.totalBeforeTax}
                  </p>

                  <label className="view-item-name-text">Sales tax</label>
                  <p className="view-item-content-text">
                    {reservation.calculatedAmounts.salesTax}
                  </p>

                  <label className="view-item-name-text">Other tax</label>
                  <p className="view-item-content-text">
                    {reservation.calculatedAmounts.otherTax}
                  </p>

                  <label className="view-item-name-text">Total</label>
                  <p className="view-item-content-text">
                    {reservation.calculatedAmounts.total}
                  </p>

                  <label className="view-item-name-text">
                    Ovroom commission
                  </label>
                  <p className="view-item-content-text">
                    {reservation.calculatedAmounts.totalOvroomCommission}
                  </p>

                  <label className="view-item-name-text">
                    Tour operator amount
                  </label>
                  <p className="view-item-content-text">
                    {reservation.calculatedAmounts.tourOperatorAmount}
                  </p>

                  <label className="view-item-name-text">Refund amount</label>
                  <p className="view-item-content-text">
                    {reservation.calculatedAmounts.refundAmount}
                  </p>

                  <div>
                    <label className="view-item-name-text">
                      Total change fee&nbsp;
                    </label>
                    <i
                      class="bi bi-info-circle view-item-name-text-accent"
                      data-tip="If tourist changes the reservation, the total fee charged to tourist."
                    />
                  </div>
                  <p className="view-item-content-text">
                    {reservation.calculatedAmounts.totalChangeFee}
                  </p>

                  <label className="view-item-name-text">
                    Ovroom change fee
                  </label>
                  <p className="view-item-content-text">
                    {reservation.calculatedAmounts.ovroomChangeFee}
                  </p>

                  <label className="view-item-name-text">
                    Tour operator change fee
                  </label>
                  <p className="view-item-content-text">
                    {reservation.calculatedAmounts.tourOperatorChangeFee}
                  </p>

                  <div>
                    <label className="view-item-name-text">
                      Tour operator debit amount&nbsp;
                    </label>
                    <i
                      class="bi bi-info-circle view-item-name-text-accent"
                      data-tip="If tour operator changes the reservation, the fee debited from tour operator account."
                    />
                  </div>
                  <p className="view-item-content-text">
                    {reservation.calculatedAmounts.tourOperatorDebitAmount}
                  </p>
                </div>
              </div>
              <ReactTooltip />
            </div>
          )}
        </React.Fragment>
      );
  }
}

OperatorViewResFinancialInfo.propTypes = {
  reservation: PropTypes.object.isRequired,
};

export default OperatorViewResFinancialInfo;
