import React from "react";
import PropTypes from "prop-types";

import "../../css/input.css";

// Form's Input component
// Renders a label with text field and error messages for a Form.
//
// Input:
//      The props of this component should have:
//        name (String, required) - Name of the specific input field in the form. This should be unique in a given form.
//        label (String, required) - Label to be displayed ABOVE the text-field.
//        value (String, required) - Value of the text-field.
//        type (String, required) - Type of the input field to show ("text", etc.)
//        onChange (function, required) - The method to call in the calling-component when the value of the input field changes (user types).
//        autoFocus (Boolean, optional) - If set, the field is rendered with FOCUS.
//        error (String, optional) - If present, it is shown BELOW the input field as error.
//

const Input = ({ name, label, value, type, onChange, error, ...rest }) => {
  return (
    <div className="form-group input-form-group">
      <label className="input-form-label" htmlFor={name}>
        {label}
      </label>
      <input
        {...rest}
        id={name}
        name={name}
        value={value}
        type={type}
        onChange={onChange}
        className="form-control input-form"
      />
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  // value: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default Input;
