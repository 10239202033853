// Tour operator tour service.

import http from "./httpService";
import { apiURL } from "../config.json";

const apiEndpoint = apiURL + "/operator/tours";

// createTour
// Create a new tour.
//
// Input:
//  Tour object
//
export function createTour(tour) {
  return http.post(apiEndpoint, tour);
}

// getTour
// Get a specific tour identified by the tourID.
//
export function getTour(id) {
  return http.get(apiEndpoint + "/" + id);
}

// getTours
// Get all tours by the operator.
//
export function getTours() {
  return http.get(apiEndpoint);
}

// setProfilePhoto
// Set the tour's profile photo.
//
export function setProfilePhoto(tourId, storedFileName) {
  const url = apiEndpoint + "/setProfilePhoto";

  const input = {
    tourId,
    storedFileName,
  };

  return http.put(url, input);
}

// updateTourBasicInfo
// Update Tour Basic Information.
//
export function updateTourBasicInfo(
  tourId,
  tourTitle,
  placeText,
  placeTagsString,
  tourType,
  startAddress,
  endAddress
) {
  const url = apiEndpoint + "/updateTourBasicInfo";

  const input = {
    tourId,
    tourTitle,
    placeText,
    placeTagsString,
    tourType,
    startAddress: { ...startAddress },
    endAddress: { ...endAddress },
  };
  return http.put(url, input);
}

// updatePrice
// Update tour's prices.
//
export function updatePrice(tourId, prices) {
  const url = apiEndpoint + "/updatePrice";
  const input = {
    tourId,
    prices,
  };
  return http.put(url, input);
}

// updateDetails
// Update tour's details.
//
export function updateDetails(tourId, details) {
  const url = apiEndpoint + "/updateDetails";
  const input = {
    tourId,
    details,
  };
  return http.put(url, input);
}

// updateHighlights
// Update tour's highlights.
//
export function updateHighlights(tourId, highlights) {
  const url = apiEndpoint + "/updateHighlights";
  const input = {
    tourId,
    highlights,
  };
  return http.put(url, input);
}

// updateDescription
// Update tour's description.
//
export function updateDescription(tourId, description) {
  const url = apiEndpoint + "/updateDescription";
  const input = {
    tourId,
    description,
  };
  return http.put(url, input);
}

// updateLastStartDate
// Update tour's last start date.
//
export function updateLastStartDate(tourId, lastStartDate) {
  const url = apiEndpoint + "/updateLastStartDate";
  const input = {
    tourId,
    lastStartDate,
  };
  return http.put(url, input);
}

// updateCapacity
// Update tour's capactiy.
//
export function updateCapacity(tourId, capacity) {
  const url = apiEndpoint + "/updateCapacity";
  const input = {
    tourId,
    capacity,
  };
  return http.put(url, input);
}

// updateTransportation
// Update tour's transportation.
//
export function updateTransportation(tourId, transportation) {
  const url = apiEndpoint + "/updateTransportation";
  const input = {
    tourId,
    transportation,
  };
  return http.put(url, input);
}

// updateAccommodation
// Update tour's accommodation.
//
export function updateAccommodation(tourId, accommodation) {
  const url = apiEndpoint + "/updateAccommodation";
  const input = {
    tourId,
    accommodation,
  };
  return http.put(url, input);
}

// updateTourType
// Update tour type.
//
export function updateTourType(tourId, tourType) {
  const url = apiEndpoint + "/updateTourType";
  const input = {
    tourId,
    tourType,
  };
  return http.put(url, input);
}

// updateDuration
// Update tour's duration (length and times).
//
export function updateDuration(tourId, duration) {
  const url = apiEndpoint + "/updateDuration";
  const input = {
    tourId,
    duration,
  };
  return http.put(url, input);
}

// addUnavailableDate
// Add an unavailable date to the tour.
//
export function addUnavailableDate(tourId, startDate, endDate) {
  const url = apiEndpoint + "/addUnavailableDates";

  const input = {
    tourId,
    startDate,
    endDate,
  };

  return http.put(url, input);
}

// removeUnavailableDate
// Remove an unavailable date from the tour.
//
export function removeUnavailableDate(tourId, unavailableDateId) {
  const url = apiEndpoint + "/removeUnavailableDate";

  const input = {
    tourId,
    unavailableDateId,
  };

  return http.put(url, input);
}

// uploadImage
// Upload an image to Amazon S3.
// This is done in two steps:
//  1. Get a signed request (authorization) from Amazon S3 by contacting Ovroom Server.
//  2. Using the signed request (autorization), directly upload image to Amazon S3.
//
// Input:
//  tour ID - (String) - mongoDB ID of the tour
//  file - (Object) - the selected file object with following structure
//    {
//        name (String) - such as 'image.jpg'
//        type (String) - such as 'image/jpeg'
//    }
//   image - Actual image file read from the disk
//
//  Returns:
//    Success:
//      Returns an object with following structure:
//        {
//            fileURL: (String) - URL to access File directly on Amazon S3
//            fileName: (String) - Filename of the file stored on Amazon S3
//        }
//    Failure:
//      null
//
export async function uploadImage(tourId, file, image) {
  // Step 1 - Get Amazon S3 signed request (authorization) from Ovroom server
  let S3AuthResponse = null;

  // Get S3 authorization for the file upload
  S3AuthResponse = await _getSignedS3Request(tourId, file);

  // Step 2 - Prepare data, and then upload image directly from the client app to Amazon S3
  if (S3AuthResponse) {
    let blobData = _convertImageDataForUpload(image, file);
    const url = S3AuthResponse.data.signedRequest;
    const contentType = { "Content-Type": file.type };

    const response = await http.put(url, blobData, {
      headers: contentType,
    });

    if (response.status === 200) {
      const rval = {
        fileURL: S3AuthResponse.data.fileURL,
        fileName: S3AuthResponse.data.storedFileName,
      };
      return rval;
    }
  }
  return null;
}

// deleteImage
// Delete a tour's image. Optionally, also delete it from the Amazon S3.
//
// Input:
//  tourId - (String, required) - mongoDB ID of the tour
//  storedFileName - (String, required) - The name of the file stored on Amazon S3
//  deleteStorage - (Boolean, requried) - If true, delete the file from Amazon S3. If false, don't delete the file from Amazon S3.
//
//  Returns: A promise
//
export function deleteImage(tourId, storedFileName, deleteStorage) {
  const url = apiURL + "/operator/tourImageFiles/delete";
  const requestBody = { tourId, storedFileName, deleteStorage };
  return http.post(url, requestBody);
}

// _getSignedS3Request
// Get Amazon S3 Signed Request so that the tour image can be directly uploaded to Amazon Se.
// Input:
//  tour ID - (String) - mongoDB ID of the tour
//  file - (Object) - the selected file object with following structure
//    {
//        name (String) - such as 'image.jpg'
//        type (String) - such as 'image/jpeg'
//    }
//
//  Returns:
//    A promise.
//    Finally when the asynch call returns, it returns an Amazon S3 signed reqeust using which a
//    file can be directly uploaded by this web application to Amazon S3.
function _getSignedS3Request(tourId, file) {
  const url =
    "https://ovroom.herokuapp.com/api/operator/tourImageFiles/prepareUpload";

  const data = {
    fileName: file.name,
    fileType: file.type,
    tourId: tourId,
  };

  return http.post(url, data);
}

// _convertImageDataForUpload
// Convert image data to be uploaded to Amazon S3
//
//  input:
//    image - the file read from the disk
//  file - (Object) - the selected file object with following structure
//    {
//        name (String) - such as 'image.jpg'
//        type (String) - such as 'image/jpeg'
//    }
//
//  Returns
//    A buffer with image data suitable for upload to Amazon S3.
//
function _convertImageDataForUpload(image, file) {
  let binary = atob(image.split(",")[1]);
  let array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }

  let blobData = new Blob([new Uint8Array(array)], {
    type: file.type,
  });

  return blobData;
}
