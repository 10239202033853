import React, { Component } from "react";

import OperatorUpcomingReservations from "./reservations/upcoming";
import OperatorPastReservations from "./reservations/past";
import OperatorCanceledReservations from "./reservations/canceled";

import * as authService from "../../services/authService";
import * as userService from "../../services/userService";

import "../../css/app.css";

import OperatorContext from "../../context/operatorContext";

class OperatorReservations extends Component {
  // Context used
  static contextType = OperatorContext;

  isMobile = window.innerWidth < 480;

  // Active component designators
  UPCOMING_RESERVATIONS = "upcoming_reservations";
  PAST_RESERVATIONS = "past_reservations";
  CANCELED_RESERVATIONS = "canceled_reservations";

  // Reservation selection state
  VIEWRESERVATION = "viewreservation";

  handleViewReservation = (reservation) => {
    let subSelection =
      this.context.operatorManageReservations.reservationSubSelection;
    this.context.onOperatorManageReservationsSubSelectionChanged(
      subSelection,
      this.VIEWRESERVATION,
      reservation._id
    );
  };

  handleNavigationClicked = ({ currentTarget: input }) => {
    // Remember the new 'selection' of the Operator | Reservation screen.
    // This can be one of "upcoming_reservations", "past_reservations" or "canceled_reservations".
    this.context.onOperatorManageReservationsSubSelectionChanged(
      input.id,
      "allreservations"
    );
  };

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      this.props.history.replace("/login");
      return null;
    }

    // Get user info from the local storage. If it is not found, send the user to the log-in screen.
    const userInfo = userService.getUserFromLocalStorage();
    if (!userInfo) {
      this.props.history.replace("/login");
      return null;
    }

    const activeComponent =
      this.context.operatorManageReservations.reservationSubSelection;

    const pastBreadCrumbStyle =
      activeComponent === this.PAST_RESERVATIONS
        ? "breadcrumb-item   active"
        : "breadcrumb-item";
    const pastLinkStyle =
      activeComponent === this.PAST_RESERVATIONS
        ? "operator-navigation-active-link-style-level2 clickable"
        : "operator-navigation-inactive-link-style clickable";

    const upcomingBreadCrumbStyle =
      activeComponent === this.UPCOMING_RESERVATIONS
        ? "breadcrumb-item active"
        : "breadcrumb-item";
    const upcomingLinkStyle =
      activeComponent === this.UPCOMING_RESERVATIONS
        ? "operator-navigation-active-link-style-level2 clickable"
        : "operator-navigation-inactive-link-style clickable";

    const canceledBreadCrumbStyle =
      activeComponent === this.CANCELED_RESERVATIONS
        ? "breadcrumb-item   active"
        : "breadcrumb-item";
    const canceledLinkStyle =
      activeComponent === this.CANCELED_RESERVATIONS
        ? "operator-navigation-active-link-style-level2 clickable"
        : "operator-navigation-inactive-link-style clickable";

    return (
      <OperatorContext.Consumer>
        {(operatorContext) => (
          <React.Fragment>
            {/* <div className="container-fluid">
          <div className="container">
            <NavBar loggedIn={true} userInfo={userInfo} />
          </div>
        </div> */}

            {/* <TopbarShadeLine /> */}

            <div className="container app-style1-container">
              <nav
                className="operator-navigation-styles"
                aria-label="breadcrumb"
              >
                <ol className="breadcrumb">
                  <li className={upcomingBreadCrumbStyle}>
                    <label
                      id={this.UPCOMING_RESERVATIONS}
                      className={upcomingLinkStyle}
                      onClick={this.handleNavigationClicked}
                    >
                      Upcoming
                    </label>
                  </li>
                  <li className={pastBreadCrumbStyle} aria-current="page">
                    <label
                      id={this.PAST_RESERVATIONS}
                      className={pastLinkStyle}
                      onClick={this.handleNavigationClicked}
                    >
                      Past
                    </label>
                  </li>

                  <li className={canceledBreadCrumbStyle}>
                    <label
                      id={this.CANCELED_RESERVATIONS}
                      className={canceledLinkStyle}
                      onClick={this.handleNavigationClicked}
                    >
                      Canceled
                    </label>
                  </li>
                </ol>
                <div className="operator-navigation-horizontal-line-container">
                  <hr className="horizontal-line-primary-color" />
                </div>
              </nav>

              <div className="app-style1-main-container-placement">
                {activeComponent === this.UPCOMING_RESERVATIONS && (
                  <OperatorUpcomingReservations />
                )}
                {activeComponent === this.PAST_RESERVATIONS && (
                  <OperatorPastReservations />
                )}
                {activeComponent === this.CANCELED_RESERVATIONS && (
                  <OperatorCanceledReservations />
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </OperatorContext.Consumer>
    );
  }
}

export default OperatorReservations;
