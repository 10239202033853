import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import getSymbolFromCurrency from "currency-symbol-map";

import "../../../../css/edit.css";
import "../../../../css/operator.css";
import "../../../../css/ovroomFormPage.css";

class OperatorViewTourPrice extends Component {
  state = { loading: false };

  renderMonthlyPrices = () => {};

  renderSinglePrice = (price) => {
    return (
      <>
        <label className="view-item-name-text">Adult price</label>
        <p className="view-item-content-text">
          {getSymbolFromCurrency(price.currency)} {price.amountAdult}
        </p>
        <label className="view-item-name-text">Child price</label>
        <p className="view-item-content-text">
          {getSymbolFromCurrency(price.currency)} {price.amountChild}
        </p>
      </>
    );
  };

  renderMonthlyPrices = (prices) => {
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let i = 0;
    return (
      <>
        <label className="view-item-name-text">Monthly price schedule</label>

        <table className="table">
          <thead>
            <tr>
              <th>Month</th>
              <th>Adult</th>
              <th>Child</th>
            </tr>
          </thead>
          <tbody>
            {prices.map((p) => (
              <tr key={i}>
                <td>{months[i++]}</td>
                <td>
                  {getSymbolFromCurrency(prices[0].currency)} {p.amountAdult}
                </td>
                <td>
                  {getSymbolFromCurrency(prices[0].currency)} {p.amountChild}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  render() {
    const { tour } = this.props;

    let priceType = "";
    if (tour.prices)
      priceType = tour.prices.length > 1 ? "Monthly Price" : "Single Price";

    return (
      <React.Fragment>
        {tour && (
          <div className="card shadow p-3 mb-5 bg-white rounded">
            <div className="container card-body">
              <div className="view-title-container">
                <label className="card-title view-title-text">Tour price</label>
                <div className="edit-button-with-warning-container">
                  <Link
                    className="btn btn-primary view-title-button-text"
                    to={{
                      pathname: "/operator/tours/tour-price",
                      state: { tour },
                    }}
                  >
                    <i className="bi bi-pencil-square"></i> Edit
                  </Link>
                  <label className="view-item-name-text">
                    (Requires review)
                  </label>
                </div>
              </div>

              <div className="view-content-container">
                <p>
                  You have chosen{" "}
                  <em className="view-emphasis-text">{priceType}</em> for the
                  tour.
                </p>
                <p>
                  To charge the same price throughout the year, use Single
                  Price. To charge different prices in different months, use
                  Monthly Price.
                </p>

                <label className="view-item-name-text">
                  Sales tax percentage
                </label>
                <p className="view-item-content-text">
                  {tour.prices[0].salesTaxPercentage} %
                </p>

                <label className="view-item-name-text">
                  Sales tax description
                </label>
                <p className="view-item-content-text">
                  {tour.prices[0].salesTaxDescription}
                </p>

                {tour.prices[0].otherTaxPercentage > 0 && (
                  <>
                    <label className="view-item-name-text">
                      Other tax percentage
                    </label>
                    <p className="view-item-content-text">
                      {tour.prices[0].otherTaxPercentage} %
                    </p>

                    <label className="view-item-name-text">
                      Other tax description
                    </label>
                    <p className="view-item-content-text">
                      {tour.prices[0].otherTaxDescription}
                    </p>
                  </>
                )}

                {priceType === "Single Price" &&
                  this.renderSinglePrice(tour.prices[0])}
                {priceType === "Monthly Price" &&
                  this.renderMonthlyPrices(tour.prices)}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

OperatorViewTourPrice.propTypes = {
  tour: PropTypes.object.isRequired,
};

export default OperatorViewTourPrice;
