import React, { Component } from "react";
import PropTypes from "prop-types";

import OperatorViewTourBasicInfo from "./viewTour/viewTourBasicInfo";
import OperatorViewTourUnavailableDates from "./viewTour/viewTourUnavailableDates";
import OperatorViewTourPrice from "./viewTour/viewTourPrice";
import OperatorViewTourDescription from "./viewTour/viewTourDescription";
import OperatorViewTourDetails from "./viewTour/viewTourDetails";
import OperatorViewTourHighlights from "./viewTour/viewTourHighlights";
import OperatorEditTourImage from "./tourImage";
import OperatorViewTourDuration from "./viewTour/viewTourDuration";
import OperatorViewTourLastStartDate from "./viewTour/viewTourLastStartDate";
import OperatorViewTourCapacity from "./viewTour/viewTourCapacity";
import OperatorViewTransportation from "./viewTour/viewTourTransportation";
import OperatorViewAccommodation from "./viewTour/viewTourAccommodation";

import * as authService from "../../../services/authService";
import * as userService from "../../../services/userService";
import * as tourServices from "../../../services/operatorTourService";

import "../../../css/app.css";
import "../../../css/edit.css";
import "../../../css/operator.css";

class OperatorTour extends Component {
  isMobile = window.innerWidth < 480;

  state = { tour: undefined, loading: true };

  handleTourProfileImageChanged = (tour, image) => {
    this.props.onTourProfileImageChanged(tour, image);
  };

  handleTourProfileImageDeleted = (tour, image) => {
    this.props.onTourProfileImageDeleted(tour, image);
  };

  handleTourProfileImageAdded = (tour, image) => {
    this.props.onTourProfileImageAdded(tour, image);
  };

  async componentDidMount() {
    // Get the tour ID from the props
    let tourId = this.props.tourId;

    if (tourId) {
      let response;
      try {
        response = await tourServices.getTour(tourId);
      } catch (e) {
      } finally {
        this.setState({ loading: false });
      }
      if (response && response.status === 200) {
        this.setState({ tour: response.data });
      }
    }
  }

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      this.props.history.replace("/login");
      return null;
    }

    // Get user info from the local storage. If it is not found, send the user to the log-in screen.
    const userInfo = userService.getUserFromLocalStorage();
    if (!userInfo) {
      this.props.history.replace("/login");
      return null;
    }

    const { tour, loading } = this.state;
    const { incompleteTour } = this.props;

    return (
      <React.Fragment>
        <div className="container">
          {tour && (
            <div className="app-main-area-flex-layout">
              {incompleteTour && (
                <h1 className="view-section-title">Create Tour</h1>
              )}
              {!incompleteTour && (
                <div>
                  <button
                    onClick={this.props.onShowAllTours}
                    className="btn btn-outline-primary submit-button"
                  >
                    Show all tours
                  </button>
                  <div className="view-section-title">{tour.tourTitle}</div>
                </div>
              )}
              <div className="horizontal-line-container">
                <hr className="horizontal-line-primary-color" />
              </div>

              <OperatorViewTourBasicInfo tour={tour} />
              <OperatorViewTourUnavailableDates tour={tour} />
              <OperatorViewTourPrice tour={tour} />
              <OperatorViewTourDescription tour={tour} />
              <OperatorViewTourDetails tour={tour} />
              <OperatorViewTourHighlights tour={tour} />
              <OperatorEditTourImage
                tour={tour}
                onTourProfileImageChanged={this.handleTourProfileImageChanged}
                onTourProfileImageDeleted={this.handleTourProfileImageDeleted}
                onTourProfileImageAdded={this.handleTourProfileImageAdded}
              />
              <OperatorViewTourDuration tour={tour} />
              <OperatorViewTourLastStartDate tour={tour} />
              <OperatorViewTourCapacity tour={tour} />
              <OperatorViewTransportation tour={tour} />
              <OperatorViewAccommodation tour={tour} />
            </div>
          )}
          {loading && (
            <div className="view-spinner-container">
              <span
                className="spinner-border spinner-border-sm m-1"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          )}
          {!tour && <div>No tour</div>}
        </div>
      </React.Fragment>
    );
  }
}

OperatorTour.propTypes = {
  tourId: PropTypes.string.isRequired,
  onShowAllTours: PropTypes.func.isRequired,
  onTourProfileImageChanged: PropTypes.func.isRequired,
  onTourProfileImageDeleted: PropTypes.func.isRequired,
  onTourProfileImageAdded: PropTypes.func.isRequired,
};

export default OperatorTour;
