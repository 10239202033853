import React from "react";
import Joi from "joi";
import { ToastContainer } from "react-toastify";

import * as authService from "../../../../services/authService";
import * as operatorTourService from "../../../../services/operatorTourService";
import Form from "../../../common/form";
import "../../../../css/ovroomFormPage.css";

import { assetsURL, logoImageName } from "../../../../config.json";

import UserContext from "../../../../context/userContext";

class CreateTourSubmit extends Form {
  isMobile = window.innerWidth < 480;

  // Context used
  static contextType = UserContext;

  state = {
    data: {},
    errors: {},
    loading: false,
  };

  schema = Joi.object({});

  doSubmit = async () => {
    // Assemble the Tour object
    const initInfo = JSON.parse(localStorage.getItem("TourInitInfo"));
    const transportation = JSON.parse(
      localStorage.getItem("TourTransportation")
    );
    const accommodation = JSON.parse(localStorage.getItem("TourAccommodation"));
    const duration = JSON.parse(localStorage.getItem("TourDuration"));
    const capacity = JSON.parse(localStorage.getItem("TourCapacity"));
    const cancellationPolicy = JSON.parse(
      localStorage.getItem("TourCancelPolicy")
    );
    const prices = JSON.parse(localStorage.getItem("TourPrice"));
    const schedule = JSON.parse(localStorage.getItem("TourSchedule"));
    const unavailableDates = JSON.parse(
      localStorage.getItem("TourUnavailableDates")
    );
    const lastStartDate = JSON.parse(localStorage.getItem("TourLastStartDate"));
    const description = JSON.parse(localStorage.getItem("TourDescription"));
    const details = JSON.parse(localStorage.getItem("TourDetails"));
    const highlights = JSON.parse(localStorage.getItem("TourHighlights"));

    let tour = {
      ...initInfo,
      transportation,
      accommodation,
      duration,
      capacity,
      cancellationPolicy,
      prices,
      schedule,
      unavailableDates,
      lastStartDate,
      description,
      details,
      highlights,
    };

    // Send the tour object to the sever
    let response;
    try {
      this.setState({ loading: true });
      response = await operatorTourService.createTour(tour);
    } catch (e) {
    } finally {
      this.setState({ loading: false });
    }
    if (response && response.status === 200) {
      // Clean up - Delete local storage
      localStorage.removeItem("TourInitInfo");
      localStorage.removeItem("TourTransportation");
      localStorage.removeItem("TourAccommodation");
      localStorage.removeItem("TourDuration");
      localStorage.removeItem("TourCapacity");
      localStorage.removeItem("TourCancelPolicy");
      localStorage.removeItem("TourPrice");
      localStorage.removeItem("TourSchedule");
      localStorage.removeItem("TourUnavailableDates");
      localStorage.removeItem("TourLastStartDate");
      localStorage.removeItem("TourDescription");
      localStorage.removeItem("TourDetails");
      localStorage.removeItem("TourHighlights");

      // Send the user to the add images page
      this.props.history.push("/operator/manage");
      return;
    }
  };

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      //localStorage.setItem("UrlBeforeLogin", this.props.location.pathname);
      this.props.history.replace("/login");
      return null;
    }

    let containerClass,
      headerContainer,
      instructionContainer,
      formContainer,
      logoImage,
      pageHeadingTextStyle;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";
      headerContainer = "ovroom-form-header-container-mobile";
      instructionContainer = "ovroom-form-instruction-container-option1";
      formContainer = "ovroom-form-elements-container-option1";
      logoImage = "logo-image-mobile";
      pageHeadingTextStyle = "ovroom-form-page-heading-mobile";
    } else {
      // desktop and tablet

      containerClass = "container ovroom-form-container";
      headerContainer = "ovroom-form-header-container";
      instructionContainer = "ovroom-form-instruction-container-option1";
      formContainer = "ovroom-form-elements-container-option1";
      logoImage = "logo-image";
      pageHeadingTextStyle = "ovroom-form-page-heading";
    }

    return (
      <UserContext.Consumer>
        {(userContext) => (
          <div className={containerClass}>
            <div className={headerContainer}>
              <img
                className={logoImage}
                src={assetsURL + logoImageName}
                alt="Ovroom logo"
              />
              <p className={pageHeadingTextStyle}>Create Tour</p>
            </div>
            <div className={instructionContainer}>
              You are now ready to submit the tour to Ovroom. If you need to
              review any details that you've alread submitted, please use the
              browser 'back' button to go back to previous pages. When ready to
              submit the tour, click on 'Create tour' button below.
            </div>
            <div className={formContainer}>
              <form>{this.renderSubmitButton("Create tour")}</form>
              <div className="vertical-filler-container" />
            </div>
            <ToastContainer />
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default CreateTourSubmit;
