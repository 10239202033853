import http from "./httpService";
import { apiURL } from "../config.json";

const apiEndpoint = apiURL + "/operator/accounts/";

// registerTourOperatorAccount
// Register a new business account with Stripe for the tour operator.
//
// Input:
//
export function registerTourOperatorAccount() {
  const url = apiEndpoint;

  // The following HTTP request returns a promise
  return http.post(url);
  
}
