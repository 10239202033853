import React, { Component } from "react";

import CreateTourTourScheduleForm from "./createTourTourScheduleForm";
import CreateTourUserScheduleForm from "./createTourUserScheduleForm";

import { ToastContainer } from "react-toastify";

import * as authService from "../../../../services/authService";
import "../../../../css/ovroomFormPage.css";

import { assetsURL, logoImageName } from "../../../../config.json";

class CreateTourSchedule extends Component {
  isMobile = window.innerWidth < 480;

  state = { tourScheduleSelected: true };

  handleRadioButtonClicked = ({ currentTarget: input }) => {
    if (input.id === "tourSchedule")
      this.setState({ tourScheduleSelected: input.checked });
    else this.setState({ tourScheduleSelected: !input.checked });
  };

  doSubmit = (schedule) => {
    localStorage.setItem("TourSchedule", JSON.stringify(schedule));
    this.props.history.push("/operator/create-tour-unavailabledates");
  };

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      //localStorage.setItem("UrlBeforeLogin", this.props.location.pathname);
      this.props.history.replace("/login");
      return null;
    }

    let containerClass, headerContainer, logoImage, pageHeadingTextStyle;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";
      headerContainer = "ovroom-form-header-container-mobile";
      pageHeadingTextStyle = "ovroom-form-page-heading-mobile";
      logoImage = "logo-image-mobile";
    } else {
      // desktop and tablet
      containerClass = "container ovroom-form-container";
      headerContainer = "ovroom-form-header-container";
      pageHeadingTextStyle = "ovroom-form-page-heading";
      logoImage = "logo-image";
    }
    return (
      <div className={containerClass}>
        <div className={headerContainer}>
          <img
            className={logoImage}
            src={assetsURL + logoImageName}
            alt="Ovroom logo"
          />
          <p className={pageHeadingTextStyle}>Tour Schedule</p>
        </div>

        <div
          className="btn-group ovroom-form-elements-container-option3"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="tourSchedule"
            autoComplete="off"
            checked={this.state.tourScheduleSelected}
            onChange={this.handleRadioButtonClicked}
          />
          <label className="btn btn-outline-secondary" htmlFor="tourSchedule">
            Tour Schedule
          </label>

          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="userSchedule"
            autoComplete="off"
            checked={!this.state.tourScheduleSelected}
            onChange={this.handleRadioButtonClicked}
          />
          <label className="btn btn-outline-secondary" htmlFor="userSchedule">
            User Schedule
          </label>
        </div>

        <div className="ovroom-form-instructions-container-option3">
          {this.state.tourScheduleSelected && (
            <React.Fragment>
              <p>
                If multiple employees are available to conduct simultaneous
                tours, then select Tour Schedule. If only one person is
                available to conduct the tour, then select User Schedule.
              </p>
              <p>
                For Tour Schedule, below please specify number of simultaneous
                tours you can conduct in each month of the year.
              </p>
            </React.Fragment>
          )}
          {!this.state.tourScheduleSelected && (
            <p>
              Select User Schedule if only one person is available to conduct
              the tour.
            </p>
          )}
        </div>

        <div className="ovroom-form-container-option3">
          {this.state.tourScheduleSelected && (
            <CreateTourTourScheduleForm onSubmitForm={this.doSubmit} />
          )}
          {!this.state.tourScheduleSelected && (
            <CreateTourUserScheduleForm onSubmitForm={this.doSubmit} />
          )}
        </div>
        <div className="vertical-filler-container" />
        <ToastContainer />
      </div>
    );
  }
}

export default CreateTourSchedule;
