import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import PropTypes from "prop-types";

import * as dateUtils from "../../../../utils/date";
import "../../../../css/edit.css";
import "../../../../css/operator.css";
import "../../../../css/ovroomFormPage.css";

class OperatorViewTourUnavailableDates extends Component {
  renderUnavailableDates = () => {
    const { unavailableDates } = this.props.tour;

    let rval = unavailableDates.map((u) => {
      const startDate = dateUtils.getStringFromDate(u.startTimestamp);
      const endDate = dateUtils.getStringFromDate(u.endTimestamp);

      return (
        <div key={u._id}>
          {_.isEqual(startDate, endDate) && (
            <p className="view-item-content-text">{startDate}</p>
          )}

          {!_.isEqual(startDate, endDate) && (
            <p className="view-item-content-text">
              {startDate} <em className="view-emphasis-text">to</em> {endDate}
            </p>
          )}
        </div>
      );
    });
    return rval;
  };

  render() {
    const { tour } = this.props;
    return (
      <React.Fragment>
        {tour && (
          <div className="card shadow p-3 mb-5 bg-white rounded">
            <div className="container card-body">
              <div className="view-title-container">
                <label className="card-title view-title-text">
                  Unavailable dates
                </label>
                <Link
                  className="btn btn-primary view-title-button-text"
                  to={{
                    pathname: "/operator/tours/tour-unavailable-dates",
                    state: { tour },
                  }}
                >
                  <i className="bi bi-pencil-square"></i> Edit
                </Link>
              </div>
              <div className="view-content-container">
                <div>{this.renderUnavailableDates()}</div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

OperatorViewTourUnavailableDates.propTypes = {
  tour: PropTypes.object.isRequired,
};

export default OperatorViewTourUnavailableDates;
