// forgotPasswordIntent.jsx
// This is the component that launches when the user clicks on 'Forgot password' link on the
// login page. This is where we collect the email address of the user and submit it to the
// server for the change password intent.
//
import React from "react";
import Joi from "joi";
import { ToastContainer } from "react-toastify";

import * as authService from "../services/authService";
import Form from "./common/form";
import "../css/ovroomFormPage.css";

import { assetsURL, logoImageName } from "../config.json";

class ForgotPasswordIntent extends Form {
  isMobile = window.innerWidth < 480;

  state = {
    data: {
      email: "",
    },
    errors: {},
    loading: false,
  };

  schema = Joi.object({
    email: Joi.string()
      .trim()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
  });

  doSubmit = async () => {
    const { email } = this.state.data;

    // Construct the object to be sent
    const emailInput = { email };

    let response;
    try {
      this.setState({ loading: true });
      response = await authService.forgotPasswordIntent(emailInput);
    } catch (e) {
    } finally {
      this.setState({ loading: false });
    }
    // Check if the response is success
    if (response && response.status === 200) {
      this.props.history.push("/forgotPasswordIntentSuccess");
    }
  };

  render() {
    let containerClass,
      headerContainer,
      formContainer,
      logoImage,
      forgotPasswordLabelText;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";
      headerContainer = "ovroom-form-header-container-mobile";
      formContainer = "ovroom-form-elements-container-option1";
      logoImage = "logo-image-mobile";
      forgotPasswordLabelText = "ovroom-form-page-heading-mobile";
    } else {
      // desktop and tablet

      containerClass = "container ovroom-form-container";
      headerContainer = "ovroom-form-header-container";
      formContainer = "ovroom-form-elements-container-option1";
      logoImage = "logo-image";
      forgotPasswordLabelText = "ovroom-form-page-heading";
    }

    return (
      <React.Fragment>
        <div className={containerClass}>
          <div className={headerContainer}>
            <img
              className={logoImage}
              src={assetsURL + logoImageName}
              alt="Ovroom logo"
            />
            <p className={forgotPasswordLabelText}>Forgot Password</p>
          </div>
          <p className="ovroom-form-instruction-container-option1">
            To receive a link to change your password, please type your email
            address below and click on Send button.
          </p>
          <div className={formContainer}>
            <form>
              {this.renderInput("email", "Email", false, "email")}
              {this.renderSubmitButton("Send")}
            </form>
          </div>
          <ToastContainer />
        </div>
      </React.Fragment>
    );
  }
}

export default ForgotPasswordIntent;
