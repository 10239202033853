import React from "react";

import "../../css/ovroomFormPage.css";

import { assetsURL, logoImageName } from "../../config.json";

const RegisterOperatorSuccessful = ({ history }) => {
  const isMobile = window.innerWidth < 480;

  const doSubmit = () => {
    history.push("/operator/create-tour-start");
  };

  let containerClass, titleClass, headingClass, logoImage;
  if (isMobile) {
    containerClass = "container ovroom-form-container-mobile";

    titleClass = "ovroom-form-header-container-mobile";
    headingClass = "ovroom-form-page-heading-mobile";
    logoImage = "logo-image-mobile";
  } else {
    containerClass = "container ovroom-form-container";

    titleClass = "ovroom-form-header-container";
    headingClass = "ovroom-form-page-heading";
    logoImage = "logo-image";
  }

  return (
    <div className={containerClass}>
      <div className={titleClass}>
        <img
          className={logoImage}
          src={assetsURL + logoImageName}
          alt="Ovroom logo"
        />
        <p className={headingClass}>Tour Operator successful</p>
      </div>

      <div className="ovroom-form-instruction-container-option1">
        <p>
          Your tour operator registration is successful. Please go ahead and add
          your tour!
        </p>
        <button
          className="btn btn-primary ovroom-form-submit-button"
          onClick={doSubmit}
        >
          Add tour
        </button>
      </div>
    </div>
  );
};

export default RegisterOperatorSuccessful;
