import React, { useRef } from "react";
import { Dropdown } from "bootstrap";
import { NavLink, Link } from "react-router-dom";

import "../css/navbar.css";

import { assetsURL, logoImageName } from "../config.json";

const NavBar = ({ loggedIn, userInfo, themeColor }) => {
  const isMobile = window.innerWidth < 480;
  const isDesktopOrLaptop = !isMobile;

  let logoTextDesktopStyle, navigationTextDesktopStyle, logoName;
  if (themeColor === "safed") {
    logoTextDesktopStyle = "logo-text-safed-desktop clickable";
    navigationTextDesktopStyle =
      "nav-link me-auto navigation-text-safed-desktop";
    logoName = "LogoWhite.png";
  } else {
    logoTextDesktopStyle = "logo-text-desktop clickable";
    navigationTextDesktopStyle = "nav-link me-auto navigation-text-desktop";
    logoName = logoImageName;
  }

  const ddRef = useRef();

  let unreadBadgeCount =
    userInfo && parseInt(userInfo.unreadMessageCount) > 0
      ? parseInt(userInfo.unreadMessageCount)
      : undefined;

  return (
    <>
      {!loggedIn && (
        <nav className="navbar navbar-expand-sm navbar-light">
          <div className="container-fluid">
            <NavLink className="nav-link me-auto" to="/">
              <img
                className="logo-image-desktop"
                src={assetsURL + logoName}
                alt="Ovroom logo"
              />
              {isDesktopOrLaptop && (
                <label className={logoTextDesktopStyle}>Ovroom</label>
              )}
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#ovroomNavBarContentNotLoggedIn"
              aria-controls="ovroomNavBarContentNotLoggedIn"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <label className={navigationTextDesktopStyle + "  clickable"}>
                <i className="far fa-address-card"></i>
              </label>
            </button>

            <div
              className="collapse navbar-collapse"
              id="ovroomNavBarContentNotLoggedIn"
            >
              <ul className="navbar-nav  mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink className={navigationTextDesktopStyle} to="/login">
                    Login
                  </NavLink>
                </li>
                <li className="nav-item">
                  <a
                    className={navigationTextDesktopStyle}
                    href="https://operator.ovroom.com"
                  >
                    Operator Login
                  </a>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={navigationTextDesktopStyle}
                    to="/register"
                  >
                    Register
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}

      {loggedIn && (
        <nav className="navbar navbar-expand-sm navbar-light">
          <div className="container-fluid">
            <NavLink className="nav-link me-auto" to="/">
              <img
                className="logo-image-desktop"
                src={assetsURL + logoName}
                alt="Ovroom logo"
              />
              {isDesktopOrLaptop && (
                <label className={logoTextDesktopStyle}>Ovroom</label>
              )}
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#ovroomNavBarContentLoggedIn"
              aria-controls="ovroomNavBarContentLoggedIn"
              aria-expanded="false"
              aria-label="Toggle navigation"
              style={{ borderStyle: "none" }}
            >
              <label className={navigationTextDesktopStyle + "  clickable"}>
                <i className="bi bi-list" style={{ fontSize: 25 }}></i>
                {userInfo && parseInt(userInfo.unreadMessageCount) > 0 && (
                  <sup>
                    <span className="badge badge-pill badge-primary navbar-badge-mobile">
                      {userInfo.unreadMessageCount <= 9
                        ? userInfo.unreadMessageCount
                        : "9+"}
                    </span>
                  </sup>
                )}
              </label>
            </button>

            <div
              className="collapse navbar-collapse"
              id="ovroomNavBarContentLoggedIn"
            >
              <ul className="navbar-nav  mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink
                    className={navigationTextDesktopStyle}
                    activeClassName="navigation-text-active"
                    to="/login"
                  >
                    Tours
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={navigationTextDesktopStyle}
                    activeClassName="navigation-text-active"
                    to="/register"
                  >
                    {isDesktopOrLaptop &&
                      userInfo &&
                      parseInt(userInfo.unreadMessageCount) > 0 && (
                        <sup>
                          <span className="badge badge-pill badge-primary navbar-badge">
                            {parseInt(userInfo.unreadMessageCount) <= 9
                              ? parseInt(userInfo.unreadMessageCount)
                              : "9+"}
                          </span>
                        </sup>
                      )}
                    Messages
                    {isMobile && userInfo && userInfo.unreadMessageCount > 0 && (
                      <sup>
                        <span className="badge badge-pill badge-primary navbar-badge-mobile">
                          {userInfo.unreadMessageCount <= 9
                            ? userInfo.unreadMessageCount
                            : "9+"}
                        </span>
                      </sup>
                    )}
                  </NavLink>
                </li>

                {userInfo && userInfo.isOwner && (
                  <NavLink
                    className={navigationTextDesktopStyle}
                    activeClassName="navigation-text-active"
                    to="/operator/reservations"
                  >
                    Reservations
                  </NavLink>
                )}

                {userInfo && userInfo.isOwner && (
                  <NavLink
                    className={navigationTextDesktopStyle}
                    activeClassName="navigation-text-active"
                    to="/operator/manage"
                  >
                    Manage
                  </NavLink>
                )}

                {isMobile && (
                  <>
                    <li className="nav-item">
                      <NavLink
                        className={navigationTextDesktopStyle}
                        to="/profile"
                      >
                        Profile
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink
                        className={navigationTextDesktopStyle}
                        to="/logout"
                      >
                        Logout
                      </NavLink>
                    </li>
                  </>
                )}
              </ul>
            </div>
            {isDesktopOrLaptop && (
              <div className="dropdown">
                <a
                  className="nav-link active"
                  href="#"
                  id="profileDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <label className={navigationTextDesktopStyle + "  clickable"}>
                    {userInfo && userInfo.firstName}{" "}
                    <i className="far fa-user-circle"></i>
                  </label>
                </a>

                <ul className="dropdown-menu" aria-labelledby="profileDropdown">
                  <li>
                    <NavLink className="dropdown-item" to="/profile">
                      Profile
                    </NavLink>
                  </li>
                  {userInfo && !userInfo.isOwner && (
                    <li>
                      <NavLink
                        className="dropdown-item"
                        to="/becomeTourOperator"
                      >
                        Become Tour Operator
                      </NavLink>
                    </li>
                  )}
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to="/logout"
                      id="logout-label"
                    >
                      Logout
                    </NavLink>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </nav>
      )}
    </>
  );
};

export default NavBar;
