import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import { showToast } from "../../utils/toast";
import * as authService from "../../services/authService";

import "../../css/registerVerifyEmail.css";

import { assetsURL, logoImageName } from "../../config.json";

import UserContext from "../../context/userContext";

class RegisterTourOperatorVerifyEmail extends Component {
  // Context used
  static contextType = UserContext;

  // Component properties
  isMobile = window.innerWidth < 480;

  state = {
    errors: {},
  };

  handleResendEmail = async () => {
    let response;
    try {
      const emailObject = {
        email: this.context.email,
      };
      this.setState({ loading: true }); // show spinner
      response = await authService.getVerificationEmail(emailObject);
    } catch (e) {
    } finally {
      this.setState({ loading: false }); // remove spinner
    }
    // Check if the response is success
    if (response && response.status === 200) {
      // may be show success?
      showToast("Email sent!", "success");
    }
  };

  doSubmit = async () => {
    // Go to the log-in page
    this.props.history.push("/registration-successful");
  };

  render() {
    let containerClass, pageHeaderContainerClass, headingClass, logoImage;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";

      pageHeaderContainerClass = "ovroom-form-header-container-mobile";
      headingClass = "ovroom-form-page-heading-mobile";
      logoImage = "logo-image-mobile";
    } else {
      containerClass = "container ovroom-form-container";

      pageHeaderContainerClass = "ovroom-form-header-container";
      headingClass = "ovroom-form-page-heading";
      logoImage = "logo-image";
    }
    return (
      <UserContext.Consumer>
        {() => (
          <div className={containerClass}>
            <div className={pageHeaderContainerClass}>
              <img
                className={logoImage}
                src={assetsURL + logoImageName}
                alt="Ovroom logo"
              />
              <p className={headingClass}>Confirm your email(s)</p>
            </div>

            <div className="ovroom-form-instruction-container-option1">
              <p>
                Ovroom has sent a verification email(s) to{" "}
                <b>{this.context.email}</b>. Please click on the verification
                link in the email to verify your email address.
              </p>
              <button
                className="btn btn-primary ovroom-form-submit-button"
                onClick={this.doSubmit}
              >
                Next
              </button>
            </div>

            <div className="resendContainer">
              <label className="resend-text">Didn't get the email </label>
              <label
                className="resend-text resend-text-link clickable"
                onClick={this.handleResendEmail}
              >
                Send again{" "}
              </label>
            </div>
            <ToastContainer />
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}
export default RegisterTourOperatorVerifyEmail;
