import React from "react";

import "../css/sectionCard.css";

const SectionCard = ({ url, title }) => {
  return (
    <div className="card section-card border-0">
      <img
        src={url}
        className="card-img-top rounded section-card-image"
        alt={title}
      />

      <div className="card-body  p-1 ">
        <p className="section-card-body-text">{title}</p>
      </div>
    </div>
  );
};

export default SectionCard;
