import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

// TableBody component
// The component that displays table's body.
//
// Input:
//  data (Array of objects, required) - This is the array of objects that will be displayed in the table body.
//  columns (Array of objects, required) - This is the array of column title objects which looks as follows:
//              {
//                  label: <String for the column header title>
//                  path: <String that represents the variable-name of the path of the column in the calling component>
//                  key: <Optional, but must have 'content' if present - String - A unique string that identifies the column uniquely. If key is provide, it is used to
//                      display the column header component 'th', otherwise the 'path' is used as the key.
//                  content: <Optional, but must have 'key' if present - JSX component (in other words a React Component, which is a JavaScript object). This is used
//                      to render column contents which are JSX components like (<h1> Title </h1>).
//              }
//  rowKey (String, required) - Variable name in the data objects that identifies the unique key for each row.
//
class TableBody extends Component {
  _renderCell = (item, column) => {
    if (column.content) return column.content(item);
    else return _.get(item, column.path);
  };

  _createCellKey = (item, rowKey, column) => {
    return item[rowKey] + (column.path || column.key);
  };

  render() {
    const { data, columns, rowKey } = this.props;
    return (
      <tbody>
        {data.map((item) => (
          <tr key={item[rowKey]}>
            {columns.map((column) => (
              <td key={this._createCellKey(item, rowKey, column)}>
                {this._renderCell(item, column)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }
}

TableBody.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  rowKey: PropTypes.string.isRequired,
};

export default TableBody;
