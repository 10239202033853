import React from "react";
import Joi from "joi";
import { ToastContainer } from "react-toastify";

import * as tourService from "../../../../services/operatorTourService";
import * as authService from "../../../../services/authService";
import Form from "../../../common/form";
import "../../../../css/ovroomFormPage.css";
import "../../../../css/createTourDetails.css";

import { assetsURL, logoImageName } from "../../../../config.json";

import UserContext from "../../../../context/userContext";

class TourDetails extends Form {
  isMobile = window.innerWidth < 480;

  // Context used
  static contextType = UserContext;

  state = {
    data: {
      details: [],
    },
    optionalData: {
      title: "",
      description: "",
    },
    editDetailData: {
      title: "",
      description: "",
    },
    errors: {},
    loading: false,
    changed: false,
    editingDetail: false,
  };

  schema = Joi.object({
    details: Joi.array(),
  });

  editDetailDataSchema = Joi.object({
    title: Joi.string().trim().min(2).max(32).required().label("Title"),
    description: Joi.string()
      .trim()
      .min(2)
      .max(3000)
      .required()
      .label("Description"),
  });

  optionalDataSchema = Joi.object({
    title: Joi.string().trim().min(2).max(32).required().label("Title"),
    description: Joi.string()
      .trim()
      .min(2)
      .max(3000)
      .required()
      .label("Description"),
  });

  validateEditDetailProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const rule = this.editDetailDataSchema.extract(name);
    const schema = Joi.object({ [name]: rule });
    const { error } = schema.validate(obj);
    return error ? error.details[0].message : null;
  };

  validateEditDetail = () => {
    const { editDetailData } = this.state;
    const { error } = this.editDetailDataSchema.validate(editDetailData, {
      abortEarly: false,
    });
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  validateDetailProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const rule = this.optionalDataSchema.extract(name);
    const schema = Joi.object({ [name]: rule });
    const { error } = schema.validate(obj);
    return error ? error.details[0].message : null;
  };

  validateDetail = () => {
    const { optionalData } = this.state;
    const { error } = this.optionalDataSchema.validate(optionalData, {
      abortEarly: false,
    });
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  handleChangeEditDetail = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };

    let errorMessage;

    errorMessage = this.validateEditDetailProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name]; // If no errors, remove any existing errors for this field, if any.

    let editDetailData = { ...this.state.editDetailData };
    editDetailData[input.name] = input.value;
    this.setState({ editDetailData, changed: true });
  };

  handleChangeOfDetails = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };

    let errorMessage;

    errorMessage = this.validateDetailProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name]; // If no errors, remove any existing errors for this field, if any.

    let optionalData = { ...this.state.optionalData };
    optionalData[input.name] = input.value;
    this.setState({ optionalData, errors });
  };

  handleAddDetails = () => {
    let details = [...this.state.data.details];
    let optionalData = { ...this.state.optionalData };
    let detail = {
      id: new Date(),
      title: optionalData.title,
      description: optionalData.description,
    };
    details.push(detail);

    // Reset optional data
    optionalData.title = "";
    optionalData.description = "";

    this.setState({ data: { details }, optionalData, changed: true });
  };

  componentDidMount = () => {
    let { details } = this.props.location.state.tour;
    if (!details) return;
    let i = 0;
    details = details.map((d) => {
      return {
        id: i++,
        title: d.title,
        description: d.description,
        editing: false, // Is the detail being edited?
      };
    });
    this.setState({ data: { details } });
  };

  doCancel = () => {
    this.props.history.goBack();
  }

  doSubmit = async () => {
    const { data } = this.state;
    let details = data.details.map((u) => {
      return { title: u.title, description: u.description };
    });

    const { tour } = this.props.location.state;

    // Update the server
    let response;
    try {
      this.setState({ loading: true });
      response = await tourService.updateDetails(tour._id, details);
    } catch (e) {
    } finally {
      this.setState({ loading: false });
      if (response && response.status === 200) this.props.history.goBack();
    }
  };

  handleEditDetail = (detail) => {
    let index = this.state.data.details.findIndex((u) => u.id === detail.id);
    detail.editing = true;
    let details = this.state.data.details.filter((u) => u.id !== detail.id);
    details.splice(index, 0, detail);
    let editDetailData = { ...this.state.editDetailData };
    editDetailData.title = detail.title;
    editDetailData.description = detail.description;
    this.setState({ data: { details }, editDetailData, editingDetail: true });
  };

  handleCancelEdit = (detail) => {
    let index = this.state.data.details.findIndex((u) => u.id === detail.id);
    detail.editing = false;
    let details = this.state.data.details.filter((u) => u.id !== detail.id);
    details.splice(index, 0, detail);
    this.setState({ data: { details }, editingDetail: false });
  };

  handleSaveDetail = (detail) => {
    let index = this.state.data.details.findIndex((u) => u.id === detail.id);
    let details = this.state.data.details.filter((u) => u.id !== detail.id);
    let updatedDetail = { ...this.state.editDetailData };
    updatedDetail["id"] = new Date();
    updatedDetail["editing"] = false;
    details.splice(index, 0, updatedDetail);
    this.setState({ data: { details }, editingDetail: false, changed: true });
  };

  handleDeleteDetail = (detail) => {
    let details = this.state.data.details.filter((u) => u.id !== detail.id);
    this.setState({ data: { details }, changed: true });
  };

  renderEachDetail = (detail) => {
    const { editDetailData } = this.state;
    const { errors } = this.state;
    return (
      <div key={detail.id}>
        {!detail.editing && (
          <div className="existing-detail-container">
            <div className="existing-detail-removebutton-container">
              <button
                className="btn btn-sm btn-primary"
                onClick={() => this.handleEditDetail(detail)}
                disabled={this.state.editingDetail}
              >
                <i className="bi bi-pencil-square"></i> Edit
              </button>

              <label
                className="btn btn-sm"
                style={{ color: "var(--color-kesudo)" }}
                onClick={() => this.handleDeleteDetail(detail)}
              >
                <i className="bi bi-trash"></i>
              </label>
            </div>
            <div>
              <div>
                <label className="detail-title-text-style">
                  {detail.title}
                </label>
              </div>

              <div>
                <p>{detail.description}</p>
              </div>
            </div>
          </div>
        )}

        {detail.editing && (
          <div className="existing-detail-container" key={detail.id}>
            <div className="existing-detail-removebutton-container">
              <button
                className="btn btn-sm btn-primary"
                onClick={() => this.handleSaveDetail(detail)}
              >
                <i className="bi bi-save"></i> Save
              </button>

              <button
                className="btn btn-sm"
                onClick={() => this.handleCancelEdit(detail)}
              >
                Cancel
              </button>
            </div>
            <div>
              <div>
                <input
                  id="title"
                  name="title"
                  value={editDetailData["title"]}
                  error={errors["title"]}
                  type="text"
                  onChange={this.handleChangeEditDetail}
                  className="form-control input-form mt-2"
                />
                {errors["title"] && (
                  <div className="alert alert-danger">{errors["title"]}</div>
                )}
              </div>

              <div>
                <textarea
                  id="description"
                  name="description"
                  value={editDetailData["description"]}
                  error={errors["description"]}
                  type="text"
                  rows={5}
                  onChange={this.handleChangeEditDetail}
                  className="form-control input-form mt-2"
                />
                {errors["description"] && (
                  <div className="alert alert-danger">
                    {errors["description"]}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  renderDetails = () => {
    const { details } = this.state.data;

    return (
      <React.Fragment>
        {details.map((detail) => this.renderEachDetail(detail))}
      </React.Fragment>
    );
  };

  render() {
    const { optionalData } = this.state;
    const { errors } = this.state;

    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      //localStorage.setItem("UrlBeforeLogin", this.props.location.pathname);
      this.props.history.replace("/login");
      return null;
    }

    let containerClass,
      headerContainer,
      formContainer,
      logoImage,
      pageHeadingTextStyle,
      instructionsContainer,
      sectionHeadingTextStyle;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";
      headerContainer = "ovroom-form-header-container-mobile";
      instructionsContainer = "ovroom-form-instruction-container-option1";
      formContainer = "ovroom-form-elements-container-option1";
      sectionHeadingTextStyle = "ovroom-form-section-heading-mobile";
      logoImage = "logo-image-mobile";
      pageHeadingTextStyle = "ovroom-form-page-heading-mobile";
    } else {
      // desktop and tablet

      containerClass = "container ovroom-form-container";
      headerContainer = "ovroom-form-header-container";
      instructionsContainer = "ovroom-form-instruction-container-option1";
      sectionHeadingTextStyle = "ovroom-form-section-heading";
      formContainer = "ovroom-form-elements-container-option1";
      logoImage = "logo-image";
      pageHeadingTextStyle = "ovroom-form-page-heading";
    }

    return (
      <UserContext.Consumer>
        {(userContext) => (
          <div className={containerClass}>
            <div className={headerContainer}>
              <img
                className={logoImage}
                src={assetsURL + logoImageName}
                alt="Ovroom logo"
              />
              <p className={pageHeadingTextStyle}>Tour Details</p>
            </div>
            <div className={instructionsContainer}>
              <b>Optional.</b> If you would like to describe the details of
              everyday of the tour, then fill out this section. Provide 'Title'
              such as{" "}
              <b>
                <em>Day 1</em>
              </b>{" "}
              or{" "}
              <b>
                <em>Morning</em>
              </b>
              , and then describe relevant activity details in the 'Description'
              section.
            </div>
            <div className={formContainer}>
              <div className="new-detail-container">
                <div className="form-group input-form-group">
                  <label className="input-form-label" htmlFor="detail">
                    Title
                  </label>
                  <input
                    id="title"
                    name="title"
                    value={optionalData["title"]}
                    error={errors["title"]}
                    type="text"
                    onChange={this.handleChangeOfDetails}
                    className="form-control input-form"
                  />
                  {errors["title"] && (
                    <div className="alert alert-danger">{errors["title"]}</div>
                  )}
                </div>
                <div className="form-group input-form-group">
                  <label className="input-form-label" htmlFor="description">
                    Description
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    value={optionalData["description"]}
                    error={errors["description"]}
                    type="text"
                    rows={5}
                    onChange={this.handleChangeOfDetails}
                    className="form-control input-form"
                  />
                  {errors["description"] && (
                    <div className="alert alert-danger">
                      {errors["description"]}
                    </div>
                  )}
                </div>
              </div>

              {/* Do not allow more than 30 details */}
              <button
                disabled={
                  this.validateDetail() || this.state.data.details.length >= 30
                }
                onClick={this.handleAddDetails}
                className="btn btn-primary submit-button"
              >
                Add detail
              </button>

              <div className="horizontal-line-container">
                <hr className="horizontal-line-primary-color" />
              </div>
              <label className={sectionHeadingTextStyle}>Tour details</label>
              {this.state.data.details.length === 0 && <p>No details</p>}
              {this.state.data.details.length > 0 && this.renderDetails()}

              <div className="horizontal-line-container">
                <hr className="horizontal-line-primary-color" />
              </div>

              <form>{this.renderEditFormSubmitButton("Save and close")}</form>
              <div className="vertical-filler-container" />
            </div>
            <ToastContainer />
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default TourDetails;
