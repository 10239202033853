import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";

// Publishable Key of Stripe goes in here (NOT The Secret Key)
const stripePromise = loadStripe(
  "pk_test_51HHIpHLNvqYz3ah2FCLC7FJY43XkhovLSaV5WBV7Qha2yehUiy82b1tvWMmweosOlEllyCLZ8ZxUTjNthsQNgoFc00zr5BidVj"
);

const Checkout = () => {
  const [checkoutSessionId, setCheckoutSessionId] = useState("");

  const handleSessionIdUpdate = ({ target }) => {
    setCheckoutSessionId(target.value);
    console.log(checkoutSessionId);
  };

  const handleCheckout = async (event) => {
    // Get Stripe.js instance
    const stripe = await stripePromise;

    //const sessionId = getCheckoutSessionId(); // In real world we may call service but here just use the one supplied
    const sessionId = checkoutSessionId;
    console.log("Using checkout session id: ", checkoutSessionId);

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: sessionId,
    });

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
      console.log(result.error.message);
    }
  };

  return (
    <React.Fragment>
      <label>"Enter Checkout Session ID"</label>
      <input
        id="CheckoutSessionId"
        name="CheckoutSessionId"
        value=""
        onChange={handleSessionIdUpdate}
      />
      <button role="link" onClick={handleCheckout}>
        Checkout
      </button>
    </React.Fragment>
  );
};

export default Checkout;
