import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import "../../../../css/edit.css";
import "../../../../css/operator.css";
import "../../../../css/ovroomFormPage.css";

class OperatorViewTourBasicInfo extends Component {
  render() {
    const { tour } = this.props;

    return (
      <React.Fragment>
        {tour && (
          <div className="card shadow p-3 mb-5 bg-white rounded">
            <div className="container card-body">
              <div className="view-title-container">
                <label className="card-title view-title-text">
                  Tour basic info
                </label>
                <div className="edit-button-with-warning-container">
                  <Link
                    className="btn btn-primary view-title-button-text"
                    to={{
                      pathname: "/operator/tours/tour-basic-info",
                      state: { tour },
                    }}
                  >
                    <i className="bi bi-pencil-square"></i> Edit
                  </Link>
                  <label className="view-item-name-text">
                    (Requires review)
                  </label>
                </div>
              </div>

              <div className="view-content-container">
                <label className="view-item-name-text">Tour title</label>
                <p className="view-item-content-text">{tour.tourTitle}</p>

                <label className="view-item-name-text">Place text</label>
                <p className="view-item-content-text">{tour.placeText}</p>

                <label className="view-item-name-text">Place tags</label>
                <p className="view-item-content-text">
                  {tour.placeTagsArray.join(", ")}
                </p>

                <label className="view-item-name-text">Tour type</label>
                <p className="view-item-content-text">{tour.tourType}</p>

                <label className="view-item-name-text">Start address</label>
                <p className="view-item-content-text">
                  {tour.startAddress.street}, {tour.startAddress.city},{" "}
                  {tour.startAddress.state} {tour.startAddress.postalCode},{" "}
                  {tour.startAddress.country}
                </p>

                <label className="view-item-name-text">End address</label>
                <p className="view-item-content-text">
                  {tour.endAddress.street}, {tour.endAddress.city},{" "}
                  {tour.endAddress.state} {tour.endAddress.postalCode},{" "}
                  {tour.endAddress.country}
                </p>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

OperatorViewTourBasicInfo.propTypes = {
  tour: PropTypes.object.isRequired,
};

export default OperatorViewTourBasicInfo;
