import React from "react";

import deviceConfig from "./common/deviceConfig";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Section3Card from "./section3Card";
import "../css/section3.css";

// The Carousel used in this code is "react-multi-carousel" installed as a separate NPM package.

const Section3 = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
    },
  };

  let containerClass = "container section3-container";
  //let partialMargin = "";
  if (deviceConfig.isMobilePhone) {
    //partialMargin = `itemClass="carousel-item-margin-40-px"`;
    containerClass += " section3-container-mobile";
  }

  return (
    <div className={containerClass}>
      <label className="section3-heading">Explore our world</label>
      {/* The Carousel used in this code is "react-multi-carousel" installed as a separate NPM package. */}
      <Carousel
        swipeable={true}
        draggable={true}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={false}
        keyBoardControl={true}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        itemClass="carousel-item-margin-10-px"
        partialVisible={deviceConfig.isMobilePhone}
        showDots={deviceConfig.isMobilePhone}
        minimumTouchDrag={0}
        dotListClass="section3-dotlist-class"
      >
        <div>
          <Section3Card
            url="/images/NationalParks.jpg"
            title="National parks"
          />
        </div>
        <div>
          <Section3Card url="/images/Safari.jpg" title="Safari" />
        </div>
        <div>
          <Section3Card url="/images/CityScape.jpg" title="City scapes" />
        </div>
        <div>
          <Section3Card url="/images/FamilyFun.jpg" title="Family fun" />
        </div>
      </Carousel>
    </div>
  );
};

export default Section3;
