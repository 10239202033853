import React from "react";
import Joi from "joi";
import { ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { showToast } from "../../../../utils/toast";
import * as authService from "../../../../services/authService";
import * as operatorTourService from "../../../../services/operatorTourService";
import Form from "../../../common/form";
import "../../../../css/ovroomFormPage.css";
import "../../../../css/edit.css";
import "../../../../css/createTourUnavailableDates.css";

import { assetsURL, logoImageName } from "../../../../config.json";

import UserContext from "../../../../context/userContext";

class TourUnavailableDates extends Form {
  isMobile = window.innerWidth < 480;

  // Context used
  static contextType = UserContext;

  disableEnddate = true;

  state = {
    data: {
      unavailableDates: [],
    },
    errors: {},
    addLoading: false,
    startDateForServer: "",
    endDateForServer: "",
    startDateString: "",
    endDateString: "",
    startDate: "",
    endDate: "",
    changed: true,
  };

  schema = Joi.object({
    unavailableDates: Joi.array(),
  });

  // Check if the given date has conflict with already scheduled unavailable dates
  dateConflict = (date) => {
    let conflict = false;
    this.state.data.unavailableDates.forEach((u) => {
      const minDate = new Date(u.startDateForServer);
      const maxDate = new Date(u.endDateForServer);

      if (date >= minDate && date <= maxDate) {
        conflict = true;
      }
    });
    return conflict;
  };

  onChangeStartDate = (date) => {
    if (!date) return;
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const day = date.getDate().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const stringDate = `${year}-${month}-${day}`;
    let d = new Date(stringDate);

    if (this.dateConflict(d)) {
      showToast("Date conflict", "error");

      return;
    }
    const displayString = `${month}/${day}/${year}`;

    this.setState({
      startDateForServer: stringDate,
      startDate: date,
      startDateString: displayString,
    });
    this.disableEnddate = false; // Allow end date entry
  };

  onChangeEndDate = (date) => {
    if (!date) return;
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const day = date.getDate().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const stringDate = `${year}-${month}-${day}`;
    let d = new Date(stringDate);
    if (this.dateConflict(d)) {
      showToast("Date conflict", "error");
      return;
    }
    const displayString = `${month}/${day}/${year}`;
    this.setState({
      endDateForServer: stringDate,
      endDate: date,
      endDateString: displayString,
    });
  };

  onDateAdd = async () => {
    // If the endDate is not specified, copy the endDate to be the same as the startDate

    let startDateForServer = this.state.startDateForServer;
    let endDateForServer =
      this.state.endDate !== ""
        ? this.state.endDateForServer
        : this.state.startDateForServer;

    // First update the server and see if it is a success
    let response;
    let { tour } = this.props.location.state;
    try {
      this.setState({ addLoading: true });
      response = await operatorTourService.addUnavailableDate(
        tour._id,
        startDateForServer,
        endDateForServer
      );
    } catch (e) {
    } finally {
      this.setState({ addLoading: false });
    }
    if (response && response.status === 200) {
      let r_ud = response.data;
      const ud = {
        id: r_ud._id, // arbitrary ID for the element
        startDate: this.state.startDateString,
        endDate:
          this.state.endDate !== ""
            ? this.state.endDateString
            : this.state.startDateString,
        startDateForServer: startDateForServer,
        endDateForServer: endDateForServer,
      };

      let unavailableDates = [...this.state.data.unavailableDates];
      unavailableDates.push(ud);
      this.disableEnddate = true;
      this.setState({
        data: { unavailableDates: unavailableDates },
        startDate: "",
        endDate: "",
      });
    }
  };

  doCancel = () => {
    this.props.history.goBack();
  };

  doSubmit = async () => {
    // let unavailableDates = this.state.data.unavailableDates.map((u) => {
    //   return { startDate: u.startDateForServer, endDate: u.endDateForServer };
    // });

    // Server is already updated
    // Return
    this.props.history.goBack();
  };

  handleUnavailableDateDeleted = async (unavailableDate) => {
    let udArray = this.state.data.unavailableDates.filter(
      (u) => u.id !== unavailableDate.id
    );
    this.setState({ data: { unavailableDates: udArray } });

    // Remove the date from the server
    //let response;
    let { tour } = this.props.location.state;
    try {
      //this.setState({ addLoading: true });
      await operatorTourService.removeUnavailableDate(
        tour._id,
        unavailableDate.id
      );
    } catch (e) {
    } finally {
      //this.setState({ addLoading: false });
    }
  };

  getStringFromDate = (inputDate) => {
    let date = new Date(inputDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const day = date.getDate().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const stringDate = `${year}-${month}-${day}`;
    return stringDate;
  };

  getDisplayStringFromDate = (inputDate) => {
    let date = new Date(inputDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const day = date.getDate().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const stringDate = `${year}/${month}/${day}`;
    return stringDate;
  };

  renderUnavailableDates = () => {
    let udArray = [...this.state.data.unavailableDates];
    return (
      <React.Fragment>
        {udArray.map((u) => {
          return (
            <div className="unavailable-dates-and-delete-container" key={u.id}>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => this.handleUnavailableDateDeleted(u)}
              >
                Remove
              </button>
              <div>
                <div>
                  {!(u.startDate === u.endDate) && (
                    <label>
                      {u.startDate} <em className="view-emphasis-text">to</em>
                    </label>
                  )}
                  {u.startDate === u.endDate && <label>{u.startDate}</label>}
                </div>
                {!(u.startDate === u.endDate) && (
                  <div>
                    <label>{u.endDate}</label>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  componentDidMount() {
    let { unavailableDates } = this.props.location.state.tour;

    unavailableDates = unavailableDates.map((u) => {
      const startDate = this.getDisplayStringFromDate(u.startTimestamp);
      const endDate = this.getDisplayStringFromDate(u.endTimestamp);
      const startDateForServer = this.getStringFromDate(u.startTimestamp);
      const endDateForServer = this.getStringFromDate(u.endTimestamp);
      return {
        id: u._id,
        startDate,
        endDate,
        startDateForServer,
        endDateForServer,
      };
    });

    this.setState({ data: { unavailableDates: [...unavailableDates] } });
  }

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      //localStorage.setItem("UrlBeforeLogin", this.props.location.pathname);
      this.props.history.replace("/login");
      return null;
    }

    let containerClass,
      headerContainer,
      formContainer,
      logoImage,
      pageHeadingTextStyle,
      instructionsContainer,
      sectionHeadingTextStyle;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";
      headerContainer = "ovroom-form-header-container-mobile";
      instructionsContainer = "ovroom-form-instruction-container-option1";
      formContainer = "ovroom-form-elements-container-option1";
      sectionHeadingTextStyle = "ovroom-form-section-heading-mobile";
      logoImage = "logo-image-mobile";
      pageHeadingTextStyle = "ovroom-form-page-heading-mobile";
    } else {
      // desktop and tablet

      containerClass = "container ovroom-form-container";
      headerContainer = "ovroom-form-header-container";
      instructionsContainer = "ovroom-form-instruction-container-option1";
      sectionHeadingTextStyle = "ovroom-form-section-heading";
      formContainer = "ovroom-form-elements-container-option1";
      logoImage = "logo-image";
      pageHeadingTextStyle = "ovroom-form-page-heading";
    }

    const { unavailableDates } = this.state.data;
    const { startDate, endDate } = this.state;

    const minStartDate = new Date();
    let maxStartDate = new Date();
    maxStartDate = new Date(
      maxStartDate.setMonth(maxStartDate.getMonth() + 12)
    );

    const minEndDate = startDate && startDate !== "" ? startDate : minStartDate;
    const maxEndtDate = maxStartDate;

    return (
      <UserContext.Consumer>
        {(userContext) => (
          <div className={containerClass}>
            <div className={headerContainer}>
              <img
                className={logoImage}
                src={assetsURL + logoImageName}
                alt="Ovroom logo"
              />
              <p className={pageHeadingTextStyle}>Unavailable Dates</p>
            </div>
            <div className={instructionsContainer}>
              If you know the dates when tours cannot be conducted, enter those
              dates below by selecting start and end dates. We will not reserve
              tours for such unavailable dates.
            </div>
            <div className={formContainer}>
              <div className="unavailable-date-picker-container">
                <DatePicker
                  className="ovroom-date-picker-container-style"
                  selected={startDate}
                  onChange={(date) => this.onChangeStartDate(date)}
                  placeholderText="Start date"
                  minDate={minStartDate}
                  maxDate={maxStartDate}
                />

                <DatePicker
                  className="ovroom-date-picker-container-style"
                  selected={endDate}
                  onChange={(date) => this.onChangeEndDate(date)}
                  placeholderText="End date"
                  minDate={minEndDate}
                  maxDate={maxEndtDate}
                  disabled={this.disableEnddate}
                />
              </div>

              {/* Do not allow more than 30 unavailable date ranges */}
              <button
                disabled={
                  this.state.startDate === "" ||
                  this.state.data.unavailableDates.length >= 30
                }
                onClick={this.onDateAdd}
                className="btn btn-primary submit-button"
              >
                {this.state.addLoading && (
                  <span
                    className="spinner-border spinner-border-sm m-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Add unavailable date
              </button>

              <div className="horizontal-line-container">
                <hr className="horizontal-line-primary-color" />
              </div>
              <label className={sectionHeadingTextStyle}>
                Unavailable dates
              </label>
              {unavailableDates.length === 0 && <p>No unavailable dates</p>}
              {unavailableDates.length > 0 && this.renderUnavailableDates()}

              <div className="horizontal-line-container">
                <hr className="horizontal-line-primary-color" />
              </div>

              <form>{this.renderCloseButton("Close")}</form>
              <div className="vertical-filler-container" />
            </div>
            <ToastContainer />
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default TourUnavailableDates;
