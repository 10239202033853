import React from "react";
//import { useMediaQuery } from "react-responsive";

import deviceConfig from "./common/deviceConfig";
// import http from "../services/httpService";
// import config from "../config.json";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "../css/section4.css";

function Section4({ onHandleSignUpTourOperator }) {
  // const isMobilePhone = useMediaQuery({
  //   query: "(max-device-width: 464px)",
  // });

  let containerClass = "container section4-container";
  let gridClass = "section4-grid";
  let textContainerClass = "section4-text-container";
  let textClass = "section4-text";
  let buttonClass = "section4-button-box clickable";
  if (deviceConfig.isMobilePhone) {
    containerClass += " section4-container-mobile";
    gridClass += " section4-grid-mobile";
    textContainerClass += " section4-text-mobile-container";
    textClass += " section4-text-mobile";
    buttonClass += " section4-button-box-mobile";
  }

  return (
    <div className={containerClass}>
      <ToastContainer />
      <div className={gridClass}>
        <div className={textContainerClass}>
          <p className={textClass}>Share your world</p>
          <p className={textClass}>with others...</p>
        </div>
        <div className={buttonClass} onClick={onHandleSignUpTourOperator}>
          <label className="section4-button-text clickable">
            Become a tour operator
          </label>
        </div>
      </div>
    </div>
  );
}

export default Section4;
