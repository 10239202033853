import React, { Component } from "react";

import "../../css/search.css";

class SearchTour extends Component {
  render() {
    return (
      <div className="search-box">
        <i className="fas fa-search search-icon"></i>
        <label className="search-text">Where would you go?</label>
      </div>
    );
  }
}

export default SearchTour;
