import React from "react";
import Joi from "joi";
import { ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { showToast } from "../../../../utils/toast";

import * as authService from "../../../../services/authService";
import Form from "../../../common/form";
import "../../../../css/ovroomFormPage.css";
import "../../../../css/createTourUnavailableDates.css";

import { assetsURL, logoImageName } from "../../../../config.json";

import UserContext from "../../../../context/userContext";

class CreateTourUnavailableDates extends Form {
  isMobile = window.innerWidth < 480;

  // Context used
  static contextType = UserContext;

  disableEnddate = true;

  state = {
    data: {
      unavailableDates: [],
    },
    errors: {},
    loading: false,
    startDateForServer: "",
    endDateForServer: "",
    startDateString: "",
    endDateString: "",
    startDate: "",
    endDate: "",
  };

  schema = Joi.object({
    unavailableDates: Joi.array(),
  });

  // Check if the given date has conflict with already scheduled unavailable dates
  dateConflic = (date) => {
    let conflict = false;
    this.state.data.unavailableDates.forEach((u) => {
      const minDate = new Date(u.startDateForServer);
      const maxDate = new Date(u.endDateForServer);

      if (date >= minDate && date <= maxDate) {
        conflict = true;
      }
    });
    return conflict;
  };

  onChangeStartDate = (date) => {
    if (!date) return;
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const day = date.getDate().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const stringDate = `${year}-${month}-${day}`;
    let d = new Date(stringDate);
    if (this.dateConflic(d)) {
      showToast("Date conflic", "error");

      return;
    }
    const displayString = `${month}/${day}/${year}`;

    this.setState({
      startDateForServer: stringDate,
      startDate: date,
      startDateString: displayString,
    });
    this.disableEnddate = false; // Allow end date entry
  };

  onChangeEndDate = (date) => {
    if (!date) return;
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const day = date.getDate().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const stringDate = `${year}-${month}-${day}`;
    let d = new Date(stringDate);
    if (this.dateConflic(d)) {
      showToast("Date conflic", "Error");
      return;
    }
    const displayString = `${month}/${day}/${year}`;
    this.setState({
      endDateForServer: stringDate,
      endDate: date,
      endDateString: displayString,
    });
  };

  onDateAdd = () => {
    // If the endDate is not specified, copy the endDate to be the same as the startDate
    const ud = {
      id: Date.now(), // arbitrary ID for the element
      startDate: this.state.startDateString,
      endDate:
        this.state.endDate !== ""
          ? this.state.endDateString
          : this.state.startDateString,
      startDateForServer: this.state.startDateForServer,
      endDateForServer:
        this.state.endDate !== ""
          ? this.state.endDateForServer
          : this.state.startDateForServer,
    };

    let unavailableDates = [...this.state.data.unavailableDates];
    unavailableDates.push(ud);
    this.disableEnddate = true;
    this.setState({
      data: { unavailableDates: unavailableDates },
      startDate: "",
      endDate: "",
    });
  };

  doSubmit = async () => {
    const { data } = this.state;
    let unavailableDates = data.unavailableDates.map((u) => {
      return { startDate: u.startDateForServer, endDate: u.endDateForServer };
    });
    localStorage.setItem(
      "TourUnavailableDates",
      JSON.stringify(unavailableDates)
    );
    this.props.history.push("/operator/create-tour-laststartdate");
  };

  handleUnavailableDateDeleted = (unavailableDate) => {
    let udArray = this.state.data.unavailableDates.filter(
      (u) => u.id !== unavailableDate.id
    );
    this.setState({ data: { unavailableDates: udArray } });
  };

  renderUnavailableDates = () => {
    let udArray = [...this.state.data.unavailableDates];
    return (
      <React.Fragment>
        {udArray.map((u) => {
          return (
            <div className="unavailable-dates-and-delete-container" key={u.id}>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => this.handleUnavailableDateDeleted(u)}
              >
                Remove
              </button>
              <div>
                <div>
                  {!(u.startDate === u.endDate) && (
                    <label>{u.startDate} to</label>
                  )}
                  {u.startDate === u.endDate && <label>{u.startDate}</label>}
                </div>
                {!(u.startDate === u.endDate) && (
                  <div>
                    <label>{u.endDate}</label>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      //localStorage.setItem("UrlBeforeLogin", this.props.location.pathname);
      this.props.history.replace("/login");
      return null;
    }

    let containerClass,
      headerContainer,
      formContainer,
      logoImage,
      pageHeadingTextStyle,
      instructionsContainer,
      sectionHeadingTextStyle;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";
      headerContainer = "ovroom-form-header-container-mobile";
      instructionsContainer = "ovroom-form-instruction-container-option1";
      formContainer = "ovroom-form-elements-container-option1";
      sectionHeadingTextStyle = "ovroom-form-section-heading-mobile";
      logoImage = "logo-image-mobile";
      pageHeadingTextStyle = "ovroom-form-page-heading-mobile";
    } else {
      // desktop and tablet

      containerClass = "container ovroom-form-container";
      headerContainer = "ovroom-form-header-container";
      instructionsContainer = "ovroom-form-instruction-container-option1";
      sectionHeadingTextStyle = "ovroom-form-section-heading";
      formContainer = "ovroom-form-elements-container-option1";
      logoImage = "logo-image";
      pageHeadingTextStyle = "ovroom-form-page-heading";
    }

    const { unavailableDates } = this.state.data;
    const { startDate, endDate } = this.state;

    const minStartDate = new Date();
    let maxStartDate = new Date();
    maxStartDate = new Date(
      maxStartDate.setMonth(maxStartDate.getMonth() + 12)
    );

    const minEndDate = startDate && startDate !== "" ? startDate : minStartDate;
    const maxEndtDate = maxStartDate;

    return (
      <UserContext.Consumer>
        {(userContext) => (
          <div className={containerClass}>
            <div className={headerContainer}>
              <img
                className={logoImage}
                src={assetsURL + logoImageName}
                alt="Ovroom logo"
              />
              <p className={pageHeadingTextStyle}>Unavailable Dates</p>
            </div>
            <div className={instructionsContainer}>
              <b>Optional.</b> If you know the dates when tours cannot be
              conducted, enter those dates below by selecting start and end
              dates. We will not reserve tours for such unavailable dates.
            </div>
            <div className={formContainer}>
              <div className="unavailable-date-picker-container">
                <DatePicker
                  className="ovroom-date-picker-container-style"
                  selected={startDate}
                  onChange={(date) => this.onChangeStartDate(date)}
                  placeholderText="Start date"
                  minDate={minStartDate}
                  maxDate={maxStartDate}
                />

                <DatePicker
                  className="ovroom-date-picker-container-style"
                  selected={endDate}
                  onChange={(date) => this.onChangeEndDate(date)}
                  placeholderText="End date"
                  minDate={minEndDate}
                  maxDate={maxEndtDate}
                  disabled={this.disableEnddate}
                />
              </div>

              {/* Do not allow more than 30 unavailable date ranges */}
              <button
                disabled={
                  this.state.startDate === "" ||
                  this.state.data.unavailableDates.length >= 30
                }
                onClick={this.onDateAdd}
                className="btn btn-primary submit-button"
              >
                Add unavailable date
              </button>

              <div className="horizontal-line-container">
                <hr className="horizontal-line-primary-color" />
              </div>
              <label className={sectionHeadingTextStyle}>
                Unavailable dates
              </label>
              {unavailableDates.length === 0 && <p>No unavailable dates</p>}
              {unavailableDates.length > 0 && this.renderUnavailableDates()}

              <div className="horizontal-line-container">
                <hr className="horizontal-line-primary-color" />
              </div>

              <form>{this.renderSubmitButton("Save and next")}</form>
              <div className="vertical-filler-container" />
            </div>
            <ToastContainer />
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default CreateTourUnavailableDates;
