import React, { Component } from "react";

import CreateTourPriceSinglePriceForm from "./createTourPriceSinglePriceForm";
import CreateTourPriceMonthlyPriceForm from "./createTourPriceMonthlyPriceForm";

import { ToastContainer } from "react-toastify";

import * as authService from "../../../../services/authService";
import "../../../../css/ovroomFormPage.css";

import { assetsURL, logoImageName } from "../../../../config.json";

class CreateTourPrice extends Component {
  isMobile = window.innerWidth < 480;

  state = { singlePriceSelected: true, currencies: [] };

  handleRadioButtonClicked = ({ currentTarget: input }) => {
    if (input.id === "singleprice")
      this.setState({ singlePriceSelected: input.checked });
    else this.setState({ singlePriceSelected: !input.checked });
  };

  doSubmit = (prices) => {
    localStorage.setItem("TourPrice", JSON.stringify(prices));
    this.props.history.push("/operator/create-tour-schedule");
  };

  componentDidMount() {
    let countries = JSON.parse(localStorage.getItem("Countries"));
    const currencies = countries.map((c) => {
      return {
        _id: c._id,
        name: `${c.name} (${c.currency})`,
        currency: c.currency,
        isoCode: c.countryISOCode,
      };
    });
    this.setState({ currencies: currencies });
  }

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      //localStorage.setItem("UrlBeforeLogin", this.props.location.pathname);
      this.props.history.replace("/login");
      return null;
    }

    let containerClass, headerContainer, logoImage, pageHeadingTextStyle;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";
      headerContainer = "ovroom-form-header-container-mobile";
      pageHeadingTextStyle = "ovroom-form-page-heading-mobile";
      logoImage = "logo-image-mobile";
    } else {
      // desktop and tablet
      containerClass = "container ovroom-form-container";
      headerContainer = "ovroom-form-header-container";
      pageHeadingTextStyle = "ovroom-form-page-heading";
      logoImage = "logo-image";
    }
    return (
      <div className={containerClass}>
        <div className={headerContainer}>
          <img
            className={logoImage}
            src={assetsURL + logoImageName}
            alt="Ovroom logo"
          />
          <p className={pageHeadingTextStyle}>Tour Price</p>
        </div>

        <div
          className="btn-group ovroom-form-elements-container-option3"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="singleprice"
            autoComplete="off"
            checked={this.state.singlePriceSelected}
            onChange={this.handleRadioButtonClicked}
          />
          <label className="btn btn-outline-secondary" htmlFor="singleprice">
            Single price
          </label>

          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="monthlyprice"
            autoComplete="off"
            checked={!this.state.singlePriceSelected}
            onChange={this.handleRadioButtonClicked}
          />
          <label className="btn btn-outline-secondary" htmlFor="monthlyprice">
            Monthly price
          </label>
        </div>

        <div className="ovroom-form-instructions-container-option3">
          {this.state.singlePriceSelected && (
            <p>
              To charge the same price throughout the year, use Single Price. To
              charge different prices in different months, use Monthly Price.
            </p>
          )}
          {!this.state.singlePriceSelected && (
            <p>
              To charge different prices in different months, use Monthly Price.
              Please enter price for every month of the year below. Ovroom
              automatically applies this price schedule when a tour is reserved.
            </p>
          )}
        </div>

        <div className="ovroom-form-container-option3">
          {this.state.singlePriceSelected && (
            <CreateTourPriceSinglePriceForm
              onSubmitForm={this.doSubmit}
              currencies={this.state.currencies}
            />
          )}
          {!this.state.singlePriceSelected && (
            <CreateTourPriceMonthlyPriceForm
              onSubmitForm={this.doSubmit}
              currencies={this.state.currencies}
            />
          )}
        </div>
        <div className="vertical-filler-container" />
        <ToastContainer />
      </div>
    );
  }
}

export default CreateTourPrice;
