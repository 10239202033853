import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import "../../../../css/edit.css";
import "../../../../css/operator.css";
import "../../../../css/ovroomFormPage.css";

class OperatorViewResStatus extends Component {
  render() {
    const { reservation } = this.props;

    if (reservation)
      return (
        <React.Fragment>
          {reservation && (
            <div className="card shadow p-3 mb-5 bg-white rounded">
              <div className="container card-body">
                <div className="view-title-container">
                  <label className="card-title view-title-text">Status</label>
                </div>

                {/* "_id price calculatedAmounts duration cancellationPolicy paymentStatus refundStatus refundReason fulfillmentStatus debitStatus disputeStatus checkoutStarted createdAt lastUpdatedAt tour tourist confirmation numberOfAdults numberOfChildren createdBy" */}

                <div className="view-content-container">
                  <label className="view-item-name-text">Payment status</label>
                  <p className="view-item-content-text">
                    {reservation.paymentStatus}
                  </p>

                  <label className="view-item-name-text">
                    Fulfillment status
                  </label>
                  <p className="view-item-content-text">
                    {reservation.fulfillmentStatus}
                  </p>

                  <label className="view-item-name-text">Refund status</label>
                  <p className="view-item-content-text">
                    {reservation.refundStatus}
                  </p>

                  <label className="view-item-name-text">Refund reason</label>
                  <p className="view-item-content-text">
                    {reservation.refundReason}
                  </p>

                  <label className="view-item-name-text">Debit status</label>
                  <p className="view-item-content-text">
                    {reservation.debitStatus}
                  </p>

                  <label className="view-item-name-text">Dispute status</label>
                  <p className="view-item-content-text">
                    {reservation.disputeStatus}
                  </p>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      );
  }
}

OperatorViewResStatus.propTypes = {
  reservation: PropTypes.object.isRequired,
};

export default OperatorViewResStatus;
