import React, { Component } from "react";
import _ from "lodash";

import OrgTable from "./orgTable";
import Pagination from "../common/pagination";
import ListGroup from "../common/listGroup";
import { paginate } from "../../utils/paginate";

/////// Delete Start /////////////
import {
  getOrgs,
  //getPendingPublicationApprovalOrgs,
  //getPendingFinanceApprovalOrgs,
} from "../../mosh/services/fakeOrgsService";
///// Delete End //////////

class Organizations extends Component {
  state = {
    organizations: [],
    pageSize: 4,
    currentPage: 1,
    filterIndex: 0,
    mainMenuIndex: 1,
    sortColumn: { path: "name", order: "asc" },
  };

  menuItems = ["Messages", "Organizations", "Finance", "Tours"];
  filters = ["All", "Publication Approved", "Finance Approved"];

  // State hooks
  componentDidMount() {
    let orgs = getOrgs();
    this.setState({ organizations: orgs });
  }

  handleDelete = (org) => {
    let orgs = this.state.organizations.filter((o) => o._id !== org._id);
    this.setState({ organizations: orgs });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleFilterSelect = (filter) => {
    const index = this.filters.indexOf(filter);
    this.setState({ filterIndex: index, currentPage: 1 });
  };

  handleMenuSelect = (item) => {
    const index = this.menuItems.indexOf(item);
    this.setState({ mainMenuIndex: index });
  };

  handleSort = (sortColumn) => {
    this.setState(sortColumn);
  };

  _getPagedData = () => {
    const {
      pageSize,
      currentPage,
      organizations: allOrganizations,
      filterIndex,
      sortColumn,
    } = this.state;

    let filtered =
      filterIndex === 0
        ? allOrganizations
        : _getFilteredOrgs(allOrganizations, this.filters[filterIndex]);
    const sorted = _.orderBy(
      filtered,
      [sortColumn.path, "name"],
      [sortColumn.order, "asc"]
    );

    let rval = {
      data: paginate(sorted, currentPage, pageSize),
      count: filtered.length,
    };
    return rval;
  };

  render() {
    const { pageSize, currentPage, filterIndex, mainMenuIndex } = this.state;
    const { data: organizations, count } = this._getPagedData();

    return (
      <div className="row">
        <div className="col-3">
          <ListGroup
            items={this.menuItems}
            selectedItemIndex={mainMenuIndex}
            onItemSelect={this.handleMenuSelect}
            margin={1}
          />
        </div>
        <div className="col">
          <div className="row">
            <ListGroup
              title={"Filter by:"}
              items={this.filters}
              selectedItemIndex={filterIndex}
              onItemSelect={this.handleFilterSelect}
              horizontal={true}
              margin={1}
            />
          </div>
          <div className="row">
            {count === 0 && <p>There are no organizations.</p>}
            {count > 0 && (
              <React.Fragment>
                <p>Showing {count} organizations.</p>

                <OrgTable
                  organizations={organizations}
                  sortColumn={this.state.sortColumn}
                  onDelete={this.handleDelete}
                  onSort={this.handleSort}
                />

                <Pagination
                  itemsCount={count}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  onPageChange={this.handlePageChange}
                />
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

//////////////////////////////////////////////////////////////
// Support functions
//////////////////////////////////////////////////////////////
function _getFilteredOrgs(inOrgs, filter) {
  switch (filter) {
    case "Publication Approved":
      return inOrgs.filter((o) => o.isPublicationApproved === true);

    case "Finance Approved":
      return inOrgs.filter((o) => o.isFinanceApproved === true);

    default:
      return inOrgs;
  }
}

export default Organizations;
