import React from "react";
import Joi from "joi";
import { ToastContainer } from "react-toastify";

import * as authService from "../../../../services/authService";
import Form from "../../../common/form";
import "../../../../css/ovroomFormPage.css";
import "../../../../css/createTourDetails.css";

import { assetsURL, logoImageName } from "../../../../config.json";

import UserContext from "../../../../context/userContext";

class CreateTourHighlights extends Form {
  isMobile = window.innerWidth < 480;

  // Context used
  static contextType = UserContext;

  state = {
    data: {
      highlights: [],
    },
    optionalData: {
      description: "",
    },
    errors: {},
    loading: false,
  };

  optionalDataSchema = Joi.object({
    description: Joi.string()
      .trim()
      .min(2)
      .max(100)
      .required()
      .label("Highlight"),
  });

  schema = Joi.object({
    highlights: Joi.array(),
  });

  validateOptionalDataProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const rule = this.optionalDataSchema.extract(name);
    const schema = Joi.object({ [name]: rule });
    const { error } = schema.validate(obj);
    return error ? error.details[0].message : null;
  };

  validateAllOptionalData = () => {
    const { optionalData } = this.state;
    const { error } = this.optionalDataSchema.validate(optionalData, {
      abortEarly: false,
    });
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  handleChangeOfHighlights = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };

    let errorMessage;

    errorMessage = this.validateOptionalDataProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name]; // If no errors, remove any existing errors for this field, if any.

    let optionalData = { ...this.state.optionalData };
    optionalData[input.name] = input.value;
    this.setState({ optionalData, errors });
  };

  handleAddHighlight = () => {
    let highlights = [...this.state.data.highlights];
    let optionalData = { ...this.state.optionalData };
    let highlight = {
      id: new Date(),
      title: optionalData.title,
      description: optionalData.description,
    };
    highlights.push(highlight);

    // Reset optional data
    optionalData.description = "";

    this.setState({ data: { highlights }, optionalData });
  };

  doSubmit = async () => {
    const { data } = this.state;
    let highlights = data.highlights.map((u) => {
      return { description: u.description };
    });
    localStorage.setItem("TourHighlights", JSON.stringify(highlights));

    // go to submit tour
    this.props.history.push("/operator/create-tour-submit");
  };

  handleDeleteHighlight = (highlight) => {
    let highlights = this.state.data.highlights.filter(
      (u) => u.id !== highlight.id
    );
    this.setState({ data: { highlights } });
  };

  renderHighlights = () => {
    const { highlights } = this.state.data;

    return (
      <React.Fragment>
        {highlights.map((u) => {
          return (
            <div className="existing-detail-container" key={u.id}>
              <div className="existing-detail-removebutton-container">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => this.handleDeleteHighlight(u)}
                >
                  Remove
                </button>
              </div>
              <div>
                <div>
                  <p>{u.description}</p>
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  render() {
    const { optionalData } = this.state;
    const { errors } = this.state;

    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      //localStorage.setItem("UrlBeforeLogin", this.props.location.pathname);
      this.props.history.replace("/login");
      return null;
    }

    let containerClass,
      headerContainer,
      formContainer,
      logoImage,
      pageHeadingTextStyle,
      instructionsContainer,
      sectionHeadingTextStyle;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";
      headerContainer = "ovroom-form-header-container-mobile";
      instructionsContainer = "ovroom-form-instruction-container-option1";
      formContainer = "ovroom-form-elements-container-option1";
      sectionHeadingTextStyle = "ovroom-form-section-heading-mobile";
      logoImage = "logo-image-mobile";
      pageHeadingTextStyle = "ovroom-form-page-heading-mobile";
    } else {
      // desktop and tablet

      containerClass = "container ovroom-form-container";
      headerContainer = "ovroom-form-header-container";
      instructionsContainer = "ovroom-form-instruction-container-option1";
      sectionHeadingTextStyle = "ovroom-form-section-heading";
      formContainer = "ovroom-form-elements-container-option1";
      logoImage = "logo-image";
      pageHeadingTextStyle = "ovroom-form-page-heading";
    }

    return (
      <UserContext.Consumer>
        {(userContext) => (
          <div className={containerClass}>
            <div className={headerContainer}>
              <img
                className={logoImage}
                src={assetsURL + logoImageName}
                alt="Ovroom logo"
              />
              <p className={pageHeadingTextStyle}>Tour Highlights</p>
            </div>
            <div className={instructionsContainer}>
              <b>Optional.</b> If you would like to add highlights of the tour,
              please add upto 3 highlights. Describe each highlight in brief
              (upto 100 characters each).
            </div>
            <div className={formContainer}>
              <div className="new-detail-container">
                <div className="form-group input-form-group">
                  <label className="input-form-label" htmlFor="description">
                    Highlight
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    value={optionalData["description"]}
                    error={errors["description"]}
                    type="text"
                    rows={2}
                    onChange={this.handleChangeOfHighlights}
                    className="form-control input-form"
                  />
                  {errors["description"] && (
                    <div className="alert alert-danger">
                      {errors["description"]}
                    </div>
                  )}
                </div>
              </div>

              {/* Do not allow more than 3 highlights */}
              <button
                disabled={
                  this.validateAllOptionalData() ||
                  this.state.data.highlights.length >= 3
                }
                onClick={this.handleAddHighlight}
                className="btn btn-primary submit-button"
              >
                Add highlight
              </button>

              <div className="horizontal-line-container">
                <hr className="horizontal-line-primary-color" />
              </div>
              <label className={sectionHeadingTextStyle}>Tour highlights</label>
              {this.state.data.highlights.length === 0 && <p>No highlights</p>}
              {this.state.data.highlights.length > 0 && this.renderHighlights()}

              <div className="horizontal-line-container">
                <hr className="horizontal-line-primary-color" />
              </div>

              <form>{this.renderSubmitButton("Save and next")}</form>
              <div className="vertical-filler-container" />
            </div>
            <ToastContainer />
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default CreateTourHighlights;
