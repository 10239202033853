import React from "react";
import Joi from "joi";

import * as authService from "../../../../services/authService";
import * as operatorService from "../../../../services/operatorService";
import Form from "../../../common/form";
import "../../../../css/ovroomFormPage.css";

class CreateTourUserScheduleForm extends Form {
  isMobile = window.innerWidth < 480;

  state = {
    data: {
      userId: "",
    },
    errors: {},
    loading: true,
    users: [],
  };

  schema = Joi.object({
    userId: Joi.string().required().label("User"),
  });

  async componentDidMount() {
    let response;
    try {
      response = await operatorService.getOrganizationUsers();
    } catch (e) {
    } finally {
      this.setState({ loading: false });
    }
    if (response && response.status === 200) {
      let users = response.data;
      users = users.map((u) => {
        return {
          _id: u.user._id,
          name: u.isOwner
            ? `${u.user.firstName} ${u.user.lastName} (Owner)`
            : `${u.user.firstName} ${u.user.lastName}`,
        };
      });
      this.setState({ users: users });
    }
  }

  doSubmit = async () => {
    const schedule = { scheduleType: "User", userId: this.state.data.userId };
    this.props.onSubmitForm(schedule); // Call the parent component for further actions
  };

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      //localStorage.setItem("UrlBeforeLogin", this.props.location.pathname);
      this.props.history.replace("/login");
      return null;
    }

    let sectionHeadingTextStyle;
    if (this.isMobile) {
      sectionHeadingTextStyle = "ovroom-form-section-heading-mobile";
    } else {
      // desktop and tablet
      sectionHeadingTextStyle = "ovroom-form-section-heading";
    }

    return (
      <React.Fragment>
        <form>
          <label className={sectionHeadingTextStyle}>
            Select user that conducts tour.
          </label>
          {this.renderSelect("userId", "User", this.state.users, true)}

          {this.renderSubmitButton("Save and next")}
        </form>
      </React.Fragment>
    );
  }
}

export default CreateTourUserScheduleForm;
