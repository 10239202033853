import React from "react";
import { assetsURL, logoImageName } from "../config.json";

import "../css/ovroomFormPage.css";

const ForgotPasswordIntentSuccess = ({ history }) => {
  const isMobile = window.innerWidth < 480;

  const doSubmit = () => {
    history.push("/");
  };

  let containerClass, pageHeaderContainerClass, headingTextClass, logoImage;
  if (isMobile) {
    containerClass = "container ovroom-form-container-mobile";

    pageHeaderContainerClass = "ovroom-form-header-container-mobile";
    headingTextClass = "ovroom-form-page-heading-mobile";
    logoImage = "logo-image-mobile";
  } else {
    containerClass = "container ovroom-form-container";

    pageHeaderContainerClass = "ovroom-form-header-container";
    headingTextClass = "ovroom-form-page-heading";
    logoImage = "logo-image";
  }

  return (
    <div className={containerClass}>
      <div className={pageHeaderContainerClass}>
        <img
          className={logoImage}
          src={assetsURL + logoImageName}
          alt="Ovroom logo"
        />
        <p className={headingTextClass}>Email Sent</p>
      </div>

      <div className="ovroom-form-instruction-container-option1">
        <p>
          We have sent you an email with a link to change your password. Please
          check your email.
        </p>
        <button
          className="btn btn-primary ovroom-form-submit-button"
          onClick={doSubmit}
        >
          Return to ovroom.com
        </button>
      </div>
    </div>
  );
};

export default ForgotPasswordIntentSuccess;
