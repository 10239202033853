export function getStringFromDate(inputDate) {
  let date = new Date(inputDate);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const day = date.getDate().toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const stringDate = `${year}-${month}-${day}`;
  return stringDate;
}
