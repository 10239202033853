import axios from "axios";
import * as authService from "./authService";
import { showToast } from "../utils/toast";

axios.interceptors.request.use(function (config) {
  config.headers["x-auth-token"] = authService.getJwt();
  return config;
});

axios.interceptors.response.use(null, (error) => {
  const { response } = error;
  if (response && response.status !== 200) {
    //Don't show error for Invalid Token, and logout user
    if (response.status === 400 && response.data === "Invalid token") {
      authService.logout();
      window.location = "/login"; // Perform complete reload of the application from the server
    } else showToast(response.data, "error");

    //showToast(response.data, "error");
  } else showToast(error.message, "error");

  return Promise.reject(error);
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
