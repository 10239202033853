import React from "react";
import { Link } from "react-router-dom";
import Joi from "joi";
import _ from "lodash";
import { ToastContainer } from "react-toastify";

import * as operatorService from "../../services/operatorService";
import * as accountService from "../../services/accountService";
import * as authService from "../../services/authService";
import * as userService from "../../services/userService";
import * as countryService from "../../services/countryService";
import Form from "../common/form";
import "../../css/registerOperator.css";
import "../../css/checkbox.css";

import { assetsURL, logoImageName } from "../../config.json";

import UserContext from "../../context/userContext";

class RegisterTourOperator extends Form {
  isMobile = window.innerWidth < 480;

  // Context used
  static contextType = UserContext;

  state = {
    data: {
      name: "",
      friendlyName: "",
      country: "", // Country selection
      phone: "",

      addressStreet: "",
      addressCity: "",
      addressState: "",
      addressPostalCode: "",

      aboutInfo: "",

      financeEmail: "",
      financePhone: "",

      supportEmail: "",
      supportPhone: "",

      agreement: "false", // The state of the checkbox indicating if user has agreed to terms
    },
    errors: {},
    countries: [],
    loading: true, // Flag to indicate if an async task is being performed. We set it to true, because we are downloading
    // the list of countries, just as soon as this form is loaded. It will be set to false in the componentDidMount
    // after the list of countries is successfully loaded.
  };

  schema = Joi.object({
    name: Joi.string().trim().min(2).max(255).required().label("Name"),
    friendlyName: Joi.string()
      .trim()
      .min(2)
      .max(255)
      .required()
      .label("Friendly name"),
    country: Joi.string().required().label("Country (Country code)"),
    phone: Joi.number().required().label("Phone number"),

    addressStreet: Joi.string()
      .trim()
      .min(2)
      .max(500)
      .required()
      .label("Street address"),
    addressCity: Joi.string().trim().min(2).max(255).required().label("City"),
    addressState: Joi.string().trim().min(2).max(255).required().label("State"),
    addressPostalCode: Joi.string()
      .trim()
      .min(2)
      .max(255)
      .required()
      .label("Postal code"),
    // Address country is already captured above

    aboutInfo: Joi.string().trim().min(0).max(3000).label("About info"),

    financeEmail: Joi.string()
      .trim()
      .email({ tlds: { allow: false } })
      .required()
      .label("Finance email"),
    financePhone: Joi.number().required().label("Finance phone number"),

    supportEmail: Joi.string()
      .trim()
      .email({ tlds: { allow: false } })
      .required()
      .label("Support email"),
    supportPhone: Joi.number().required().label("Support phone number"),

    agreement: Joi.boolean().invalid(false),
  });

  // registerOrgAndUpdateContext
  // Asynch funtion
  // Register the new organization with th server. If the server reponds
  // successfully, then update the User Context with newly registered
  // organization.
  //
  // Input: Organization object
  //
  // Regurns: Boolean
  //    true - if everything is successful
  //    false - if an error is encountered
  registerOrgAndUpdateContext = async (organization) => {
    let response;
    try {
      this.setState({ loading: true });
      response = await operatorService.registerOperator(organization);
    } catch (e) {
    } finally {
      this.setState({ loading: false });
    }
    // Check if the response is success
    if (response && response.status === 200) {
      // Extract Org Id, name, friendly name from the reponse
      const { _id, name, friendlyName } = response.data;

      // Update the UserContext with the new org info
      this.context.onOrgRegistered(
        _id,
        name,
        friendlyName,
        this.state.data.financeEmail,
        this.state.data.supportEmail
      );
      return true;
    } else return false;
  };

  // getUserAndUpdateContext
  // Asynch funtion
  // Retrieve updated User info from the server. If the server reponds
  // successfully, update the User Context with new user info.
  //
  // Input: none
  //
  // Regurns:
  //    user object - (response.data) of the response received with successful server inquiry
  //    undefined - if an error is encountered
  getUserAndUpdateContext = async () => {
    let response;
    try {
      this.setState({ loading: true });
      response = await userService.getUser();
    } catch (e) {
    } finally {
      this.setState({ loading: false });
    }

    if (response && response.status === 200) {
      const user = response.data;
      const {
        userId,
        firstName,
        lastName,
        unreadMessages,
        isOwner,
        countryISOCode,
        currentOrganization,
        languageLocaleCode,
        emailVerified,
        mobilePhoneVerified,
      } = user;

      // Update the UserContext with the new user info
      this.context.onLogin(
        userId,
        firstName,
        lastName,
        unreadMessages,
        isOwner,
        countryISOCode,
        currentOrganization,
        languageLocaleCode,
        emailVerified,
        mobilePhoneVerified
      );

      return user;
    } else return undefined;
  };

  constructOrganization = () => {
    const {
      name,
      friendlyName,
      country,
      phone,
      addressStreet,
      addressCity,
      addressState,
      addressPostalCode,
      aboutInfo,
      financeEmail,
      financePhone,
      supportEmail,
      supportPhone,
    } = this.state.data;

    let userCountry = this.state.countries.find((c) =>
      _.isEqual(country, c._id)
    );

    // Construct the organization to be sent
    const organization = {
      name,
      friendlyName,
      phoneCountryCode: userCountry.countryCode,
      phone,

      address: {
        street: addressStreet,
        city: addressCity,
        state: addressState,
        postalCode: addressPostalCode,
        country: userCountry.name,
      },

      aboutInfo,

      finance: {
        email: financeEmail,
        phone: financePhone,
        phoneCountryCode: userCountry.countryCode,
      },

      support: {
        email: supportEmail,
        phone: supportPhone,
        phoneCountryCode: userCountry.countryCode,
      },
    };

    return organization;
  };

  createTourOperatorFinanceAccount = async () => {
    let response;
    try {
      this.setState({ loading: true });
      response = await accountService.registerTourOperatorAccount();
    } catch (e) {
    } finally {
      this.setState({ loading: false });
    }

    if (response && response.status === 200) {
      const { url } = response.data;

      // Send the user to Stripe
      window.location.href = url;
    }
  };

  doSubmit = async () => {
    // Construct organization to submit from the form data
    const organization = this.constructOrganization();

    // Submit the new organization to the server and upon success
    // update the User Context with new organization
    let rval = await this.registerOrgAndUpdateContext(organization);

    if (rval) {
      // Get the user again from the server, and update the User Context
      let user = await this.getUserAndUpdateContext();
      if (user) {
        // If the finance and/or support emails are different than the user's email
        // then send the user to the "RegisterTourOperatorVerifyEmail" component so
        // as to inform the user that he/she must verify the new email. Otherwise
        // send the user to ... TBD
        if (
          !_.isEqual(user.email, organization.finance.email) ||
          !_.isEqual(user.email, organization.support.email)
        ) {
          this.props.history.push("/operator/register-verify-email");
        } else {
          // Go to sign up with Stripe
          this.createTourOperatorFinanceAccount();
        }
      }
    }
  };

  async componentDidMount() {
    let response;
    try {
      response = await countryService.getOperatorCountries();
    } catch (e) {
    } finally {
      this.setState({ loading: false });
    }
    if (response && response.status === 200) {
      this.setState({ countries: response.data });
    }
  }

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      localStorage.setItem("UrlBeforeLogin", this.props.location.pathname);
      this.props.history.replace("/login");
      return null;
    }

    let Countries = this.state.countries;
    // Prepare the coutnries array
    const countries = Countries.map((c) => {
      return {
        _id: c._id,
        name: `${c.name} (+${c.countryCode})`,
      };
    });

    let containerClass,
      headerContainer,
      formContainer,
      logoImage,
      registerLabelText;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";
      headerContainer = "ovroom-form-header-container-mobile";
      formContainer = "ovroom-form-elements-container-option2";
      logoImage = "logo-image-mobile";
      registerLabelText = "ovroom-form-page-heading-mobile";
    } else {
      // desktop and tablet

      containerClass = "container ovroom-form-container";
      headerContainer = "ovroom-form-header-container";
      formContainer = "ovroom-form-elements-container-option2";
      logoImage = "logo-image";
      registerLabelText = "ovroom-form-page-heading";
    }

    return (
      <UserContext.Consumer>
        {(userContext) => (
          <div className={containerClass}>
            <div className={headerContainer}>
              <img
                className={logoImage}
                src={assetsURL + logoImageName}
                alt="Ovroom logo"
              />
              <p className={registerLabelText}>Register Tour Operator</p>
            </div>
            <div className={formContainer}>
              <form>
                {this.renderInput("name", "Business legal name", true)}
                {this.renderInput(
                  "friendlyName",
                  "Business friendly name",
                  false
                )}
                <div className="horizontal-line-container">
                  <hr className="horizontal-line-primary-color" />
                </div>

                {this.renderSelect(
                  "country",
                  "Business country (Country code)",
                  countries,
                  false
                )}
                {this.renderInput("phone", "Business phone number", false)}
                <div className="horizontal-line-container">
                  <hr className="horizontal-line-primary-color" />
                </div>

                {this.renderInput(
                  "addressStreet",
                  "Business street address",
                  false
                )}
                {this.renderInput("addressCity", "City", false)}
                {this.renderInput("addressState", "State/Province", false)}
                {this.renderInput("addressPostalCode", "Postal code", false)}

                <div className="horizontal-line-container">
                  <hr className="horizontal-line-primary-color" />
                </div>

                {this.renderInput("financeEmail", "Finance email", false)}
                {this.renderInput("financePhone", "Finance phone", false)}

                <div className="horizontal-line-container">
                  <hr className="horizontal-line-primary-color" />
                </div>

                {this.renderInput("supportEmail", "Support email", false)}
                {this.renderInput("supportPhone", "Support phone", false)}

                <div className="horizontal-line-container">
                  <hr className="horizontal-line-primary-color" />
                </div>

                {this.renderTextArea(
                  "aboutInfo",
                  "Describe your business",
                  false,
                  5
                )}

                <div className="form-check">
                  <input
                    className="form-check-input checkbox-form"
                    type="checkbox"
                    value={this.state.agreement}
                    id={"agreement"}
                    name={"agreement"}
                    onChange={this.handleChange}
                  />
                  <p className="accept-operator-terms-text">
                    I have read and agree to the{" "}
                    <Link to="/operator-termsandconditions">
                      Terms and Conditions
                    </Link>{" "}
                    and <Link to="/operator-privacy">Privacy Policy</Link>.
                  </p>
                </div>
                {this.renderSubmitButton("Register")}
              </form>
              <div className="vertical-filler-container" />
            </div>
            <ToastContainer />
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default RegisterTourOperator;
