import React, { Component } from "react";
import PropTypes from "prop-types";

import * as authService from "../../../services/authService";
import * as userService from "../../../services/userService";
import * as operatorTourService from "../../../services/operatorTourService";

import "../../../css/ovroomFormPage.css";

class UploadTourImages extends Component {
  state = {
    selectedFile: undefined,
    loaded: 0,
    image: undefined,
    storedFile: undefined,
    imageURL: undefined,
    loading: false,
    uploadSuccess: false,
  };

  // Handler when an image file changes - probably because the user selected an image to upload.
  handleImageChange = (event) => {
    // Read image file from local repository
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        selectedFile: event.target.files[0],
        loaded: 0,
        image: e.target.result,
      });
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  // Handler when the form is submitted.
  handleSubmit = async () => {
    let response;
    try {
      this.setState({ loading: true });
      response = await operatorTourService.uploadImage(
        this.props.tour._id,
        this.state.selectedFile,
        this.state.image
      );
    } catch (e) {
    } finally {
      this.setState({ loading: false });
    }
    if (response) {
      this.setState({
        storedFile: response.fileName,
        imageURL: response.fileURL,
        uploadSuccess: true,
      });

      // Call the parent
      const image = {
        imageURL: this.state.imageURL,
        imageFileName: this.state.selectedFile.name,
        imageType: this.state.image,
        storedFileName: this.state.storedFile,
      };
      this.props.onSuccessfulUpload(image);
    }
  };

  componentDidUpdate(prevProps) {
    // We check if the paraent component is telling us to clear the state(this.props.clearState), and that
    // have we cleared the state already (this.state.selectedFile)
    if (this.props.clearState && this.state.selectedFile) {
      // Reset the state
      this.setState({
        selectedFile: undefined,
        loaded: 0,
        image: undefined,
        storedFile: undefined,
        imageURL: undefined,
        loading: false,
        uploadSuccess: false,
      });
    }
  }

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      this.props.history.replace("/login");
      return null;
    }

    // Get user info from the local storage. If it is not found, send the user to the log-in screen.
    const userInfo = userService.getUserFromLocalStorage();
    if (!userInfo) {
      this.props.history.replace("/login");
      return null;
    }

    return (
      <React.Fragment>
        <div className="col-md-6">
          <form method="post" action="#" id="#">
            <div className="form-group files">
              <label style={{ paddingRight: "1rem" }}>Step 1: </label>
              <label className="btn btn-primary ">
                <input type="file" onChange={this.handleImageChange} />
                Select photo
              </label>
            </div>

            {this.state.selectedFile && (
              <div>
                <p>Selected photo: {this.state.selectedFile.name}</p>
                <label style={{ paddingRight: "1rem" }}>Step 2: </label>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.handleSubmit()}
                >
                  {this.state.loading && (
                    <span
                      className="spinner-border spinner-border-sm m-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Upload photo
                </button>
              </div>
            )}
          </form>

          {this.state.uploadSuccess && (
            <p style={{ color: "var(--color-neelo" }}>
              Photo successfully uploaded!
            </p>
          )}
        </div>
      </React.Fragment>
    );
  }
}

UploadTourImages.propTypes = {
  tour: PropTypes.object.isRequired,
  clearState: PropTypes.bool.isRequired,
  onSuccessfulUpload: PropTypes.func.isRequired,
};

export default UploadTourImages;
