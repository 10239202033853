import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import deviceConfig from "./common/deviceConfig";
import SectionCard from "./sectionCard";
import "../css/section2.css";

// The Carousel used in this code is "react-multi-carousel" installed as a separate NPM package.

const Section2 = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
    },
  };

  let containerClass = "container section2-container";
  if (deviceConfig.isMobilePhone) {
    containerClass += " section2-container-mobile";
  }

  return (
    <div className={containerClass}>
      <label className="section2-heading">Pick your ride</label>
      {/* The Carousel used in this code is "react-multi-carousel" installed as a separate NPM package. */}
      <Carousel
        swipeable={true}
        draggable={true}
        showArrows={true}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={false}
        keyBoardControl={true}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        partialVisible={deviceConfig.isMobilePhone}
        showDots={deviceConfig.isMobilePhone}
        minimumTouchDrag={0}
        dotListClass="section2-dotlist-class"
      >
        <div>
          <SectionCard url="/images/Overland.jpg" title="Overland" />
        </div>
        <div>
          <SectionCard url="/images/Motorcycle.jpg" title="Motorcycle" />
        </div>
        <div>
          <SectionCard url="/images/Bicycle.jpg" title="Bicycle" />
        </div>
        <div>
          <SectionCard url="/images/Walk.jpg" title="Walk" />
        </div>
      </Carousel>
    </div>
  );
};

export default Section2;
