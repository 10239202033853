import React from "react";
import PropTypes from "prop-types";

import "../../css/select.css";

// Form's Select component
// Renders a label with select-dropdown and error messages for a Form.
//
// Input:
//      The props of this component should have:
//        name (String, required) - Name of the specific input field in the form. This should be unique in a given form.
//        label (String, required) - Label to be displayed ABOVE the text-field.
//        options (Array of objects, required) - An array of options to choose from in the 'select' component. Each
//          object in the 'options' array looks as under:
//              {
//                  _id: <(String, required) A unique string that identifies the option>
//                  name: <(String, required) The value of the option to be shown in the drop-down menu>
//              }
//        error (String, optional) - If present, it is shown BELOW the input field as error.
//

const Select = ({ name, label, options, error, value, ...rest }) => {
  return (
    <div className="form-group">
      <label className="select-form-label" htmlFor={name}>
        {label}
      </label>
      <select
        {...rest}
        id={name}
        name={name}
        className="form-control form-select select-form"
        value={value}
      >
        <option value=""> </option>
        {options.map((option) => (
          <option key={option._id} value={option._id}>
            {option.name}
          </option>
        ))}
      </select>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
};

export default Select;
