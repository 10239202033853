import React from "react";
import PropTypes from "prop-types";

// ListGroup component
// Creates a list group that acts as filter, or a menu item.
//
// Props:
//  title (String, optional) - Title of the group
//  items (Array of strings or objects, required) - Array of strings that constitutes the list group or menu
//  displayField (String, optional) - It 'items' is an array of objects, then displayField determines which field of the object should be shown
//      in the list. The field must be a String.
//  key (String, optional) - It 'items' is an array of objects, then 'key' determines which field of the object should be used as the key-value
//      for each item in the list. The 'key-value' must be unique and should be either a number or a string.
//  selectedItemIndex (Number, required) - Index of the active item (currently selected item).
//  onItemSelect (Function, required) - A method to be called in the parent component when an item is selected.
//  horizontal (Boolean, optional) - Flag that indicates if the layout of the listgroup is vertical or horizontal
//  margin (Number, optional) - Specifies if any margin needs to be shown around the list group
//
const ListGroup = ({
  title,
  items,
  displayField,
  key,
  selectedItemIndex,
  onItemSelect,
  horizontal,
  margin,
}) => {
  const currentItem = items[selectedItemIndex];
  let classes = "list-group";
  if (horizontal) classes += " list-group-horizontal";
  if (margin) classes += ` m-${margin}`;
  return (
    <ul className={classes}>
      {title && (
        <li
          className="list-group-item"
          style={horizontal ? titleStyleHorizontal : titleStyleVertical}
        >
          {title}
        </li>
      )}
      {items.map((item) => (
        <button
          key={key ? item[key] : item}
          className={
            item === currentItem ? "list-group-item active" : "list-group-item"
          }
          onClick={() => onItemSelect(item)}
        >
          {displayField ? item[displayField] : item}
        </button>
      ))}
    </ul>
  );
};

const titleStyleHorizontal = { borderTop: 0, borderLeft: 0, borderBottom: 0 };
const titleStyleVertical = { borderTop: 0, borderLeft: 0, borderRight: 0 };

ListGroup.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array.isRequired,
  displayField: PropTypes.string,
  key: PropTypes.string,
  selectedItemIndex: PropTypes.number.isRequired,
  onItemSelect: PropTypes.func.isRequired,
  horizontal: PropTypes.bool,
  margin: PropTypes.number,
};

export default ListGroup;
