import React from "react";
import Joi from "joi";
import { ToastContainer } from "react-toastify";

import * as authService from "../../../../services/authService";
import Form from "../../../common/form";
import "../../../../css/ovroomFormPage.css";
import "../../../../css/checkbox.css";

import { assetsURL, logoImageName } from "../../../../config.json";

import UserContext from "../../../../context/userContext";

class CreateTourCapacity extends Form {
  isMobile = window.innerWidth < 480;

  // Context used
  static contextType = UserContext;

  state = {
    data: {
      capacityMaxAdultsPerTour: "",
      capacityMaxChildrenPerTour: "",
      capacityMaxPersonsPerTour: "",
    },
    errors: {},
    loading: false,
  };

  schema = Joi.object({
    capacityMaxAdultsPerTour: Joi.number()
      .min(0)
      .max(50)
      .required()
      .label("Maximum number of adults per tour"),
    capacityMaxChildrenPerTour: Joi.number()
      .min(0)
      .max(50)
      .required()
      .label("Maximum number of children per tour"),
    capacityMaxPersonsPerTour: Joi.number()
      .min(0)
      .max(50)
      .required()
      .label("Maximum total per tour (adults + children)"),
  });

  doSubmit = async () => {
    const { data } = this.state;
    const capacity = {
      maxAdultsPerTour: data.capacityMaxAdultsPerTour,
      maxChildrenPerTour: data.capacityMaxChildrenPerTour,
      maxPersonsPerTour: data.capacityMaxPersonsPerTour,
    };
    localStorage.setItem("TourCapacity", JSON.stringify(capacity));
    this.props.history.push("/operator/create-tour-cancel-policy");
  };

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      //localStorage.setItem("UrlBeforeLogin", this.props.location.pathname);
      this.props.history.replace("/login");
      return null;
    }

    let containerClass,
      headerContainer,
      formContainer,
      logoImage,
      pageHeadingTextStyle;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";
      headerContainer = "ovroom-form-header-container-mobile";
      formContainer = "ovroom-form-elements-container-option2";
      logoImage = "logo-image-mobile";
      pageHeadingTextStyle = "ovroom-form-page-heading-mobile";
    } else {
      // desktop and tablet

      containerClass = "container ovroom-form-container";
      headerContainer = "ovroom-form-header-container";
      formContainer = "ovroom-form-elements-container-option2";
      logoImage = "logo-image";
      pageHeadingTextStyle = "ovroom-form-page-heading";
    }

    return (
      <UserContext.Consumer>
        {(userContext) => (
          <div className={containerClass}>
            <div className={headerContainer}>
              <img
                className={logoImage}
                src={assetsURL + logoImageName}
                alt="Ovroom logo"
              />
              <p className={pageHeadingTextStyle}>Tour Capacity</p>
            </div>
            <div className={formContainer}>
              <form>
                {this.renderInput(
                  "capacityMaxAdultsPerTour",
                  "Maximum number of adults per tour",
                  true
                )}
                {this.renderInput(
                  "capacityMaxChildrenPerTour",
                  "Maximum number of children per tour",
                  false
                )}
                {this.renderInput(
                  "capacityMaxPersonsPerTour",
                  "Maximum total per tour (adults + children)",
                  false
                )}

                {this.renderSubmitButton("Save and next")}
              </form>
              <div className="vertical-filler-container" />
            </div>
            <ToastContainer />
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default CreateTourCapacity;
