import { Link } from "react-router-dom";
import Joi from "joi";

import Form from "./common/form";
import * as authService from "../services/authService";
import UserContext from "../context/userContext";

import "../css/loginForm.css";
import React from "react";

class EmailForm extends Form {
  // Context used
  static contextType = UserContext;

  state = {
    data: {
      email: "",
      password: "",
    },
    errors: {},
    loading: false,
  };

  // The following 'doSubmit' must be spelled exactly like the way it is to ensure that
  // when our 'Form' component performs the submit, it is calling the correct method
  // implemented here.
  doSubmit = async () => {
    const { email, password } = this.state.data;
    let response;
    try {
      this.setState({ loading: true }); // show spinner
      response = await authService.emailAuth({ email, password });
    } catch (e) {
    } finally {
      this.setState({ loading: false }); // remove spinner
    }
    if (response && response.status === 200) {
      const {
        _id,
        firstName,
        lastName,
        unreadMessages,
        isOwner,
        countryISOCode,
        currentOrganization,
        languageLocaleCode,
        emailVerified,
        mobilePhoneVerified,
      } = response.data;

      // Handle log-in to save information received
      this.context.onLogin(
        _id,
        firstName,
        lastName,
        unreadMessages,
        isOwner,
        countryISOCode,
        currentOrganization,
        languageLocaleCode,
        emailVerified,
        mobilePhoneVerified
      );

      // Save email address to User Context
      this.context.onEmailLogin(email);

      // Go to parent to perform further tasks (e.g. go to Home page)
      this.props.onSubmitForm();
    }
  };

  schema = Joi.object({
    email: Joi.string()
      .trim()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    password: Joi.string()
      .trim()
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&+-_])[A-Za-z\d@$!%*?&+-_]{8,}$/
      )
      .required()
      .label("Password")
      .messages({
        "string.pattern.base":
          'Password must be minimum 8 characters long, and must have one uppercase character, one lowercase character, one numeric character and one of the following special characters "@ $ ! % * ? & + - _"',
      }),
  });

  render() {
    return (
      <React.Fragment>
        <form>
          {this.renderInput("email", "Email", true)}
          {this.renderInput("password", "Password", false, "password")}
          <Link to="/forgotPasswordIntent">
            <p className="login-sub-text">Forgot password?</p>
          </Link>
          {this.renderSubmitButton("Login with email")}
        </form>
      </React.Fragment>
    );
  }
}

export default EmailForm;
