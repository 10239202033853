import React from "react";
import Joi from "joi";
import _ from "lodash";

import * as authService from "../../../../services/authService";
import Form from "../../../common/form";
import "../../../../css/ovroomFormPage.css";

class CreateTourPriceMonthlyPriceForm extends Form {
  isMobile = window.innerWidth < 480;

  state = {
    data: {
      salesTaxPercentage: "",
      salesTaxDescription: "",
      otherTaxPercentage: "",
      otherTaxDescription: "",

      currency: "",

      amountAdult1: "", // January
      amountChild1: "",
      amountAdult2: "", // February
      amountChild2: "",
      amountAdult3: "", // March
      amountChild3: "",
      amountAdult4: "", // April
      amountChild4: "",
      amountAdult5: "", // May
      amountChild5: "",
      amountAdult6: "", // June
      amountChild6: "",
      amountAdult7: "", // July
      amountChild7: "",
      amountAdult8: "", // August
      amountChild8: "",
      amountAdult9: "", // September
      amountChild9: "",
      amountAdult10: "", // October
      amountChild10: "",
      amountAdult11: "", // November
      amountChild11: "",
      amountAdult12: "", // December
      amountChild12: "",
    },
    errors: {},
    loading: false,
  };

  schema = Joi.object({
    currency: Joi.string().required().label("Currency"),

    amountAdult1: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per adult"),
    amountChild1: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per child"),

    amountAdult2: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per adult"),
    amountChild2: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per child"),

    amountAdult3: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per adult"),
    amountChild3: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per child"),

    amountAdult4: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per adult"),
    amountChild4: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per child"),

    amountAdult5: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per adult"),
    amountChild5: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per child"),

    amountAdult6: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per adult"),
    amountChild6: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per child"),

    amountAdult7: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per adult"),
    amountChild7: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per child"),

    amountAdult8: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per adult"),
    amountChild8: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per child"),

    amountAdult9: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per adult"),
    amountChild9: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per child"),

    amountAdult10: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per adult"),
    amountChild10: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per child"),

    amountAdult11: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per adult"),
    amountChild11: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per child"),

    amountAdult12: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per adult"),
    amountChild12: Joi.number()
      .positive()
      .integer()
      .min(0)
      .required()
      .label("Price per child"),

    salesTaxPercentage: Joi.number()
      .min(0)
      .required()
      .label("Sales tax percentage"),
    salesTaxDescription: Joi.string()
      .empty("")
      .max(3000)
      .label("Sales tax description")
      .optional(),
    otherTaxPercentage: Joi.number()
      .empty("")
      .min(0)
      .label("Other tax percentage")
      .optional(),
    otherTaxDescription: Joi.string()
      .empty("")
      .max(3000)
      .label("Other tax description")
      .optional(),
  }).with("otherTaxPercentage", "otherTaxDescription"); // make sure if startDate is present, then endDate must be present, too

  doSubmit = async () => {
    let prices = [];
    // Get the actual currency notation
    const currency = this.props.currencies.find((c) =>
      _.isEqual(c._id, this.state.data.currency)
    ).currency;

    const currencyCountryISOCode = this.props.currencies.find((c) =>
      _.isEqual(c._id, this.state.data.currency)
    ).isoCode;

    for (let i = 1; i <= 12; i++) {
      // Construct variable names
      const adultPriceVarName = `amountAdult${i}`;
      const childPriceVarName = `amountChild${i}`;
      prices.push({
        currency: currency,
        currencyCountryISOCode: currencyCountryISOCode,
        amountAdult: this.state.data[adultPriceVarName],
        amountChild: this.state.data[childPriceVarName],
        salesTaxPercentage: this.state.data.salesTaxPercentage,
        salesTaxDescription: this.state.data.salesTaxDescription,
        otherTaxPercentage: this.state.data.otherTaxPercentage,
        otherTaxDescription: this.state.data.otherTaxDescription,
      });
    }

    this.props.onSubmitForm(prices); // Call the parent component for further actions
  };

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      //localStorage.setItem("UrlBeforeLogin", this.props.location.pathname);
      this.props.history.replace("/login");
      return null;
    }

    let sectionHeadingTextStyle;
    if (this.isMobile) {
      sectionHeadingTextStyle = "ovroom-form-section-heading-mobile";
    } else {
      // desktop and tablet

      sectionHeadingTextStyle = "ovroom-form-section-heading";
    }

    return (
      <React.Fragment>
        <form>
          <label className={sectionHeadingTextStyle}>
            Tax we need to collect
          </label>

          {this.renderInput("salesTaxPercentage", "Sales tax percentage", true)}
          {this.renderInput(
            "salesTaxDescription",
            "Sales tax description",
            false
          )}

          {this.renderInput(
            "otherTaxPercentage",
            "Other tax percentage (optional)",
            false
          )}
          {this.renderInput(
            "otherTaxDescription",
            "Other tax description",
            false
          )}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>

          <label className={sectionHeadingTextStyle}>Tour currency</label>

          {this.renderSelect(
            "currency",
            "Currency",
            this.props.currencies,
            false
          )}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>
          <label className={sectionHeadingTextStyle}>January price</label>
          {this.renderInput("amountAdult1", "Price per adult", false)}
          {this.renderInput("amountChild1", "Price per child", false)}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>
          <label className={sectionHeadingTextStyle}>February price</label>
          {this.renderInput("amountAdult2", "Price per adult", false)}
          {this.renderInput("amountChild2", "Price per child", false)}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>
          <label className={sectionHeadingTextStyle}>March price</label>
          {this.renderInput("amountAdult3", "Price per adult", false)}
          {this.renderInput("amountChild3", "Price per child", false)}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>
          <label className={sectionHeadingTextStyle}>April price</label>
          {this.renderInput("amountAdult4", "Price per adult", false)}
          {this.renderInput("amountChild4", "Price per child", false)}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>
          <label className={sectionHeadingTextStyle}>May price</label>
          {this.renderInput("amountAdult5", "Price per adult", false)}
          {this.renderInput("amountChild5", "Price per child", false)}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>
          <label className={sectionHeadingTextStyle}>June price</label>
          {this.renderInput("amountAdult6", "Price per adult", false)}
          {this.renderInput("amountChild6", "Price per child", false)}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>
          <label className={sectionHeadingTextStyle}>July price</label>
          {this.renderInput("amountAdult7", "Price per adult", false)}
          {this.renderInput("amountChild7", "Price per child", false)}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>
          <label className={sectionHeadingTextStyle}>August price</label>
          {this.renderInput("amountAdult8", "Price per adult", false)}
          {this.renderInput("amountChild8", "Price per child", false)}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>
          <label className={sectionHeadingTextStyle}>September price</label>
          {this.renderInput("amountAdult9", "Price per adult", false)}
          {this.renderInput("amountChild9", "Price per child", false)}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>
          <label className={sectionHeadingTextStyle}>October price</label>
          {this.renderInput("amountAdult10", "Price per adult", false)}
          {this.renderInput("amountChild10", "Price per child", false)}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>
          <label className={sectionHeadingTextStyle}>November price</label>
          {this.renderInput("amountAdult11", "Price per adult", false)}
          {this.renderInput("amountChild11", "Price per child", false)}

          <div className="horizontal-line-container">
            <hr className="horizontal-line-primary-color" />
          </div>
          <label className={sectionHeadingTextStyle}>December price</label>
          {this.renderInput("amountAdult12", "Price per adult", false)}
          {this.renderInput("amountChild12", "Price per child", false)}

          {this.renderSubmitButton("Save and next")}
        </form>
      </React.Fragment>
    );
  }
}

export default CreateTourPriceMonthlyPriceForm;
