import React from "react";
import PropTypes from "prop-types";

import "../../css/textArea.css";

// Form's TextArea component
// Renders a label with text area (where paragraph can be written) and error messages for a Form.
//
// Input:
//      The props of this component should have:
//        name (String, required) - Name of the specific text-area field in the form. This should be unique in a given form.
//        label (String, required) - Label to be displayed ABOVE the text-area field.
//        value (String, required) - Value of the text-area field.
//        rows (Number, required) - Number of rows in the text area.
//        onChange (function, required) - The method to call in the calling-component when the value of the text-area field changes (user types).
//        autoFocus (Boolean, optional) - If set, the text-area field is rendered with FOCUS.
//        error (String, optional) - If present, it is shown BELOW the text-area field as error.
//

const TextArea = ({ name, label, value, rows, onChange, error, ...rest }) => {
  return (
    <div className="form-group text-form-group">
      <label className="text-form-label" htmlFor={name}>
        {label}
      </label>
      <textarea
        {...rest}
        id={name}
        name={name}
        value={value}
        rows={rows}
        onChange={onChange}
        className="form-control text-area-form"
      />
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  rows: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default TextArea;
