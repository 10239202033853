import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import "../../../../css/edit.css";
import "../../../../css/operator.css";
import "../../../../css/ovroomFormPage.css";

class OperatorViewTransportation extends Component {
  render() {
    const { tour } = this.props;

    return (
      <React.Fragment>
        {tour && (
          <div className="card shadow p-3 mb-5 bg-white rounded">
            <div className="container card-body">
              <div className="view-title-container">
                <label className="card-title view-title-text">
                  Tour transportation
                </label>
                <div className="edit-button-with-warning-container">
                  <Link
                    className="btn btn-primary view-title-button-text"
                    to={{
                      pathname: "/operator/tours/tour-transportation",
                      state: { tour },
                    }}
                  >
                    <i className="bi bi-pencil-square"></i> Edit
                  </Link>
                </div>
              </div>

              <div className="view-content-container">
                {tour.transportation.map((t) => {
                  return (
                    <p className="view-item-content-text" key={t}>
                      {t}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

OperatorViewTransportation.propTypes = {
  tour: PropTypes.object.isRequired,
};

export default OperatorViewTransportation;
