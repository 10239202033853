// forgotPassword.jsx
// This is the component that launches when the user clicks on a link givent to him/her in an email sent
// in response to user requesting to start the forgot-password-process.
// In this component, we verify the forgot-password-token, and upon successful verification, we allow
// the user to supply new password. If the password submission is successful, we send the user to the
// 'PasswordChangeSuccesss' page (where the user is informed that the password is successfully changed, and
// the user can then proceed to Login page).
//

import React from "react";
import Joi from "joi";
import { ToastContainer } from "react-toastify";

import * as authService from "../services/authService";
import Form from "./common/form";
import "../css/ovroomFormPage.css";

import { assetsURL, logoImageName } from "../config.json";

class ForgotPassword extends Form {
  isMobile = window.innerWidth < 480;

  state = {
    data: {
      password: "",
      password_confirmation: "",
    },
    errors: {},
    loading: true, // Flag to indicate if an async task is being performed. We set it to true, because we are checking if
    // the forgot-password-token supplied in the URL is valid or not
  };

  schema = Joi.object({
    password: Joi.string()
      .trim()
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&+-_])[A-Za-z\d@$!%*?&+-_]{8,}$/
      )
      .required()
      .label("Password")
      .messages({
        "string.pattern.base":
          'Password must be minimum 8 characters long, and must have one uppercase character, one lowercase character, one numeric character and one of the following special characters "@ $ ! % * ? & + - _"',
      }),

    password_confirmation: Joi.any()
      .equal(Joi.ref("password"))
      .required()
      .label("Confirm password")
      .messages({ "any.only": "Passwords must match" }),
  });

  doSubmit = async () => {
    const { password } = this.state.data;

    // Construct the object to be sent
    const newPassword = {
      token: this.props.match.params.token,
      password: password,
    };

    let response;
    try {
      this.setState({ loading: true });
      response = await authService.submitForgotPassword(newPassword);
    } catch (e) {
    } finally {
      this.setState({ loading: false });
    }
    // Check if the response is success
    if (response && response.status === 200) {
      authService.logout();
      this.props.history.replace("/passwordChangeSuccess");
    }
  };

  // Ensure that when this component is mounted, we check the validity of the forgot password token given to us.
  // If the token is no longer valid (as determined by the server), we proceed to Not Found.
  async componentDidMount() {
    try {
      await authService.forgotPasswordIntentVerify({
        token: this.props.match.params.token,
      });
    } catch (e) {
      this.props.history.replace("/notfound");
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    let containerClass,
      headerContainer,
      formContainer,
      logoImage,
      forgotPasswordLabelText;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";
      headerContainer = "ovroom-form-header-container-mobile";
      formContainer = "ovroom-form-elements-container-option2";
      logoImage = "logo-image-mobile";
      forgotPasswordLabelText = "ovroom-form-page-heading-mobile";
    } else {
      // desktop and tablet

      containerClass = "container ovroom-form-container";
      headerContainer = "ovroom-form-header-container";
      formContainer = "ovroom-form-elements-container-option2";
      logoImage = "logo-image";
      forgotPasswordLabelText = "ovroom-form-page-heading";
    }

    return (
      <React.Fragment>
        <div className={containerClass}>
          <div className={headerContainer}>
            <img
              className={logoImage}
              src={assetsURL + logoImageName}
              alt="Ovroom logo"
            />
            <p className={forgotPasswordLabelText}>Change Password</p>
          </div>
          <div className={formContainer}>
            <form>
              {this.renderInput("password", "Password", false, "password")}
              {this.renderInput(
                "password_confirmation",
                "Confirm password",
                false,
                "password"
              )}
              {this.renderSubmitButton("Change password")}
            </form>
          </div>
          <ToastContainer />
        </div>
      </React.Fragment>
    );
  }
}

export default ForgotPassword;
