import React from "react";
import Joi from "joi";
import _ from "lodash";
import { ToastContainer } from "react-toastify";

import * as tourServices from "../../../../services/operatorTourService";
import * as tourTypesService from "../../../../services/tourTypeService";
import * as authService from "../../../../services/authService";
import * as countryService from "../../../../services/countryService";
import Form from "../../../common/form";
import "../../../../css/ovroomFormPage.css";
import "../../../../css/checkbox.css";

import { assetsURL, logoImageName } from "../../../../config.json";

class TourBasicInfo extends Form {
  isMobile = window.innerWidth < 480;

  state = {
    data: {
      tourTitle: "",
      placeText: "",
      placeTagsString: "",
      tourType: "",
      startAddressStreet: "",
      startAddressCity: "",
      startAddressState: "",
      startAddressPostalCode: "",
      startAddressCountry: "",

      endAddressStreet: "",
      endAddressCity: "",
      endAddressState: "",
      endAddressPostalCode: "",
      endAddressCountry: "",
    },
    errors: {},
    countries: [],
    tourTypes: [],
    loading: true,
    changed: false,
  };

  schema = Joi.object({
    tourTitle: Joi.string()
      .trim()
      .min(2)
      .max(500)
      .required()
      .label("Tour title"),
    placeText: Joi.string()
      .trim()
      .min(2)
      .max(500)
      .required()
      .label("Place text"),
    placeTagsString: Joi.string().trim().min(2).max(1000).label("Place tags"),
    tourType: Joi.string().trim().min(2).max(500).required().label("Tour type"),

    startAddressStreet: Joi.string()
      .min(2)
      .max(5000)
      .required()
      .label("Street address"),
    startAddressCity: Joi.string().min(2).max(255).required().label("City"),
    startAddressState: Joi.string()
      .min(2)
      .max(255)
      .required()
      .label("State/Province"),
    startAddressPostalCode: Joi.string()
      .min(1)
      .max(20)
      .required()
      .label("Postal code"),
    startAddressCountry: Joi.string()
      .min(2)
      .max(255)
      .required()
      .label("Country"),

    endAddressStreet: Joi.string()
      .min(2)
      .max(5000)
      .required()
      .label("Street address"),
    endAddressCity: Joi.string().min(2).max(255).required().label("City"),
    endAddressState: Joi.string()
      .min(2)
      .max(255)
      .required()
      .label("State/Province"),
    endAddressPostalCode: Joi.string()
      .min(1)
      .max(20)
      .required()
      .label("Postal code"),
    endAddressCountry: Joi.string().min(2).max(255).required().label("Country"),
  });

  doCancel = () => {
    this.props.history.goBack();
  }

  doSubmit = async () => {
    const { data } = this.state;
    const tourType = this.state.tourTypes.find((t) =>
      _.isEqual(t._id, data.tourType)
    );
    const startCountry = this.state.countries.find((c) =>
      _.isEqual(c._id, data.startAddressCountry)
    );
    const endCountry = this.state.countries.find((c) =>
      _.isEqual(c._id, data.endAddressCountry)
    );

    const { tour } = this.props.location.state;

    // Update the server
    let response;
    try {
      this.setState({ loading: true });
      response = await tourServices.updateTourBasicInfo(
        tour._id,
        data.tourTitle,
        data.placeText,
        data.placeTagsString,
        tourType.name,
        /* startAddress */
        {
          street: data.startAddressStreet,
          city: data.startAddressCity,
          state: data.startAddressState,
          postalCode: data.startAddressPostalCode,
          country: startCountry.name,
        },
        /* endAddress */
        {
          street: data.endAddressStreet,
          city: data.endAddressCity,
          state: data.endAddressState,
          postalCode: data.endAddressPostalCode,
          country: endCountry.name,
        }
      );
    } catch (e) {
    } finally {
      this.setState({ loading: false });

      if (response && response.status === 200) this.props.history.goBack();
    }
  };

  async componentDidMount() {
    let response;
    try {
      response = await countryService.getTouristCountries();
    } catch (e) {}

    if (response && response.status === 200) {
      this.setState({ countries: response.data });
    }
    try {
      response = await tourTypesService.getTourTypes();
    } catch (e) {
    } finally {
      this.setState({ loading: false });
    }
    if (response && response.status === 200) {
      this.setState({ tourTypes: response.data });
    }

    this.fillFormInfoFromTour();
  }

  fillFormInfoFromTour() {
    // Extract tour from the props-location-state (passed to us by TourInfoMenu)
    const { tour } = this.props.location.state;

    // Any 'Select' (dropdown) rendering requires a combination of {_id, name} as input array. So
    // convert the value of the strings received for tour-type, start address country, end address country to their
    // respective id values.
    let tourTypeId = this.state.tourTypes.find((t) => t.name === tour.tourType)
      ._id;
    let startCountryId = this.state.countries.find(
      (c) => c.name === tour.startAddress.country
    )._id;
    let endCountryId = this.state.countries.find(
      (c) => c.name === tour.endAddress.country
    )._id;

    let data = {
      tourTitle: tour.tourTitle,
      placeText: tour.placeText,
      placeTagsString: tour.placeTagsArray.toString(),
      tourType: tourTypeId,
      startAddressStreet: tour.startAddress.street,
      startAddressCity: tour.startAddress.city,
      startAddressState: tour.startAddress.state,
      startAddressPostalCode: tour.startAddress.postalCode,
      startAddressCountry: startCountryId,

      endAddressStreet: tour.endAddress.street,
      endAddressCity: tour.endAddress.city,
      endAddressState: tour.endAddress.state,
      endAddressPostalCode: tour.endAddress.postalCode,
      endAddressCountry: endCountryId,
    };

    this.setState({ data });
  }

  render() {
    // Check if the user is logged in. If not, send the user to the log-in/register screen
    const user = authService.getCurrentUserInfoFromJWT();
    if (!user) {
      //localStorage.setItem("UrlBeforeLogin", this.props.location.pathname);
      this.props.history.replace("/login");
      return null;
    }

    let Countries = this.state.countries;
    // Prepare the coutnries array
    const countries = Countries.map((c) => {
      return {
        _id: c._id,
        name: `${c.name}`,
      };
    });

    let containerClass,
      headerContainer,
      formContainer,
      logoImage,
      pageHeadingTextStyle,
      sectionHeadingTextStyle;
    if (this.isMobile) {
      containerClass = "container ovroom-form-container-mobile";
      headerContainer = "ovroom-form-header-container-mobile";
      formContainer = "ovroom-form-elements-container-option2";
      logoImage = "logo-image-mobile";
      pageHeadingTextStyle = "ovroom-form-page-heading-mobile";
      sectionHeadingTextStyle = "ovroom-form-section-heading-mobile";
    } else {
      // desktop and tablet

      containerClass = "container ovroom-form-container";
      headerContainer = "ovroom-form-header-container";
      formContainer = "ovroom-form-elements-container-option2";
      logoImage = "logo-image";
      pageHeadingTextStyle = "ovroom-form-page-heading";
      sectionHeadingTextStyle = "ovroom-form-section-heading";
    }

    return (
      <div className={containerClass}>
        <div className={headerContainer}>
          <img
            className={logoImage}
            src={assetsURL + logoImageName}
            alt="Ovroom logo"
          />
          <p className={pageHeadingTextStyle}>Tour Basic Info</p>
        </div>
        <div className={formContainer}>
          {this.state.loading && (
            <div className="view-spinner-container">
              <span
                className="spinner-border spinner-border-sm m-1"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          )}

          <form>
            {this.renderInput("tourTitle", "Tour title", true)}
            {this.renderInput("placeText", "Place text", false)}
            {this.renderInput("placeTagsString", "Place tags", false)}
            {this.renderSelect("tourType", "Tour type", this.state.tourTypes)}

            <div className="horizontal-line-container">
              <hr className="horizontal-line-primary-color" />
            </div>

            <label className={sectionHeadingTextStyle}>
              Tour start address
            </label>
            {this.renderInput("startAddressStreet", "Street address", false)}
            {this.renderInput("startAddressCity", "City", false)}
            {this.renderInput("startAddressState", "State/Province", false)}
            {this.renderInput("startAddressPostalCode", "Postal code", false)}
            {this.renderSelect("startAddressCountry", "Country", countries)}
            <div className="horizontal-line-container">
              <hr className="horizontal-line-primary-color" />
            </div>

            <label className={sectionHeadingTextStyle}>Tour end address</label>
            {this.renderInput("endAddressStreet", "Street address", false)}
            {this.renderInput("endAddressCity", "City", false)}
            {this.renderInput("endAddressState", "State/Province", false)}
            {this.renderInput("endAddressPostalCode", "Postal code", false)}
            {this.renderSelect("endAddressCountry", "Country", countries)}

            {this.renderEditFormSubmitButton("Save and close")}
          </form>
          <div className="vertical-filler-container" />
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default TourBasicInfo;
