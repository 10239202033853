import http from "./httpService";
import { apiURL } from "../config.json";
import jwtDecode from "jwt-decode";

const apiEndpoint = apiURL + "/auth";

const tokenKey = "token";

// phoneAuth
// Authenticate a user with mobile phone number and temporary secret.
//
// Input:
//  auth object with following fields:
//      mobilePhoneCountryCode - String - Country code of the user
//      mobilePhone - String - Mobilephone number of the user
//      tempSecret - String - Temporary secret code (6-digits)
//
// Returns:
//  A promise that upon resolving contains a response JSON.
//    On Success, it should have JWT token, user ID, first name and last name
//    of the user logged in.
//
export async function phoneAuth(authObject) {
  // The following HTTP request returns a promise
  const response = await http.post(apiEndpoint, authObject);
  if (response.data) {
    // Store the token in local storage. This performs 'login' for the user.
    localStorage.setItem(tokenKey, response.data.token);
  }
  return response;
}

// emailAuth
// Authenticate a user with email and password.
//
// Input:
//  auth object with following fields:
//      email - String - User's email address
//      password - String - User's password
//
// Returns:
//  A promise that upon resolving contains a response JSON.
//    On Success, it should have JWT token, user ID, first name and last name
//    of the user logged in.
//
export async function emailAuth(emailObject) {
  // The following HTTP request returns a promise
  const response = await http.post(apiEndpoint, emailObject);
  if (response.data) {
    // Store the token in local storage. This performs 'login' for the user.
    localStorage.setItem(tokenKey, response.data.token);
  }
  return response;
}

// logout
// Logout from the service.
//
// Input: none
//
// Returns: none
//
export function logout() {
  // localStorage.removeItem(tokenKey); // Remove toekn from local storage. This performs 'logout'.
  localStorage.clear(); // Delete everything in the local storage, including the token
}

// getCurrentUserInfoFromJWT
// If a 'logged in' user exists, return the user's JWT.
//
// Input: none
//
// Returns:
//  On success - returns a User Info object that is decomposed JWT (means a user is logged in)
//  On failure - returns null (means no user is logged in)
//
export function getCurrentUserInfoFromJWT() {
  try {
    const jwt = localStorage.getItem(tokenKey); // See if 'token' exists in local storage
    if (jwt) {
      const userInfo = jwtDecode(jwt);
      return userInfo;
    } else return null;
  } catch (e) {
    return null;
  }
}

// getJwt
// If a 'logged in' user exists, return the user's JWT.
//
// Input: none
//
// Returns:
//  On success - returns user's JWT.
//  On failure - returns null (means no user is logged in)
//
export function getJwt() {
  return localStorage.getItem("token"); // See if 'token' exists in local storage
}

// getTempSecret
// Get temporary secret for phone based authorization.
//
// Input:
//  phone object with following fields:
//      mobilePhoneCountryCode - String - Country code of the user
//      mobilePhone - String - Mobilephone number of the user
//
// Returns:
//  A promise that upon resolving contains a response JSON.
//    On success, it should contain a string with 6-digit authorizaiton code.
//
export function getTempSecret(phoneObject) {
  const url = apiEndpoint + "/getTempSecret";

  // The following HTTP request returns a promise
  return http.post(url, phoneObject);
}

// getVerificationEmail
// Get verfication email.
//
// Input:
//  email object with following fields:
//      email - String - email address
//
// Returns:
//  A promise that upon resolving contains a response JSON.
//    On success, it should contain a string with 6-digit authorizaiton code.
//
export function getVerificationEmail(emailObject) {
  const url = apiEndpoint + "/getVerificationEmail";

  // The following HTTP request returns a promise
  return http.post(url, emailObject);
}

// forgotPasswordIntent
// Verify the forgot-password-intent token.
//
// Input: the emailInput object that looks as under:
//  {
//    email: A string representing the user's email address
//  }
//
// Returns:
//  A promise that upon resolving contains a response JSON.
//    On success, it should contain status of 200.
//
export function forgotPasswordIntent(emailInput) {
  const url = apiEndpoint + "/forgotPasswordIntent";

  // The following HTTP request returns a promise
  return http.post(url, emailInput);
}

// forgotPasswordIntentVerify
// Verify the forgot-password-intent token.
//
// Input: the forgotPasswordToken object that looks as under:
//  {
//    token: A string representing the forgot-password-token
//  }
//
// Returns:
//  A promise that upon resolving contains a response JSON.
//    On success, it should contain status of 200.
//
export function forgotPasswordIntentVerify(forgotPasswordToken) {
  const url = apiEndpoint + "/forgotPasswordIntentVerify";

  // The following HTTP request returns a promise
  return http.post(url, forgotPasswordToken);
}

// submitForgotPassword
// Submit a new password for forgot-password-request.
//
// Input: the newPassowrd object that looks as under:
//  {
//    token: A string representing the forgot-password-token
//    password: A string representing the new password
//  }
//
// Returns:
//  A promise that upon resolving contains a response JSON.
//    On success, it should contain status of 200.
//
export function submitForgotPassword(newPassowrd) {
  const url = apiEndpoint + "/forgotPasswordSubmit";

  // The following HTTP request returns a promise
  return http.post(url, newPassowrd);
}
